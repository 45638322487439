import { observable } from "mobx";
import Model from "data/Model";

interface ForgotPasswordAttributes {
    email: string | null;
}

class ForgotPasswordModel extends Model {
    // TODO: Not sure what to do with these intermediate model types.
    // Probably need to look and merging them into their base model and
    // modifying them to support these fields in isolation.
    //
    // Alternatively build better APIs :)
    type = "forgotPasswords";

    get url(): string {
        // TODO: Querystrings shouldn't be hacked on here!
        return `/userActions?userAction=passwordReset`;
    }
    attributes = observable.object({
        email: undefined,
    } as any as ForgotPasswordAttributes);

    relationships = null;
}
export default ForgotPasswordModel;
