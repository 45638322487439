export const COMMENT = "comment";
export type COMMENT = typeof COMMENT;

export const COMPUTED = "computed";
export type COMPUTED = typeof COMPUTED;

export const DIAGRAM = "diagram";
export type DIAGRAM = typeof DIAGRAM;

export const FRAMING = "framing";
export type FRAMING = typeof FRAMING;

export const GIS = "gis";
export type GIS = typeof GIS;

export const IMAGE = "image";
export type IMAGE = typeof IMAGE;

export const INPUT = "input";
export type INPUT = typeof INPUT;

export const LOOKUP = "lookup";
export type LOOKUP = typeof LOOKUP;

export const MAP = "map";
export type MAP = typeof MAP;

export const REMOTE = "remote";
export type REMOTE = typeof REMOTE;

export const SECTION = "section";
export type SECTION = typeof SECTION;

export const SUBSHEET = "subsheet";
export type SUBSHEET = typeof SUBSHEET;

export const TABLE = "table";
export type TABLE = typeof TABLE;

export const TEXT = "text";
export type TEXT = typeof TEXT;

export const CUSTOMDIAGRAM = "customdiagram";
export type CUSTOMDIAGRAM = typeof CUSTOMDIAGRAM;

// Soft Types - ie. only exist in the client to differentiate UI for the builder.
export const DYNAMICLOOKUP = "dynamiclookup";
export type DYNAMICLOOKUP = typeof DYNAMICLOOKUP;

export const MEMBERS = "members";
export type MEMBERS = typeof MEMBERS;

export type WIDGET_TYPES =
    | COMMENT
    | COMPUTED
    | DIAGRAM
    | FRAMING
    | GIS
    | IMAGE
    | INPUT
    | LOOKUP
    | MAP
    | REMOTE
    | SECTION
    | SUBSHEET
    | TABLE
    | TEXT
    | CUSTOMDIAGRAM
    // Soft Types
    | DYNAMICLOOKUP
    | MEMBERS;
