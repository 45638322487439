import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { FramingAttributes } from "./SheetTemplateWidgetInterface";

class FramingSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "framing",
        referenceId: undefined,
        visibleIf: undefined,
        authorNotes: undefined,

        createdAt: undefined,
        updatedAt: undefined,
        order: undefined,
    } as any as FramingAttributes);
}

export default FramingSheetTemplateWidgetModel;
