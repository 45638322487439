import { observable } from "mobx";
import Model from "data/Model";

import SheetTemplates from "../collections/SheetTemplates";
import Organisation from "./Organisation";

interface Attributes {
    code: string | null;
}

interface Relationships {
    organisation: Organisation | null;
    sheetTemplates: SheetTemplates | null;
}
class OrganisationTemplateCode extends Model {
    type = "organisationTemplateCodes";
    attributes = observable.object({
        code: undefined,
    } as any as Attributes);

    relationships = observable.object(
        {
            organisation: undefined,
            sheetTemplates: undefined,
        } as any as Relationships,
        {},
        { deep: false },
    );
}
export default OrganisationTemplateCode;
