import { observable } from "mobx";
import Model from "data/Model";

interface UploadAttributes {
    name: string | null;
    url: string;
    status: string;
    createdAt: string;
    updatedAt: string;
    preSignedUrl: string;
}

class UploadModel extends Model {
    type = "uploads";
    attributes = observable.object({
        name: undefined,
        url: undefined,
        status: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        preSignedUrl: undefined,
    } as any as UploadAttributes);

    relationships = null;

    get doneActionUrl() {
        return `${this.url}/actions/done`;
    }
}
export default UploadModel;
