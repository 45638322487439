import React from "react";
import styled from "styled-components";
import { blue400, grey400, grey600 } from "ui/colors";
import isMafiSubdomain from "embeddedCalcs/utils/isMafiSubdomain";

export function Banner({ onAccept, onReject }) {
    return (
        <Root>
            <p>
                The ClearCalcs platform uses cookies and by continuing to use it
                you accept that cookies may be stored on your device. To find
                out more see our{" "}
                {isMafiSubdomain() ? (
                    <a
                        href="https://www.mafigroup.com/esics-privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                ) : (
                    <a
                        href="https://clearcalcs.com/cookie"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Cookie Policy
                    </a>
                )}
                .
            </p>
            <button className="primary" onClick={onAccept}>
                Accept All Cookies
            </button>
            <button className="primary" onClick={onReject}>
                Reject All Cookies
            </button>
        </Root>
    );
}

const Root = styled.div`
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    max-width: 350px;
    z-index: 100;

    display: flex;
    flex-direction: column;

    background: ${grey600};
    color: white;
    border: 1px solid ${grey400};
    border-radius: 5px;
    padding: 20px;
    font-size: 0.8em;

    a:link {
        text-decoration: underline;
    }

    p {
        line-height: normal;
        margin-bottom: 15px;
    }

    button {
        padding: 10px 0;
        color: white;
        text-align: center;
        margin-top: 10px;
    }

    button.primary {
        background: ${blue400};
        border: 1px solid ${grey400};
        border-radius: 5px;
    }
`;
