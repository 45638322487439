import partialEval from "./partialEval";
import { SolveError, PendingDataError } from "errors";
import { toJS } from "mobx";
import simplifyMathjsObjects from "math/simplifyMathjsObjects";
import groupBy from "framework/utils/groupBy";
import TableSheetTemplateWidgetModel from "data/models/TableSheetTemplateWidget";
import {
    ConstantNode,
    MathNode,
    SymbolNode,
    isConstantNode,
    isSymbolNode,
    isMatrix,
} from "mathjs";
import parseWithNumberSupport from "math/parseWithNumberSupport";

// setSumEquation does not exist on MathNode type but we may add it in at runtime
type SetSumExpressionNode = MathNode & { setSumEquation?: MathNode };

type SetSumFunction = ((
    args: [
        from: MathNode,
        to: MathNode,
        expression: SetSumExpressionNode,
        variable: ConstantNode | SymbolNode,
    ],
    _: any, // This is the mathjs instance
    scope: Map<any, any>,
) => any) & { rawArgs?: boolean };

type SolveSecantFunction = ((
    args: [
        x0: MathNode,
        x1: MathNode,
        criteria: MathNode,
        maxIter: MathNode,
        fn: MathNode,
    ],
    math: any, // This is the mathjs instance
    scope: Map<any, any>,
) => any) & { rawArgs?: boolean };

type IterateFunction = ((
    args: [
        from: MathNode,
        to: MathNode,
        step: MathNode,
        expression: MathNode,
        variable: SymbolNode,
    ],
    _: any, // This is the mathjs instance
    scope: Map<any, any>,
) => any) & { rawArgs?: boolean };

type TryFunction = ((
    args: [node: MathNode],
    math: any, // This is the mathjs instance
    scope: Map<any, any>,
) => any) & { rawArgs?: boolean };

type UserEquationResultFunction = ((
    args: [
        result: MathNode,
        errorContext: MathNode,
        allowStrings: ConstantNode,
    ],
    math: any, // This is the mathjs instance
    scope: Map<any, any>,
) => any) & { rawArgs?: boolean };

export default function createStaticFunctions(math) {
    const staticFunctions = {
        I(condition) {
            return condition ? 1 : 0;
        },

        // log() by default assumes base `e`, but there is weirdly no "ln()"
        // function in MathJS by default
        //
        // We can go further and use the return type of the math.log function but there's
        // complications with using generics.
        ln(...args) {
            if (args.length !== 1) {
                throw new Error("ln: Must have exactly one parameter");
            }
            return math.log(args[0]);
        },

        setSum: function (args, _, scope) {
            const from = args[0].evaluate(scope);
            const to = args[1].evaluate(scope);

            if (args[2].setSumEquation) {
                // We've already got the compiled equation cached
            } else if (
                isConstantNode(args[2]) &&
                typeof args[2].value === "string"
            ) {
                // Legacy usage: equation has been provided as a string - parse and compile it
                // isConstantNode return type is a regular ConstantNode, which is more restrictive
                // than the custom SetSumExpressionNode we have defined.
                // We need to cast it to SetSumExpressionNode to add the setSumEquation property.
                (args[2] as SetSumExpressionNode).setSumEquation =
                    parseWithNumberSupport(args[2].value);
            } else {
                // Equation has already been parsed.
                args[2].setSumEquation = args[2];
            }

            const variable = args[3];
            let variableName;
            if (
                isConstantNode(variable) &&
                typeof variable.value === "string"
            ) {
                variableName = variable.value;
            } else if (isSymbolNode(variable)) {
                variableName = variable.name;
            } else {
                throw new Error(`setSum: Unexpected variable type`);
            }
            const expr = args[2].setSumEquation;

            const fnNode = new math.FunctionAssignmentNode(
                "f",
                [variableName],
                expr,
            );

            return math.setSum2(from, to, fnNode.evaluate(scope));
        } as SetSumFunction,

        // We don't know the return type of the fn function, it can be whatever
        // shape mathjs evaluates to.
        setSum2(from: number, to: number, fn: (value: number) => any) {
            const results: Array<any> = [];
            for (let i = from; i <= to; i++) {
                results.push(fn(i));
            }
            return math.sum(results);
        },

        matrixSubset(matrix, rowIndex, columnIndex) {
            return math.subset(
                matrix,
                // The expression API is 1-based, but the JS API is 0-based. Use the built-in index transformer to translate.
                math.expression.transform.index(rowIndex, columnIndex),
            );
        },

        vectorSubset(vector, index) {
            // The expression API is 1-based, but the JS API is 0-based. Use the built-in index transformer to translate.
            return math.subset(vector, math.expression.transform.index(index));
        },

        isNumber(value) {
            return !math.isNaN(value);
        },

        // Slice column i (1-based) from matrix M
        col(matrixOrArray, i) {
            const matrix = math.matrix(matrixOrArray);
            const numRows = matrix.size()[0];
            const result = new Array(numRows);
            for (let row = 0; row < numRows; row++) {
                result[row] = matrix.get([row, i - 1]);
            }
            return math.matrix(result);
        },

        iterate: function (args, _, scope) {
            const from = args[0].evaluate(scope);
            const to = args[1].evaluate(scope);
            const step = args[2].evaluate(scope);
            const expr = args[3];
            const variable = args[4];

            const partialExpr = partialEval(
                expr,
                scope,
                (node) =>
                    isSymbolNode(node) && node.name === variable.name
                        ? node
                        : null,
                false, // remote calls unsupported within iterate func.
                false,
            );

            return math.iterate2(from, to, step, (v) =>
                partialExpr.evaluate({ [variable.name]: v }),
            );
        } as IterateFunction,

        iterate2(
            from: number,
            to: number,
            step: number,
            // We don't know the return type of the fn function, it can be whatever
            // shape mathjs evaluates to. However it is something that can be summed.
            fn: (value: number) => any,
        ) {
            const result: Array<any> = [];
            for (let i = from; i <= to; i += step) {
                result.push(fn(i));
            }
            return math.matrix(result);
        },

        solveSecant: function (args, math, scope) {
            let x0 = args[0].evaluate(scope);
            let x1 = args[1].evaluate(scope);
            const criteria = args[2].evaluate(scope);
            const maxIter = args[3].evaluate(scope);
            const fnNode = args[4];

            const fn = partialEval(
                fnNode,
                scope,
                // Nothing is unbound except the parameter of the FunctionAssignmentNode itself, which is handled
                // automatically.
                (node) => null,
                false,
                false,
            ).evaluate();

            let fx0 = fn(x0);
            let fx1 = fn(x1);
            let iters = 0;
            let x2 = 0;

            const secantExpr = parseWithNumberSupport(
                "x1 - (fx1 * (x1 - x0)) / (fx1 - fx0)",
            ).compile();

            const compareExpr = parseWithNumberSupport(
                "abs(x1 - x0) > criteria",
            ).compile();
            const zeroDiv =
                parseWithNumberSupport("isZero(fx1 - fx0)").compile();

            // Check that both initial values aren't the exact same.
            if (zeroDiv.evaluate({ fx0: x0, fx1: x1 })) {
                throw new SolveError(
                    "Equation solver: The two initial values are exactly the same.",
                );
            }

            // Check that both initial values aren't within the convergence criterion.
            if (!compareExpr.evaluate({ x0: x0, x1: x1, criteria: criteria })) {
                throw new SolveError(
                    "Equation solver: The two initial values are closer than the convergence criterion.",
                );
            }

            // Check that the initial inputs don't evaluate to exactly the same value.
            if (zeroDiv.evaluate({ fx0: fx0, fx1: fx1 })) {
                throw new SolveError(
                    "Equation solver: Division by zero error (the two initial values evaluate to the exact same result).",
                );
            }

            while (
                compareExpr.evaluate({
                    x0: x0,
                    x1: x1,
                    criteria: criteria,
                }) &&
                !zeroDiv.evaluate({ fx0: fx0, fx1: fx1 }) &&
                iters <= maxIter
            ) {
                x2 = secantExpr.evaluate({
                    x0: x0,
                    x1: x1,
                    fx0: fx0,
                    fx1: fx1,
                });
                x0 = x1;
                fx0 = fx1;
                x1 = x2;
                fx1 = fn(x1);
                iters++;
            }

            if (iters > maxIter) {
                throw new SolveError(
                    "Equation solver: Failed to converge in maximum allowed iterations.",
                );
            } else if (
                zeroDiv.evaluate({ fx0: fx0, fx1: fx1 }) &&
                compareExpr.evaluate({
                    x0: x0,
                    x1: x1,
                    criteria: criteria,
                })
            ) {
                throw new SolveError(
                    "Equation solver: Division by zero error. Two iterations evaluate to the exact same result but X values haven't converged.",
                );
            }

            return x1;
        } as SolveSecantFunction,

        // 2D: interpolate(xMat, xVal, xArray [, "log"])
        // 3D: interpolate(xyMat, xVal, xArray, yVal, yArray [, "log"])
        interpolate(...args) {
            const xyMat = toArray(args[0]);
            const xVal = args[1];
            const xArray = toArray(args[2]);
            let interpMode = "linear";
            if (args.length === 4 || args.length === 6) {
                interpMode = args[args.length - 1];
            }

            const xIndex = math.sum(
                parseWithNumberSupport("number(xVal >= xArray)").evaluate({
                    xVal: xVal,
                    xArray: xArray,
                }),
            );

            let interpExpr;
            if (interpMode === "linear") {
                interpExpr = parseWithNumberSupport(
                    "x1 * (xVal2 - xVal) / (xVal2 - xVal1) + x2 * (xVal - xVal1) / (xVal2 - xVal1)",
                ).compile();
            } else if (interpMode === "log" || interpMode === "logarithmic") {
                // log() can't handle units, hence the long function here... Units just simply cancel out in the linear interpolation equation, so not an issue there
                if (math.isNumeric(math.flatten(xyMat)[0])) {
                    interpExpr = parseWithNumberSupport(
                        "isNumeric(xVal) ? x1 ^ ((log(xVal2) - log(xVal)) / (log(xVal2) - log(xVal1))) * x2 ^ ((log(xVal) - log(xVal1)) / (log(xVal2) - log(xVal1))) : x1 ^ ((log(xVal2.toNumeric()) - log(xVal.toNumeric())) / (log(xVal2.toNumeric()) - log(xVal1.toNumeric()))) * x2 ^ ((log(xVal.toNumeric()) - log(xVal1.toNumeric())) / (log(xVal2.toNumeric()) - log(xVal1.toNumeric())))",
                    ).compile();
                } else {
                    interpExpr = parseWithNumberSupport(
                        "unit(isNumeric(xVal) ? x1.toNumeric() ^ ((log(xVal2) - log(xVal)) / (log(xVal2) - log(xVal1))) * x2.toNumeric() ^ ((log(xVal) - log(xVal1)) / (log(xVal2) - log(xVal1))) : x1.toNumeric() ^ ((log(xVal2.toNumeric()) - log(xVal.toNumeric())) / (log(xVal2.toNumeric()) - log(xVal1.toNumeric()))) * x2.toNumeric() ^ ((log(xVal.toNumeric()) - log(xVal1.toNumeric())) / (log(xVal2.toNumeric()) - log(xVal1.toNumeric()))), x1.formatUnits())",
                    ).compile();
                }
            } else {
                throw new Error(
                    `Interpolation mode ${interpMode} is not available`,
                );
            }

            if (args.length <= 4) {
                if (xIndex === xArray.length) {
                    return math.flatten(xyMat)[xIndex - 1];
                } else if (xIndex === 0) {
                    return math.flatten(xyMat)[xIndex];
                }
                return interpExpr.evaluate({
                    xVal: xVal,
                    xVal1: xArray[xIndex - 1],
                    xVal2: xArray[xIndex],
                    x1: math.flatten(xyMat)[xIndex - 1],
                    x2: math.flatten(xyMat)[xIndex],
                });
            } else {
                const yVal = args[3];
                const yArray = toArray(args[4]);

                const yIndex = math.sum(
                    parseWithNumberSupport("number(yVal >= yArray)").evaluate({
                        yVal: yVal,
                        yArray: yArray,
                    }),
                );

                if (xIndex === xArray.length && yIndex === yArray.length) {
                    return xyMat[xIndex - 1][yIndex - 1];
                } else if (xIndex === 0 && yIndex === 0) {
                    return xyMat[xIndex][yIndex];
                }
                if (yIndex === yArray.length) {
                    return interpExpr.evaluate({
                        xVal: xVal,
                        xVal1: xArray[xIndex - 1],
                        xVal2: xArray[xIndex],
                        x1: xyMat[xIndex - 1][yIndex - 1],
                        x2: xyMat[xIndex][yIndex - 1],
                    });
                } else if (yIndex === 0) {
                    return interpExpr.evaluate({
                        xVal: xVal,
                        xVal1: xArray[xIndex - 1],
                        xVal2: xArray[xIndex],
                        x1: xyMat[xIndex - 1][yIndex],
                        x2: xyMat[xIndex][yIndex],
                    });
                }
                if (xIndex === xArray.length) {
                    return interpExpr.evaluate({
                        xVal: yVal,
                        xVal1: yArray[yIndex - 1],
                        xVal2: yArray[yIndex],
                        x1: xyMat[xIndex - 1][yIndex - 1],
                        x2: xyMat[xIndex - 1][yIndex],
                    });
                } else if (xIndex === 0) {
                    return interpExpr.evaluate({
                        xVal: yVal,
                        xVal1: yArray[yIndex - 1],
                        xVal2: yArray[yIndex],
                        x1: xyMat[xIndex][yIndex - 1],
                        x2: xyMat[xIndex][yIndex],
                    });
                }
                return interpExpr.evaluate({
                    xVal: yVal,
                    xVal1: yArray[yIndex - 1],
                    xVal2: yArray[yIndex],
                    x1: interpExpr.evaluate({
                        xVal: xVal,
                        xVal1: xArray[xIndex - 1],
                        xVal2: xArray[xIndex],
                        x1: xyMat[xIndex - 1][yIndex - 1],
                        x2: xyMat[xIndex][yIndex - 1],
                    }),
                    x2: interpExpr.evaluate({
                        xVal: xVal,
                        xVal1: xArray[xIndex - 1],
                        xVal2: xArray[xIndex],
                        x1: xyMat[xIndex - 1][yIndex],
                        x2: xyMat[xIndex][yIndex],
                    }),
                });
            }
        },

        // Get the results for checks. The ordering is:
        // [true, numbers, false, NaN]
        checkGov(...values) {
            // Utilisation are by definition positive.
            // Negative values will only occur if a template has an error.
            // When this happens a template should be fixed, however in the meantime
            // below will ensure governing check is aware of negative utilisation.
            const min = values.reduce((acc, value) => {
                if (typeof value === "number" && acc > value) {
                    return value;
                }
                return acc;
            }, 0);
            if (min < 0) {
                return min;
            } else {
                return values.reduce((acc, value) => {
                    if (
                        acc === true ||
                        (typeof acc === "number" &&
                            typeof value === "number" &&
                            acc < value) ||
                        (value === false && !isNaN(acc)) ||
                        isNaN(value)
                    ) {
                        return value;
                    } else {
                        return acc;
                    }
                }, true);
            }
        },

        svg(svgString) {
            return { image: svgString };
        },

        try: function (args, math, scope) {
            try {
                return args[0].evaluate(scope);
            } catch (e) {
                if (e instanceof PendingDataError) {
                    throw e;
                } else {
                    return NaN;
                }
            }
        } as TryFunction,

        throwError(message) {
            throw new SolveError(message);
        },

        // When invalid data is given, instead of throwing Errors inline during the mathjs expression tree creation,
        // a rethrow function is created in the tree, and is called only on evaluation.
        // rethrow same, but takes error object instead of string.
        throw(message) {
            throw new Error(message);
        },

        rethrow(error) {
            throw error;
        },

        // Our default formatting for all numerical outputs
        defaultFormat(
            number,
            sigFigs: number = 3,
            intRange: [number | null, number | null] | null = [1000, 1000000],
            lowerExp: number = -3,
            upperExp: number = 6,
        ) {
            if (math.typeOf(number) === "Unit") {
                number = number.toNumeric();
            }
            let string;

            // Three options for formatting:
            // 1. Fixed notation with zero decimal places within intRange
            // 2. Engineering notation for numbers ≥ 10^upperExp or < 10^lowerExp
            // 3. Auto notation for everything else (rounding to sigFigs significant figures)
            if (
                intRange &&
                // We need to check that intRange is not null.
                // otherwise javascript will cast null during comparisons
                // e.g.
                // 10 > null is true
                // -1 > null is false
                intRange[0] !== null &&
                Math.abs(number) >= Math.max(intRange[0], 10 ** sigFigs) &&
                (intRange[1] === null || Math.abs(number) < intRange[1])
            ) {
                string = math.format(number, {
                    notation: "fixed",
                    // For "fixed", precision = number of decimal places, not significant figures
                    precision: 0,
                });
                if (Math.abs(number) >= 10000) {
                    while (/(\d+)(\d{3})/.test(string)) {
                        string = string.replace(/(\d+)(\d{3})/, "$1\u2009$2");
                    }
                }
            } else if (
                Math.abs(number) >= 10 ** upperExp ||
                (Math.abs(number) < 10 ** lowerExp && number !== 0)
            ) {
                string = math.format(number, {
                    notation: "engineering",
                    precision: sigFigs,
                });
                // Replace e+n with x10^n for better (KaTeX-formatted) display
                string = string.replace(/e\+?(\-?\d+)/g, "×10^{$1}");
            } else {
                // We want to use "auto" so that `precision` gets interpreted as significant
                // figures, rather than decimal places. However, we need to set `lowerExp` and
                // `upperExp` to ensure that our numbers are NOT formatted as exponents (which
                // is instead handled in the above condition).
                string = math.format(number, {
                    notation: "auto",
                    precision: sigFigs,
                    lowerExp: lowerExp,
                    upperExp: upperExp,
                });
                if (Math.abs(number) >= 10000) {
                    while (/(\d+)(\d{3})/.test(string)) {
                        string = string.replace(/(\d+)(\d{3})/, "$1\u2009$2");
                    }
                }
            }
            return string;
        },

        // Return 1-based index of minimum value in 1D-matrix or array
        minIndex(matrixOrArray) {
            const array = toArray(matrixOrArray);
            var min = math.min(array);
            return array.indexOf(min) + 1;
        },

        // Return 1-based index of maximum value in 1D-matrix or array
        maxIndex(matrixOrArray) {
            const array = toArray(matrixOrArray);
            var max = math.max(array);
            return array.indexOf(max) + 1;
        },

        // Easier to use setIsSubset() that doesn't require the subset to be an array
        // and displays in better TeX when you're only checking a single value
        isIncluded(value, set) {
            return math.setIsSubset([value], set);
        },

        safeTranspose(matrixOrArray) {
            const matrix = math.matrix(matrixOrArray);
            const size = matrix.size();

            if (size.length === 2 && size[1] === 0) {
                // MathJS's transpose won't transpose matrices with 0 columns.
                return math.zeros(size[1], size[0]);
            } else {
                return math.transpose(matrix);
            }
        },

        deepReplace(object, substr, replacement) {
            return JSON.parse(
                JSON.stringify(object, function (key, value) {
                    if (typeof value === "string") {
                        return value.replaceAll(substr, replacement);
                    }
                    return value;
                }),
            );
        },

        mapObject(object, fn) {
            const type = math.typeOf(object);
            if (math.typeOf(object) !== "Object") {
                throw new TypeError(
                    `mapObject: object has invalid type. Got '${type}', expected 'Object'`,
                );
            }
            return Object.fromEntries(
                Object.entries(object).map(([k, v]) => {
                    const { key, value } = fn(k, v);
                    return [key, value];
                }),
            );
        },

        getLastResult(resultSet) {
            return resultSet.entries[resultSet.entries.length - 1];
        },

        userEquationResult: function (args, math, scope) {
            const allowStrings = args[2].evaluate(scope);
            const errorContext = args[1].evaluate(scope);
            let result;
            try {
                result = args[0].evaluate(scope);
            } catch (e) {
                if (e instanceof SolveError || e instanceof PendingDataError) {
                    throw e;
                } else {
                    console.error("User formula error", e);
                    throw new SolveError("Error", errorContext);
                }
            }
            if (
                math.typeOf(result) === "number" ||
                math.typeOf(result) === "Unit" ||
                (math.typeOf(result) === "string" && allowStrings)
            ) {
                return result;
            } else {
                throw new SolveError("Result is not a number", errorContext);
            }
        } as UserEquationResultFunction,

        loadsDescription(loadObject) {
            if (loadObject) {
                let keys = Object.keys(loadObject).filter(
                    (key) =>
                        toArray(loadObject[key]).some((l) => l !== 0) &&
                        key !== "end_locs",
                );

                // Combine keys by prefix, so "Ws_up" and "Ws_down" become "Ws_up/down"
                const keysByPrefix = [
                    ...groupBy(keys, (key) => {
                        const match = key.match(/^(.*)_/);
                        return match && match[1];
                    }).entries(),
                ];

                return keysByPrefix
                    .map(([prefix, keys]) => {
                        if (prefix != null) {
                            return `${prefix}_${keys
                                .map((k) => k.replace(/^(.*)_/, ""))
                                .join("/")}`;
                        } else {
                            return keys;
                        }
                    })
                    .flat()
                    .join(", ");
            } else {
                return "";
            }
        },

        // Used for table 'object' columns e.g. load tables
        // Takes matrix of values
        // This allows us to re-use the table editing UI for editing objects too
        // returns {row1,col1: ['row1,col2','row1,col3','row1,coln'],
        //          row2,col1: ['row2,col2','row2,col3','row2,coln']}
        tableToObject(matrixOrArray) {
            const result = {};
            toArray(matrixOrArray).forEach(([key, ...values]) => {
                // Use the first instance of the key in the table
                if (!(key in result)) {
                    result[key] = values;
                }
            });
            return result;
        },

        // objectToTable is reverse of above (tableToObject)
        objectToTable(object) {
            return [...Object.entries(object)].map(([key, values]) => [
                key,
                ...toArray(values),
            ]);
        },
        simplifyScalarArray(arrayOrMatrix) {
            const array = toArray(arrayOrMatrix);
            if (array.length === 1) {
                return array[0];
            }
            return array;
        },

        // Convert value to unit (if specified)
        // This is the logic for "input" fields that might contain user formulas.
        setUnit(value, unit, errorContext, inputUnit) {
            if (unit && value !== null) {
                if (math.typeOf(value) === "Unit") {
                    try {
                        return value.to(unit);
                    } catch (e) {
                        if (e instanceof Error) {
                            throw new SolveError(e.message, errorContext);
                        } else {
                            throw new SolveError("", errorContext);
                        }
                    }
                } else {
                    return math.unit(value, inputUnit || unit).to(unit);
                }
            } else {
                return value;
            }
        },

        // Assert that value is compatible with the specified unit, including asserting that it's unit-less if unit
        // isn't specified.
        // This is the logic for "computed" fields.
        assertUnit(value, unit) {
            if (unit && math.unit(unit).toSI().toString() === "") {
                // If converting the unit to SI results in an empty string, then the units have canceled out.
                // This isn't currently supported, because we can't distinguish between "in/in" and "in/ft"
                // - even though the values to those examples should actually be different
                throw new Error(
                    `Units that cancel out are not currently supported: ${unit}`,
                );
            } else if (unit) {
                if (math.typeOf(value) === "number") {
                    if (value === 0) {
                        // Treat zero as valid if a unit is expected, and automatically convert it.
                        //
                        // It would be nice if mathjs treated zero this way this universally (like CSS units). This
                        // at least allows us to avoid some annoying pitfalls, like sum([]) == 0.
                        return math.unit(value, unit);
                    } else {
                        throw new Error(
                            `Value was unit-less. Expected: ${unit}`,
                        );
                    }
                } else if (math.typeOf(value) !== "Unit") {
                    throw new Error(
                        `Values of type ${math.typeOf(
                            value,
                        )} do not support units`,
                    );
                } else {
                    return value.to(unit);
                }
            } else {
                if (math.typeOf(value) === "Unit") {
                    // The value had a unit, but the user didn't specify one
                    throw new Error(
                        `Value was ${value.toString()}, but units weren't expected`,
                    );
                } else {
                    return value;
                }
            }
        },

        validateTableLookup(value, column, errorContext) {
            if (
                TableSheetTemplateWidgetModel.hasValueColumnIndex(
                    column.valueColumnIndex,
                )
            ) {
                if (
                    !column.dataTable.some(
                        (row) => row[column.valueColumnIndex] === value,
                    )
                ) {
                    throw new SolveError(
                        `Invalid lookup value: ${value}`,
                        errorContext,
                    );
                }
            } else {
                if (
                    typeof value !== "number" ||
                    value < 0 ||
                    value >= column.dataTable.length
                ) {
                    throw new SolveError(
                        `Invalid lookup index: ${value}`,
                        errorContext,
                    );
                }
            }

            return value;
        },

        simplifyDynamicLookupEquation(dataTable) {
            return JSON.parse(
                JSON.stringify(toArray(dataTable), math.replacer),
                simplifyMathjsObjects,
            );
        },

        // For partial eval, we need to perform the same validation as is done
        // on SheetWidgetShadow => currentDataTable
        // but in a FunctionNode whose evaluation can be delayed until
        // after its children (dataTable) have been walked.
        validateDynamicLookup(value, dataTable, valueColumnIndex) {
            const arraySimple = JSON.parse(
                JSON.stringify(dataTable, math.replacer),
                simplifyMathjsObjects,
            );

            const map = new Map();
            arraySimple.forEach((row) => {
                const key = row[valueColumnIndex].toString();
                if (map.has(key)) {
                    throw new Error(
                        `Lookup: Duplicate value in valueColumnIndex column: ${key}`,
                    );
                }
                map.set(key, row);
            });
            // We throw away the result of the dataTable validation as we only need
            // the selected lookup option.
            return value;
        },

        // Entering units should be optional where a simple addition / subtraction expression is used.
        // If for example you enter support length to be L (mm), you can enter "L - 100"
        // and 100 will be coerced to use the same units as L (mm).
        subtractWithUnitCoercion: math.typed(
            math.typed({
                "Unit, number": function (a, b) {
                    return math.subtract(a, math.unit(b, a.formatUnits()));
                },
                "number, Unit": function (a, b) {
                    return math.subtract(math.unit(a, b.formatUnits()), b);
                },
            }),
            math.subtract,
        ),

        addWithUnitCoercion: math.typed(
            math.typed({
                "Unit, number": function (a, b) {
                    return math.add(a, math.unit(b, a.formatUnits()));
                },
                "number, Unit": function (a, b) {
                    return math.add(math.unit(a, b.formatUnits()), b);
                },
            }),
            math.add,
        ),

        mapRows(matrix, fn) {
            return toArray(matrix).map((row) => fn(row));
        },
    };

    staticFunctions.setSum.rawArgs = true;
    staticFunctions.solveSecant.rawArgs = true;
    staticFunctions.iterate.rawArgs = true;
    staticFunctions.try.rawArgs = true;
    staticFunctions.userEquationResult.rawArgs = true;

    function toArray(matrixOrArray) {
        matrixOrArray = toJS(matrixOrArray);
        if (isMatrix(matrixOrArray)) {
            return matrixOrArray.toArray();
        } else if (Array.isArray(matrixOrArray)) {
            return matrixOrArray;
        } else {
            throw new TypeError("Value is not a Matrix or Array");
        }
    }

    return staticFunctions;
}
