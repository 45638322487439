import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { GisAttributes } from "./SheetTemplateWidgetInterface";

class GisSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "gis",
        label: undefined,
        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        referenceImage: undefined,
        symbol: undefined,
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        visibleIf: undefined,
        equation: undefined,
        showInSummary: undefined,

        order: undefined,
    } as any as GisAttributes);
}

export default GisSheetTemplateWidgetModel;
