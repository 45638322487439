import { observable } from "mobx";
import Model from "data/Model";

import LicenseTypesTemplateCodes from "../collections/LicenseTypesTemplateCodes";

interface LicenseTypeAttributes {
    name: string | null;
}

interface LicenseTypeRelationships {
    licenseTypeTemplateCodes: LicenseTypesTemplateCodes;
}

class LicenseType extends Model {
    type = "licenseTypes";
    attributes = observable.object({
        name: undefined,
    } as any as LicenseTypeAttributes);

    relationships = observable.object(
        {
            licenseTypeTemplateCodes: undefined,
        } as any as LicenseTypeRelationships,
        {},
        { deep: false },
    );
}
export default LicenseType;
