import { createContext, useRef } from "react";
import memoize from "memoize-one";

import Universe from "data/Universe";

const UniverseManagerContext = createContext<any>(
    // Yes, this is a lie. The value is *always* defined wherever this context is provided, so it makes sense to avoid
    // all the guards.
    null as any,
);
export default UniverseManagerContext;

export function UniverseManagerProvider(props) {
    const getProjectUniverse = useRef(
        memoize((projectId) => {
            const universe = new Universe();
            return universe;
        }),
    );
    const getOrganisationUniverse = useRef(
        memoize((organisationId) => {
            const universe = new Universe();
            return universe;
        }),
    );

    const getSheetTemplateUniverse = useRef(
        memoize((sheetTemplateId) => {
            const universe = new Universe();
            return universe;
        }),
    );

    return (
        <UniverseManagerContext.Provider
            value={{
                getOrganisationUniverse: getOrganisationUniverse.current,
                getProjectUniverse: getProjectUniverse.current,
                getSheetTemplateUniverse: getSheetTemplateUniverse.current,
            }}
        >
            {props.children}
        </UniverseManagerContext.Provider>
    );
}
