import { observable } from "mobx";
import Model from "data/Model";
import Project from "./Project";

interface SheetUpgradeAttributes {
    sheetName: string | null;
    currentVersion: number | null;
    latestVersion: number | null;
    latestTemplateId: string | null;
    templateCode: string | null;
    templateName: string | null;
    upgradeSeverity: string | null;
    upgradeReason: string | null;
    builderOrganisationId: string | null;
}

export default class SheetUpgrade extends Model {
    type = "sheetUpgrades";
    attributes = observable.object({
        sheetName: undefined,
        currentVersion: undefined,
        latestVersion: undefined,
        latestTemplateId: undefined,
        templateCode: undefined,
        templateName: undefined,
        upgradeSeverity: undefined,
        upgradeReason: undefined,
        builderOrganisationId: undefined,
    } as any as SheetUpgradeAttributes);

    relationships = null;
}
