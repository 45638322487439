import { observable } from "mobx";
import Model from "data/Model";

import LicenseType from "./LicenseType";
import SheetTemplates from "../collections/SheetTemplates";

interface LicenseTypeAttributes {
    available: boolean | null;
    code: string | null;
}

interface LicenseTypeRelationships {
    licenseType: LicenseType | null;
    sheetTemplates: SheetTemplates | null;
}

class LicenseTypeTemplateCode extends Model {
    type = "licenseTypeTemplateCodes";
    attributes = observable.object({
        available: undefined,
        code: undefined,
    } as any as LicenseTypeAttributes);

    relationships = observable.object(
        {
            licenseType: undefined,
            sheetTemplates: undefined,
        } as any as LicenseTypeRelationships,
        {},
        { deep: false },
    );
}
export default LicenseTypeTemplateCode;
