import { observable } from "mobx";
import Model from "data/Model";
import Sheets from "../collections/Sheets";
import Project from "./Project";

interface SheetGroupAttributes {
    name: string | null;
    shortName: string | null;
    createdAt: string | null;
    updatedAt: string | null;
}

interface SheetGroupRelationships {
    sheets: Sheets | null;
    project: Project | null;
}

export default class SheetGroup extends Model {
    type = "sheetGroups";
    attributes = observable.object({
        name: undefined,
        shortName: undefined,
        createdAt: undefined,
        updatedAt: undefined,
    } as any as SheetGroupAttributes);

    relationships = observable.object(
        {
            sheets: undefined,
            project: undefined,
        } as any as SheetGroupRelationships,
        {},
        { deep: false },
    );
}
