import React from "react";
import { ConsentManagerBuilder } from "@segment/consent-manager";
import { isInEUTimezone } from "@segment/in-eu";

import segment from "segment";
import { Banner } from "./Banner";

const LEGITIMATE_INTEREST = ["Mixpanel", "Vero", "ProfitWell"];

interface IProps {
    writeKey: string;
}
export default function Consent(props: IProps) {
    if (!segment.instance) {
        return null;
    }

    return (
        <ConsentManagerBuilder
            writeKey={props.writeKey}
            shouldRequireConsent={isInEUTimezone}
            mapCustomPreferences={mapCustomPreferences}
        >
            {({
                newDestinations,
                isConsentRequired,
                setPreferences,
                saveConsent,
            }) => {
                return (
                    <>
                        {isConsentRequired && newDestinations.length > 0 && (
                            <Banner
                                onAccept={() => {
                                    setPreferences({ marketing: true });
                                    saveConsent();
                                }}
                                onReject={() => {
                                    setPreferences({ marketing: false });
                                    saveConsent();
                                }}
                            />
                        )}
                    </>
                );
            }}
        </ConsentManagerBuilder>
    );
}

function mapCustomPreferences(destinations, preferences) {
    const destinationPreferences = {};

    for (const destination of destinations) {
        if (LEGITIMATE_INTEREST.includes(destination.id)) {
            destinationPreferences[destination.id] = true;
        } else {
            // Group everything that's not a "legitimate interest" destination into a single "marketing" category for now.
            destinationPreferences[destination.id] = preferences["marketing"];
        }
    }

    return { destinationPreferences, customPreferences: preferences };
}
