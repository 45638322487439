import Rollbar from "rollbar";
import { getClientConfig } from "clientConfig";

// TODO: Use type in every instance of logUnhandledError
export type UnhandledErrorType = string | Error | {};

function logUnhandledError(...args: UnhandledErrorType[]) {
    if (getClientConfig().enableRollbar) {
        // Rollbar accepts any combination of parameters in any order.
        // @ts-ignore TODO Rollbar bindings don't acknowledge any
        // of the functions:
        //     Property 'error' does not exist on type 'typeof Rollbar'
        Rollbar.error(...args);
    } else {
        console.error(...args);
    }
}
export default logUnhandledError;
