import Comment from "../models/CommentSheetTemplateWidget";
import Computed from "../models/ComputedSheetTemplateWidget";
import Diagram from "../models/DiagramSheetTemplateWidget";
import Framing from "../models/FramingSheetTemplateWidget";
import Image from "../models/ImageSheetTemplateWidget";
import Input from "../models/InputSheetTemplateWidget";
import Lookup from "../models/LookupSheetTemplateWidget";
import Remote from "../models/RemoteSheetTemplateWidget";
import Section from "../models/SectionSheetTemplateWidget";
import Table from "../models/TableSheetTemplateWidget";
import Text from "../models/TextSheetTemplateWidget";
import Map from "../models/MapSheetTemplateWidget";
import Gis from "../models/GisSheetTemplateWidget";
import Subsheet from "../models/SubsheetSheetTemplateWidget";
import CustomDiagram from "../models/CustomDiagramSheetTemplateWidget";

import {
    COMMENT,
    COMPUTED,
    DIAGRAM,
    FRAMING,
    GIS,
    IMAGE,
    INPUT,
    LOOKUP,
    MAP,
    REMOTE,
    SECTION,
    SUBSHEET,
    TABLE,
    TEXT,
    CUSTOMDIAGRAM,
} from "data/types/widgetTypes";

export default function sheetTemplateWidgetFactory(resource, options) {
    if (!resource.attributes) {
        throw new Error(
            "Can't instantiate SheetTemplateWidget subclasses without attributes",
        );
    }

    switch (resource.attributes.type) {
        case COMMENT:
            return new Comment(resource.id, options);
        case COMPUTED:
            return new Computed(resource.id, options);
        case DIAGRAM:
            return new Diagram(resource.id, options);
        case FRAMING:
            return new Framing(resource.id, options);
        case IMAGE:
            return new Image(resource.id, options);
        case INPUT:
            return new Input(resource.id, options);
        case LOOKUP:
            return new Lookup(resource.id, options);
        case REMOTE:
            return new Remote(resource.id, options);
        case SECTION:
            return new Section(resource.id, options);
        case TABLE:
            return new Table(resource.id, options);
        case TEXT:
            return new Text(resource.id, options);
        case MAP:
            return new Map(resource.id, options);
        case GIS:
            return new Gis(resource.id, options);
        case SUBSHEET:
            return new Subsheet(resource.id, options);
        case CUSTOMDIAGRAM:
            return new CustomDiagram(resource.id, options);
        default:
            throw new Error(
                `Unknown SheetTemplateWidget type: ${resource.attributes.type}`,
            );
    }
}
