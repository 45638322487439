import math from "math";
import { MathNode, isSymbolNode } from "mathjs";
import SheetTemplateModel from "data/models/SheetTemplate";

export default function makeReferenceTransformer(
    sheetTemplate: SheetTemplateModel,
    skipInputChecksFor?: string,
) {
    return (node: MathNode, path: string) => {
        if (
            path !== "fn" &&
            isSymbolNode(node) &&
            node.name &&
            sheetTemplate.relationships.sheetTemplateWidgets.getByReferenceId(
                node.name,
            )
        ) {
            return new math.FunctionNode("x", [
                new math.ConstantNode(node.name),
                new math.ConstantNode(false),
                new math.ConstantNode(skipInputChecksFor),
            ]);
        } else {
            return node;
        }
    };
}
