import { observable } from "mobx";
import Model from "data/Model";
import Project from "./Project";
import Export from "./Export";

interface ProjectArchiveExportAttributes {
    description: string | null;
}

interface ProjectArchiveExportRelationships {
    project: Project | null;
    export: Export | null;
}

class ProjectArchiveExportModel extends Model {
    type = "projectArchiveExports";
    attributes = observable.object({
        description: undefined,
    } as any as ProjectArchiveExportAttributes);

    relationships = observable.object(
        {
            project: undefined,
            export: undefined,
        } as any as ProjectArchiveExportRelationships,
        {},
        { deep: false },
    );
}
export default ProjectArchiveExportModel;
