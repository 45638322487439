import "../reset.css";
import "ui/global.css";
import "katex/dist/katex.min.css";
import "react-phone-input-2/lib/style.css";
import "uiKit/input/phone-input.css";

import { useEffect, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { configure as mobxConfigure, autorun } from "mobx";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import Cookies from "js-cookie";

import * as rollbarOffline from "framework/rollbarOffline";
import rollbarConfig from "rollbarConfig";
import Rollbar from "rollbar";

import { getClientConfig } from "clientConfig";
import { setSuperProperties } from "track";

import NextApp from "next/app";
import segment from "segment";
import Consent from "consent/Consent";
import customerio from "customerio";

import SessionStore from "framework/SessionStore";
import { SessionStoreContext } from "framework/SessionStoreContext";
import { UniverseManagerProvider } from "framework/UniverseManagerContext";
import { SidebarContextProvider } from "sidebar/SidebarContext";

import { LDProvider } from "launchdarkly-react-client-sdk";
import launchDarkly from "launchDarkly";
import { useEffectOnce } from "framework/hooks/useEffectOnce";
import googleTagManager from "googleTagManager";

// This is not set to "always" because we have been relying on the old behaviour
// where you could make changes to observables before they were observed without
// everything crumbling around you. Would be good to get to "always" at some point.
mobxConfigure({ enforceActions: "observed" });
TimeAgo.addLocale(en);

if (typeof window !== "undefined") {
    Rollbar.init(rollbarConfig(getClientConfig()));
    rollbarOffline.start();

    // Don't enable segment from embed pages, we don't want to
    // track any of it for now.
    if (
        getClientConfig().enableAnalytics &&
        !window.location.pathname.startsWith("/embed/")
    ) {
        segment.init(getClientConfig().segmentToken!);
        customerio.init(getClientConfig().customerioSiteId!);
        googleTagManager.init();
    }
}

function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const [sessionStore, setSessionStore] = useState<SessionStore>();

    // TODO: Refactor this to use useEffect. We only need one SessionStore
    // for the whole app, if we fix the !!pageProps.embeddedCalc, it'll
    // re run the useEffect and create a new SessionStore on every page.
    useEffectOnce(() => {
        const sessionStore = new SessionStore({
            printSessionId:
                typeof window !== "undefined" && window.printSessionId,
            embeddedCalc: !!pageProps.embeddedCalc,
        });

        autorun(() => {
            if (sessionStore.user) {
                setSuperProperties(
                    sessionStore.user.attributes.analyticsSuperProperties,
                );
            }
        });
        setSessionStore(sessionStore);
    });

    // Capture any referralCodes on pagerender.
    useEffect(() => {
        // Store any referralCodes we come across in the
        if (router.query.referralCode) {
            Cookies.set("referralCode", router.query.referralCode, {
                expires: 365,
            });
        }
    });

    return (
        <LDProvider {...launchDarkly.config} ldClient={launchDarkly.instance}>
            <SessionStoreContext.Provider value={sessionStore as SessionStore}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1"
                        key="viewport"
                    />
                </Head>
                <UniverseManagerProvider>
                    <SidebarContextProvider>
                        <Component {...pageProps} />
                    </SidebarContextProvider>
                    <Consent writeKey={getClientConfig().segmentToken!} />
                </UniverseManagerProvider>
            </SessionStoreContext.Provider>
        </LDProvider>
    );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
MyApp.getInitialProps = async (appContext) => {
    // calls page's `getInitialProps` and fills `appProps.pageProps`
    const appProps = await NextApp.getInitialProps(appContext);

    return { ...appProps };
};

export default MyApp;
