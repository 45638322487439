import { observable } from "mobx";

import Model from "data/Model";
import SheetTemplate from "data/models/SheetTemplate";
import OrganisationModel from "./Organisation";

interface SheetTemplateCodeAttributes {
    order: number | null;
}

interface SheetTemplateCodeRelationships {
    sheetTemplate: SheetTemplate;
    builderOrganisation: OrganisationModel;
}

export default class SheetTemplateCodeModel extends Model {
    type = "sheetTemplateCodes";
    attributes = observable.object({
        order: undefined,
    } as any as SheetTemplateCodeAttributes);
    relationships = observable.object(
        {
            sheetTemplate: undefined,
            builderOrganisation: undefined,
        } as any as SheetTemplateCodeRelationships,
        {},
        { deep: false },
    );
}
