import SheetTemplate from "./SheetTemplate";
import SharedTable from "./SharedTable";
import Upload from "./Upload";

import {
    COMMENT,
    COMPUTED,
    DIAGRAM,
    FRAMING,
    GIS,
    IMAGE,
    INPUT,
    LOOKUP,
    MAP,
    REMOTE,
    SECTION,
    SUBSHEET,
    TABLE,
    TEXT,
    CUSTOMDIAGRAM,
} from "data/types/widgetTypes";

export const LEFT_COLUMN = "left";
export type LEFT_COLUMN = typeof LEFT_COLUMN;
export const RIGHT_COLUMN = "right";
export type RIGHT_COLUMN = typeof RIGHT_COLUMN;
export type PlatformLayout = LEFT_COLUMN | RIGHT_COLUMN;

export type EquationItem = { condition: string; result: string };
export type Equation = Array<EquationItem>;
export type LookupDataTable = Array<Array<string | number | null>>;
export enum ChecksComparators {
    greaterThan = ">",
    greaterThanEqualTo = ">=",
    lessThan = "<",
    lessThanEqualTo = "<=",
    equalTo = "==",
}

export type ChecksItem = {
    condition: string;
    result: string;
    type?: "utilisation" | "passfail";
    /**
     * This is equivalent to:
     * type Comparators = 'greaterThanEqualTo' | 'lessThanEqualTo' etc.
     * https://www.typescriptlang.org/docs/handbook/enums.html#enums-at-compile-time
     */
    comparator?: keyof typeof ChecksComparators;
};

type Checks = Array<ChecksItem>;
type Units = string | null;
type Hidden = boolean | null;
type Symbol = string | null;
type Export = boolean | null;
type Selector = {
    filters: SelectorFilter[];
    tableColumns: [SelectorTableColumnValue | SelectorTableColumnCheck];
};

export type SelectorFilter = MinMaxSelectorFilter | SelectSelectorFilter;
export interface MinMaxSelectorFilter {
    type: "min" | "max";
    columnIndex: number;
    defaultValueByPreset?: { [preset: string]: string };
}
export interface SelectSelectorFilter {
    type: "select";
    columnIndex: number;
    leftToRight?: boolean;
    defaultValueByPreset?: { [preset: string]: string };
}
export interface SelectorTableColumnValue {
    type: "value";
    columnIndex: number;
}
export interface SelectorTableColumnCheck {
    type: "check";
    referenceId: string;
}

export type TableDataColumn =
    | ComputedDataColumn
    | LabelDataColumn
    | InputDataColumn
    | LookupDataColumn
    | LinkRowDataColumn
    | ObjectDataColumn
    | HiddenDataColumn;

export interface DataColumnCommon {
    label?: string;
    shortLabel?: string;
    symbol?: string | null;
    units?: string;
    units2?: string;
    unitsMks?: string;
    unitsFps?: string;
    referenceId?: string;
}

export interface ComputedDataColumn extends DataColumnCommon {
    type: COMPUTED;
    equation?: Equation;
}

export interface LabelDataColumn extends DataColumnCommon {
    type: "label";
    apiLabels?: string[];
}

export interface InputDataColumn extends DataColumnCommon {
    type: INPUT;
    defaultValue?: string | null;
    inputType?: "number" | "string";
    cellChecks?: Checks;
    allowEmpty?: boolean;
}

export interface LookupDataColumn extends DataColumnCommon {
    type: LOOKUP;
    defaultValue?: string | null;
    dataTable: any[][];
    valueColumnIndex?: number;
}

export interface ReferencesJsonItem {
    id: string;
    name: string;
}

export interface ObjectDataColumn extends DataColumnCommon {
    type: "object";
    defaultValue?: any;
    dataColumns: (LookupDataColumn | InputDataColumn)[];
}

export interface LinkRowDataColumn {
    type: "linkRow";
    description?: string;
    referenceId?: string;
    mappings: [
        {
            sourceColumn: number;
            targetColumn: number;
            prependSheetName?: boolean;
        },
    ];
    sourceDataColumns: [
        {
            label: string;
            units?: string;
            units2?: string;
            unitsMks?: string;
            unitsFps?: string;
            sorted?: boolean;
        },
    ];
    linkRequired?: boolean;
}

export interface HiddenDataColumn {
    type: "hidden";
    label?: string;
    shortLabel?: string;
    symbol?: string | null;
}

type Diagram = [
    {
        type: string; //TODO: Add available types
    },
];

// Comment, Computed, Diagram, Framing, Image, Input, Lookup, Remote, Section, Table
export interface CommonAttributes {
    visibleIf: string | null;
    referenceId: string | null;
    order: number | null;
    createdAt: string | null;
    updatedAt: string | null;
}

// Comment, Computed, Image, Input, Lookup, Section, Table, Gis
interface DebugAttributes {
    label: string | null;
    description: string | null;
    references: string | null;
    referencesJson: any | null;
    authorNotes: string | null;
    referenceImage: string | null;
}

export interface CommentAttributes extends CommonAttributes, DebugAttributes {
    type: COMMENT;
    export: Export;
    showInSuperSummary: boolean | null;
}
export interface ComputedAttributes extends CommonAttributes, DebugAttributes {
    type: COMPUTED;
    showInSummary: boolean | null;
    showInSuperSummary: boolean | null;
    units: Units;
    units2: Units;
    unitsMks: Units;
    unitsFps: Units;
    hidden: Hidden;
    checks: Checks;
    checkInHeader: boolean;
    checkShortName: string | null;
    symbol: Symbol;
    export: Export;
    equation: Equation;
    apiOutput: boolean;
    ignoreUnitAssertion: boolean | null;
    fullWidth: boolean;
}
export interface DiagramAttributes extends CommonAttributes {
    type: DIAGRAM;
    diagram: Diagram;
    equation: Equation;
    showInSuperSummary: boolean | null;
}
export interface FramingAttributes extends CommonAttributes {
    type: FRAMING;
    authorNotes: string | null;
}
export interface ImageAttributes extends CommonAttributes, DebugAttributes {
    type: IMAGE;
    image: {};
    equation: Equation;
    showInSuperSummary: boolean | null;
    fullWidth: boolean;
}
export interface InputAttributes extends CommonAttributes, DebugAttributes {
    type: INPUT;
    defaultValue: string | null;
    defaultValueMks: string | null;
    defaultValueFps: string | null;
    units: Units;
    units2: Units;
    unitsMks: Units;
    unitsFps: Units;
    hidden: Hidden;
    checks: Checks;
    symbol: Symbol;
    export: Export;
    showInSuperSummary: boolean | null;
}
export interface LookupAttributes extends CommonAttributes, DebugAttributes {
    type: LOOKUP;
    checks: Checks;
    enableAutosize: boolean | null;
    dataTable: LookupDataTable;
    dataColumns: DataColumnCommon[];
    selector: Selector;
    sharedTableId: string | null;
    defaultValue: string | number | null;
    defaultValueMks: string | number | null;
    defaultValueFps: string | number | null;
    symbol: Symbol;
    export: Export;
    radioListDisplay: boolean;
    showInSuperSummary: boolean | null;
    dataFilter: string | null;
    valueColumnIndex: number | null;
    enableSelectorChecks: boolean | null;
    equation: Equation;
}
export interface RemoteAttributes extends CommonAttributes {
    type: REMOTE;
    solver: string | null;
    symbol: Symbol;
    equation: Equation;
    enableTypes: boolean;
}
export interface SectionAttributes extends CommonAttributes, DebugAttributes {
    type: SECTION;
    collapsed: boolean | null;
    platformLayout: PlatformLayout;
    showInSuperSummary: boolean | null;
}
export interface TableAttributes extends CommonAttributes, DebugAttributes {
    type: TABLE;
    expand: boolean | null;
    tableLength: string | null;
    dataColumns: TableDataColumn[];
    defaultValue: Array<Array<any>>;
    defaultValueMks: Array<Array<any>> | null;
    defaultValueFps: Array<Array<any>> | null;
    checks: Checks;
    symbol: Symbol;
    export: Export;
    apiOutput: boolean;
    showInSummary: boolean | null;
    showInSuperSummary: boolean | null;
}
export interface TextAttributes extends CommonAttributes, DebugAttributes {
    type: TEXT;
    richText: Object | null;
}
export interface MapAttributes extends CommonAttributes {
    type: MAP;
    equation: Equation;
    authorNotes: string | null;
    symbol: Symbol;
}

export interface CustomDiagramAttributes {
    type: CUSTOMDIAGRAM;
    symbol: Symbol;
    equation: Equation;
    showInSummary: boolean | null;
    showInSuperSummary: boolean | null;

    // TODO: Extend interface to CommonAttributes if visibleIf added to custom diagram.
    // Below is a duplicate of CommonAttributes excl visibleIf
    referenceId: string | null;
    order: number | null;
    createdAt: string | null;
    updatedAt: string | null;
}

export interface CommonRelationships {
    sheetTemplate: SheetTemplate | null;
}
export interface LookupRelationships extends CommonRelationships {
    sharedTable: SharedTable | null;
}

export interface CustomDiagramRelationships extends CommonRelationships {
    upload: Upload | null;
    uploadInteractive: Upload | null;
}

export interface GisAttributes extends CommonAttributes, DebugAttributes {
    type: GIS;
    equation: Equation;
    symbol: Symbol;
    showInSummary: boolean | null;
}
export interface SubsheetAttributes extends CommonAttributes {
    type: SUBSHEET;
    equation: Equation;
    symbol: Symbol;
    showInSummary: boolean | null;
}

export const INPUT_TYPES = [INPUT, LOOKUP, TABLE, COMMENT, GIS, CUSTOMDIAGRAM];
