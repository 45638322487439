import Collection from "../Collection";
import SheetTemplateCode from "data/models/SheetTemplateCode";

class SheetTemplateCodesCollection extends Collection<SheetTemplateCode> {
    type = "sheetTemplateCodes";

    get models() {
        return [...this._models.values()].sort(
            (a, b) => a.attributes.order! - b.attributes.order!,
        );
    }
}
export default SheetTemplateCodesCollection;
