import logUnhandledError from "./utils/logUnhandledError";

let loggedLocalStorageError = false;
function suppressLocalStorageError(error) {
    if (typeof window === "undefined") {
        return;
    }
    if (error instanceof DOMException) {
        if (!loggedLocalStorageError) {
            loggedLocalStorageError = true;
            logUnhandledError(error);
        }
        return;
    }

    logUnhandledError(error);
}

class Storage {
    namespace: string;
    storage: globalThis.Storage;

    constructor(namespace: string, useSessionStorage: boolean = false) {
        this.namespace = namespace;
        this.storage = globalThis.localStorage;

        if (useSessionStorage) {
            this.storage = globalThis.sessionStorage;
        }
    }

    _generateStorageName(key: string = "") {
        return `cc::${this.namespace}::${key}`;
    }

    getItem(key: string) {
        try {
            const returnJSON = this.storage.getItem(
                this._generateStorageName(key),
            );
            return returnJSON === null ? null : JSON.parse(returnJSON);
        } catch (e) {
            suppressLocalStorageError(e);
        }
    }

    setItem(key: string, value: any) {
        try {
            const storedJSON = JSON.stringify(value);
            this.storage.setItem(this._generateStorageName(key), storedJSON);
        } catch (e) {
            suppressLocalStorageError(e);
        }
    }

    removeItem(key: string) {
        try {
            this.storage.removeItem(this._generateStorageName(key));
        } catch (e) {
            suppressLocalStorageError(e);
        }
    }

    removeAll() {
        const localStorageKeys = Object.keys(this.storage);

        localStorageKeys.forEach((key) => {
            if (key.indexOf(this._generateStorageName()) === 0) {
                try {
                    this.storage.removeItem(key);
                } catch (e) {
                    suppressLocalStorageError(e);
                }
            }
        });
    }
}
export default Storage;
