export default function groupBy<T, K>(
    list: Array<T>,
    fn: (x: T) => K,
): Map<K, Array<T>> {
    const map = new Map();
    list.forEach((item) => {
        const key = fn(item);
        const items = map.get(key);
        if (!items) {
            map.set(key, [item]);
        } else {
            items.push(item);
        }
    });
    return map;
}
