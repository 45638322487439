import { observable, computed, makeObservable } from "mobx";
import Model from "data/Model";
import simplifyMathjsObjects from "math/simplifyMathjsObjects";
import Sheet from "./Sheet";
import math from "math";
import { isUnit } from "mathjs";

interface MemberAttributes {
    referenceId: string | null;
    designation: string | null;
    nCom: number | null;
    comments: string | null;
    // This can be a number or an object in the shape of a math.Unit
    // but not a math.Unit itself, as it is not serializable.
    // We use math.reviver to convert it back to a math.Unit when needed.
    // TODO: Update types to reflect a general shape of a math.Unit
    length: number | null;
    lengthUnit: string | null;
    lengthUnit2: string | null;
    lengthUnitMks: string | null;
    lengthUnitFps: string | null;
}

interface MemberRelationships {
    sheet: Sheet | null;
}

class Member extends Model {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            lengthTyped: computed,
            lengthUntyped: computed,
        });
    }
    type = "members";
    attributes = observable.object({
        referenceId: undefined,
        designation: undefined,
        nCom: undefined,
        comments: undefined,
        length: undefined,
        lengthUnit: undefined,
        lengthUnit2: undefined,
        lengthUnitMks: undefined,
        lengthUnitFps: undefined,
    } as any as MemberAttributes);
    relationships = observable.object(
        {
            sheet: undefined,
        } as any as MemberRelationships,
        {},
        { deep: false },
    );

    get lengthTyped(): math.Unit | number | null {
        return JSON.parse(JSON.stringify(this.attributes.length), math.reviver);
    }

    get lengthUntyped() {
        return JSON.parse(
            JSON.stringify(this.attributes.length),
            simplifyMathjsObjects,
        );
    }

    lengthForDisplay(unitSystem) {
        let length: math.Unit | number;
        let units, units2;

        if (this.attributes.lengthUnit2 && isUnit(this.lengthTyped)) {
            length = this.lengthTyped;
            if (unitSystem === "MKS" && this.attributes.lengthUnitMks) {
                length = length.to(this.attributes.lengthUnitMks);
            } else if (unitSystem === "FPS" && this.attributes.lengthUnitFps) {
                length = length.to(this.attributes.lengthUnitFps);
            } else {
                length = length.to(this.attributes.lengthUnit2);
            }
            units2 = length.formatUnits();
        } else {
            // Length value is untyped, or we have a legacy unit
            length = this.lengthUntyped || NaN;
            units = this.attributes.lengthUnit2 || this.attributes.lengthUnit;
        }

        return {
            length,
            units,
            units2,
        };
    }

    get description() {
        // Handle missing designations - this shouldn't happen, but if there's something wrong on the
        // server, we don't want to explode.
        let result = this.attributes.designation || "Unknown";

        if (this.attributes.nCom && this.attributes.nCom > 1) {
            result = `${this.attributes.nCom} - ${result}`;
        }

        // Sub in the real multiplication sign, because it looks nice
        return result.replace(" x ", " × ");
    }
}
export default Member;
