import { observable } from "mobx";
import Model from "data/Model";

interface UserPasswordAttributes {
    password: string | null;
}

class UserPasswordModel extends Model {
    // TODO: Not sure what to do with these intermediate model types.
    // Probably need to look and merging them into their base model and
    // modifying them to support these fields in isolation.
    //
    // Alternatively build better APIs :)
    type = "userPasswords";

    get url(): string {
        if (!this.id) throw new Error("id is not defined");

        return `/users/${this.id}/password`;
    }

    attributes = observable.object({
        password: undefined,
    } as any as UserPasswordAttributes);

    relationships = null;
}
export default UserPasswordModel;
