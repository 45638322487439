import { TableDataColumn } from "data/models/SheetTemplateWidgetInterface";
import { computed, makeObservable } from "mobx";

interface TableDefinition {
    dataColumns: TableDataColumn[];
    defaultValue?: any[][];
    expand?: boolean | null;
}

export default class TableUtil {
    private table: TableDefinition;

    constructor(table: TableDefinition) {
        makeObservable(this, {
            allColumnsComputed: computed,
        });
        this.table = table;
    }

    get allColumnsComputed() {
        return this.table.dataColumns.every(
            (dataColumn) =>
                dataColumn.type === "computed" || dataColumn.type === "label",
        );
    }

    cleanValue(
        value: (number | string | null)[][],
    ): (number | string | null)[][] {
        // Remove any entirely null rows from expanding tables, as there are templates with junk defaultValues like [[]].
        //
        // This also has the side effect of allowing you to remove a row by clearing every cell, which is acceptable,
        // but ideally that would require the user to explicitly click the delete row button.
        if (this.table.expand) {
            value = value.filter((row) => row && !row.every((v) => v === null));
        }

        // Ensure the rows in the value are the same size as the defined dataColumns to avoid
        // warnings from MobX about untracked indexes.
        const dataColumns = this.table.dataColumns;

        // Iterate through every index up to the length, as there may be entirely empty entries in the array that
        // map would miss.
        let result: (number | string | null)[][] = [];
        for (let rowIndex = 0; rowIndex < value.length; rowIndex++) {
            let row = value[rowIndex];

            // When tableLength is used, any rows that the user hasn't touched can be missing.
            if (!row) {
                row = [];
            }

            if (row.length !== dataColumns.length) {
                // Copy the row so we don't mutate it.
                row = row.slice(0, dataColumns.length);

                // Copy default values from the new columns into the cells. This fixes errors when changing
                // materials adds a new column, as we don't have a "mapping" system for that yet.
                const defaultRow = this.defaultRow(rowIndex);
                for (let i = row.length; i < dataColumns.length; i++) {
                    row[i] = defaultRow[i];
                }
            }

            result[rowIndex] = row;
        }

        return result;
    }

    isRowEmpty(row) {
        if (this.allColumnsComputed) {
            return false;
        }

        if (!row) {
            return true;
        }

        const dataColumns = this.table.dataColumns;
        const defaultRowData = this.defaultRow();
        return dataColumns.every((dataColumn, cellIndex) => {
            const type = dataColumn.type;
            return (
                // Cells that don't take user input is considered empty
                (type !== "input" &&
                    type !== "lookup" &&
                    type !== "linkRow" &&
                    type !== "object") ||
                // Otherwise it's empty if it's set to the default value for the column
                row[cellIndex] === defaultRowData[cellIndex] ||
                // Or it's actually empty
                row[cellIndex] == null
            );
        });
    }

    defaultRow(rowIndex?: number) {
        const row = this.table.dataColumns.map((c) =>
            (c.type === "input" ||
                c.type === "lookup" ||
                c.type === "object") &&
            c.defaultValue !== undefined
                ? c.defaultValue
                : null,
        );

        if (rowIndex !== undefined) {
            // Copy any label values from the corresponding row in defaultValue (if any), as they're read-only and
            // should never be cleared.
            const templateDefaultRow =
                this.table.defaultValue &&
                this.table.defaultValue.length > rowIndex &&
                this.table.defaultValue[rowIndex];
            if (templateDefaultRow) {
                const dataColumns = this.table.dataColumns;
                dataColumns.forEach((dataColumn, cellIndex: number) => {
                    if (dataColumn.type === "label") {
                        row[cellIndex] = templateDefaultRow[cellIndex];
                    }
                });
            }
        }

        return row;
    }
}
