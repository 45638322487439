import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { RemoteAttributes } from "./SheetTemplateWidgetInterface";

class RemoteSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "remote",
        symbol: undefined,
        equation: [],
        solver: undefined,
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        visibleIf: undefined,
        enableTypes: undefined,

        order: undefined,
    } as any as RemoteAttributes);
}

export default RemoteSheetTemplateWidgetModel;
