import { observable, action } from "mobx";
import Model from "data/Model";

interface ExportOptionsAttributes {
    paperSize: "Letter" | "A4";
    printMode: "supersummary" | "summary" | "details";
    sheets: string[];
    locale: string;
    outputTo: "pdf" | "csv";
    includeToc: boolean;
}

class ExportOptionsModel extends Model {
    type = "exportOptions";
    attributes = observable.object({
        paperSize: "A4",
        printMode: "summary",
        sheets: [],
        locale: undefined,
        outputTo: "pdf",
        includeToc: undefined,
    } as any as ExportOptionsAttributes);

    relationships = null;

    get url(): string {
        return `/projects/${this.id}/actions/export`;
    }

    addSheet = action((sheet: string) => {
        if (!this.attributes.sheets.includes(sheet)) {
            this.attributes.sheets!.push(sheet);
        }
    });

    deleteSheet = action((sheet: string) => {
        const index = this.attributes.sheets.indexOf(sheet);
        if (index > -1) {
            this.attributes.sheets!.splice(index, 1);
        }
    });
}
export default ExportOptionsModel;
