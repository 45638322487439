import { observable } from "mobx";
import Model from "data/Model";

import User from "./User";

interface OneTimeStateAttributes {
    trialWelcomeDismissed: boolean | null;
    presetSelectorDismissed: boolean | null;
    projectDefaultsSidebarDismissed: boolean | null;
    abcbTrained: boolean | null;
}

interface OneTimeStateRelationships {
    user: User | null;
}

class OneTimeState extends Model {
    type = "oneTimeStates";
    attributes = observable.object({
        trialWelcomeDismissed: undefined,
        presetSelectorDismissed: undefined,
        projectDefaultsSidebarDismissed: undefined,
        abcbTrained: undefined,
    } as any as OneTimeStateAttributes);

    relationships = observable.object(
        {
            user: undefined,
        } as any as OneTimeStateRelationships,
        {},
        { deep: false },
    );
}
export default OneTimeState;
