import { observable } from "mobx";
import Model from "data/Model";

interface CategoryAttributes {
    id: string | null;
    state: string | null;
    progressPercent: string | null;
    url: string | null;
}

export default class ExportModel extends Model {
    type = "exports";
    attributes = observable.object({
        id: undefined,
        state: undefined,
        progressPercent: undefined,
        url: undefined,
    } as any as CategoryAttributes);

    relationships = null;
}
