// export type ConfigOptions = {
//     method: "GET" | "POST" | "PUT" | "DELETE",
//     headers: {},
//     body: *,
//     timeout: number,
//     responseType: "" | "arraybuffer" | "blob" | "document" | "json" | "text",
//     jsonReviver: AnalyserNode,
// };

function abortableRequest(
    url,
    {
        method = "GET",
        headers = {},
        body = null,
        responseType = "",
        timeout = 60000,
        onProgress = () => {},
        jsonReviver = undefined,
    } = {},
) {
    const req = new XMLHttpRequest();
    const promise = new Promise((resolve, reject) => {
        req.open(method, url, true);

        Object.keys(headers).forEach((headerKey) => {
            req.setRequestHeader(headerKey, headers[headerKey]);
        });
        req.timeout = timeout;

        // Let's skip the browser auto parsing 'json' type responses
        // because the default behaviour is to respond with null if
        // the response is unparsible, this is not really what we want,
        // so if we're expecting JSON, set the response type to 'text'
        // and manually try and parse the response.
        if (responseType === "json") {
            req.responseType = "text";
        }

        req.onload = function () {
            let res = req.response || req.responseText;
            if (req.status >= 200 && req.status <= 299) {
                if (req.status === 204) {
                    resolve(null);
                } else {
                    if (responseType === "json" && typeof res === "string") {
                        // IE doesn't parse responses as JSON without the json attribute,
                        // even with responseType: 'json'.
                        // See https://github.com/Raynos/xhr/issues/123
                        try {
                            res = JSON.parse(res, jsonReviver);
                        } catch (e) {
                            // Not parseable response as error
                            reject({
                                meta: {
                                    _request: {
                                        status: 0,
                                        method: method,
                                        url: url,
                                    },
                                },

                                errors: [
                                    {
                                        status: "0",
                                        code: "parse_error",
                                        title: "Response Parse Failure",
                                        detail: `Response failed to parse as ${responseType}`,
                                    },
                                ],
                            });
                            return;
                        }
                    }

                    resolve(res);
                }
            } else if (req.status === 300) {
                // If we receive a 300 status code from the API, it probably means
                // we have a client redirect we should follow, this is used for cases
                // where we've changes the URL to the item, and the client still has
                // access in a different location.
                //
                //
                // For example when upgrading a project, we deled the old project and
                // create a new one. For people who have bookmarked the old project, we
                // should redirect them to the new one.
                //
                // TODO: The fetch API has the concept of manual redirect mode, we should
                // use this instead, when we update this function to use the fetch API.
                window.location.replace(req.getResponseHeader("Location"));
            } else {
                if (responseType === "json" && typeof res === "string") {
                    // IE doesn't parse responses as JSON without the json attribute,
                    // even with responseType: 'json'.
                    // See https://github.com/Raynos/xhr/issues/123
                    try {
                        res = JSON.parse(res);
                        res.meta = res.meta || {};
                        res.meta._request = {
                            status: req.status,
                            method: method,
                            url: url,
                        };
                    } catch (e) {
                        // Not parseable response as error
                        reject({
                            meta: {
                                _request: {
                                    status: 0,
                                    method: req.method,
                                    url: req.url,
                                },
                            },

                            errors: [
                                {
                                    status: "0",
                                    code: "parse_error",
                                    title: "Response Parse Failure",
                                    detail: `Response failed to parse as ${responseType}`,
                                },
                            ],
                        });
                        return;
                    }
                }

                reject(res);
            }
        };

        req.ontimeout = function () {
            reject({
                meta: {
                    _request: {
                        status: req.status,
                        method: method,
                        url: url,
                    },
                },
                errors: [
                    {
                        status: "0",
                        code: "timeout",
                        title: "Request Timeout",
                        detail: `${method} ${url} :: Request timed out`,
                    },
                ],
            });
        };

        req.onabort = function () {
            reject({
                meta: {
                    _request: {
                        status: req.status,
                        method: method,
                        url: url,
                    },
                },
                errors: [
                    {
                        status: "0",
                        code: "abort",
                        title: "Request Aborted",
                        detail: `${method} ${url} :: Request aborted`,
                    },
                ],
            });
        };

        req.onerror = function () {
            reject({
                meta: {
                    _request: {
                        status: req.status,
                        method: method,
                        url: url,
                    },
                },
                errors: [
                    {
                        status: "0",
                        code: "error",
                        title: "Request Errored",
                        detail: `${method} ${url} :: Request errored`,
                    },
                ],
            });
        };

        if (req.upload) {
            req.upload.addEventListener("progress", onProgress);
        }
        req.send(body);
    });

    promise.abort = function () {
        if (req.readyState !== XMLHttpRequest.DONE) {
            req.abort();
        }
    };
    return promise;
}
export default abortableRequest;
