import Collection from "../Collection";
import SheetTemplate from "../models/SheetTemplate";

class SheetTemplatesCollection extends Collection<SheetTemplate> {
    type = "sheetTemplates";

    filterItems(filterText: string = "", categoryId?: string) {
        if (!filterText && !categoryId) return this.models;

        const lowercaseFilterText = (filterText || "").toLowerCase();

        let filteredModels = this.models;

        if (categoryId) {
            filteredModels = filteredModels.filter((template) => {
                return !!template.relationships.categories.get(categoryId);
            });
        }
        if (filterText) {
            filteredModels = filteredModels.filter((template) => {
                return (
                    template.attributes
                        .name!.toLowerCase()
                        .indexOf(lowercaseFilterText) !== -1
                );
            });
        }

        return filteredModels;
    }
}
export default SheetTemplatesCollection;
