import { observable } from "mobx";
import Model from "data/Model";
import Project from "./Project";
import Sheet from "./Sheet";
import User from "./User";

interface AuditLogAttributes {
    type: string | null;
    firstSeen: string | null;
    lastSeen: string | null;
    action: string | null;
}

interface AuditLogRelationships {
    project: Project | null;
    sheet: Sheet | null;
    user: User | null;
}

export default class AuditLog extends Model {
    type = "auditLogs";
    attributes = observable.object({
        type: undefined,
        firstSeen: undefined,
        lastSeen: undefined,
        action: undefined,
    } as any as AuditLogAttributes);

    relationships = observable.object(
        {
            project: undefined,
            sheet: undefined,
            user: undefined,
        } as any as AuditLogRelationships,
        {},
        { deep: false },
    );
}
