import abortableRequest from "./abortableRequest";
import math from "math";
import simplifyMathjsObjects from "math/simplifyMathjsObjects";
import { getCurrentSession } from "./apiRequest";
import { read } from "data/utils/objectCrud";
import { untracked } from "mobx";
import { getClientConfig } from "clientConfig";

export default function remoteLambda(
    lambdaName: string,
    method: string,
    data: any,
    enableTypes: boolean,
) {
    let apiPath = lambdaName.toLowerCase(); // "GIS" → "gis", "Solver" → "solver"

    // HERE BE DRAGONS - we have a couple historical "solvers" that actually require GIS
    // capabilities, and were therefore moved to the GIS lambda. Rather than editing historical
    // calculator templates to change `remote` widgets to `gis` widgets, we're going to just
    // forward these calls directly to the GIS lambda instead.
    if (
        method === "gisSignificantOrography" ||
        method === "gisRoughnessPoint" ||
        method === "gisRegion" ||
        method === "usgs_api"
    ) {
        apiPath = "gis";
    }

    const cleanedRequest = JSON.stringify(data, simplifyMathjsObjects);
    const sessionModel = getCurrentSession();

    function makeRequest() {
        // Read the token without mobx tracking, so mobx doesn't retry this function when we update the token below.
        // We want to limit the retries to 1, so we don't get into an infinite loop if something goes wrong.
        const solverToken = untracked(
            () => sessionModel.attributes.solverToken,
        );

        if (!solverToken) {
            throw new Error(
                `No solverToken - can't call lambda function ${lambdaName}`,
            );
        }

        return abortableRequest(
            `${getClientConfig().solverBaseUrl || ""}/api/${apiPath}/${method}`,
            {
                method: "POST",
                body: cleanedRequest,
                responseType: "json",
                jsonReviver: enableTypes ? math.reviver : simplifyMathjsObjects,
                headers: {
                    Authorization: `Bearer ${solverToken}`,
                },
            },
        );
    }

    return makeRequest().then(null, (err) => {
        if (err.meta && err.meta._request.status === 401) {
            return read(sessionModel).then(() => makeRequest());
        } else {
            throw err;
        }
    }) as Promise<any>;
}
