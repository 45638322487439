import math from "math";
import { MathExpression, ParseOptions } from "mathjs";

/**
 * MathJS parse is a typed-function. It doesn't natively support parsing numbers
 * although it can correctly deal with them as strings.
 * Historically we have saved certain values as numbers in the template json.
 * e.g. conditions & results of a widget's equation attribute.
 */
export default function parseWithNumberSupport(
    value: MathExpression | number,
    options: ParseOptions = {},
) {
    // MathJS parses strings as numbers if it can convert them.
    // - math.parse("1")
    // - math.parse("Infinity")
    // - math.parse("NaN")
    if (typeof value === "number") {
        return math.parse(value.toString(), options);
    } else {
        return math.parse(value, options);
    }
}
