import { observable } from "mobx";
import Model from "data/Model";

interface UserActionAttributes {
    action: string | null;
    sessionToken: string | null;
    userId: string | null;
}

class UserActionModel extends Model {
    type = "userActions";

    attributes = observable.object({
        action: undefined,
        sessionToken: undefined,
        userId: undefined,
    } as any as UserActionAttributes);

    relationships = null;

    get consumeActionUrl() {
        return `${this.url}/actions/consume`;
    }
}
export default UserActionModel;
