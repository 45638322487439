import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { InputAttributes } from "./SheetTemplateWidgetInterface";

class InputSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "input",
        label: undefined,
        symbol: undefined,
        units: undefined,
        units2: undefined,
        unitsMks: undefined,
        unitsFps: undefined,
        export: undefined,
        hidden: undefined,
        visibleIf: undefined,
        showInSuperSummary: undefined,

        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        referenceImage: undefined,
        defaultValue: undefined,
        defaultValueMks: undefined,
        defaultValueFps: undefined,
        checks: [],

        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,

        order: undefined,
    } as any as InputAttributes);

    isUserInput = true;

    unitOverride(unitSystem: string | null | undefined): string | null {
        if (unitSystem === "MKS" && this.attributes.unitsMks) {
            return this.attributes.unitsMks;
        } else if (unitSystem === "FPS" && this.attributes.unitsFps) {
            return this.attributes.unitsFps;
        } else {
            return null;
        }
    }
}

export default InputSheetTemplateWidgetModel;
