import Collection from "../Collection";
import SheetUpgrade from "../models/SheetUpgrade";

class SheetUpgradesCollection extends Collection<SheetUpgrade> {
    type = "sheetUpgrades";
    projectId = null;

    get url() {
        return `/projects/${this.projectId}/relationships/sheetUpgrades`;
    }
}
export default SheetUpgradesCollection;
