// Colors (https://www.figma.com/file/9m8FYvxfOx0DsyzO2f2De6/ClearCalcs-Design-System-2022?node-id=73%3A977)

// Primary (Blue)
const blue200 = "#EAF5FE";
const blue300 = "#BBDEFA";
const blue400 = "#0076D6";
const blue500 = "#0C63AA";
const blue600 = "#0B4778";
const blue700 = "#03213B";

// Secondary (Yellow)
const yellow200 = "#FFFFCC";
const yellow300 = "#FFE974";
const yellow400 = "#FFCC00";
const yellow500 = "#C19B00";
const yellow600 = "#8E7200";
const yellow700 = "#524200";

// Tertiary (Purple)
const purple200 = "#EADCFC";
const purple300 = "#C195F7";
const purple400 = "#974FF2";
const purple500 = "#7B40C6";
const purple600 = "#5F309A";
const purple700 = "#351959";

// Successful (Green)
const green200 = "#CFF8C3";
const green300 = "#89CB74";
const green400 = "#3AA917";
const green500 = "#2B840F";
const green600 = "#23650E";
const green700 = "#174409";

// Warning (Orange)
const orange200 = "#FFEDCC";
const orange300 = "#FFC966";
const orange400 = "#FFA500";
const orange500 = "#E39300";
const orange600 = "#996300";
const orange700 = "#664200";

// Error (Red)
const red100 = "#FFF3F2";
const red200 = "#FFD1CD";
const red300 = "#FF8679";
const red400 = "#FF3520";
const red500 = "#B91100";
const red600 = "#6F0A00";
const red700 = "#4D0D06";

// Neutral (Grey)
const grey50 = "#F8F8F8";
const grey100 = "#EEEEEE";
const grey200 = "#D0D0D0";
const grey300 = "#BDBDBD";
const grey400 = "#808080";
const grey500 = "#404040";
const grey600 = "#282828";
const grey700 = "#111111";

export {
    blue200,
    blue300,
    blue400,
    blue500,
    blue600,
    blue700,
    yellow200,
    yellow300,
    yellow400,
    yellow500,
    yellow600,
    yellow700,
    purple200,
    purple300,
    purple400,
    purple500,
    purple600,
    purple700,
    green200,
    green300,
    green400,
    green500,
    green600,
    green700,
    orange200,
    orange300,
    orange400,
    orange500,
    orange600,
    orange700,
    red100,
    red200,
    red300,
    red400,
    red500,
    red600,
    red700,
    grey50,
    grey100,
    grey200,
    grey300,
    grey400,
    grey500,
    grey600,
    grey700,
};

type Blues =
    | typeof blue200
    | typeof blue300
    | typeof blue400
    | typeof blue500
    | typeof blue600
    | typeof blue700;
type Yellows =
    | typeof yellow200
    | typeof yellow300
    | typeof yellow400
    | typeof yellow500
    | typeof yellow600
    | typeof yellow700;
type Purples =
    | typeof purple200
    | typeof purple300
    | typeof purple400
    | typeof purple500
    | typeof purple600
    | typeof purple700;
type Greens =
    | typeof green200
    | typeof green300
    | typeof green400
    | typeof green500
    | typeof green600
    | typeof green700;
type Oranges =
    | typeof orange200
    | typeof orange300
    | typeof orange400
    | typeof orange500
    | typeof orange600
    | typeof orange700;
type Reds =
    | typeof red200
    | typeof red300
    | typeof red400
    | typeof red500
    | typeof red600
    | typeof red700;
type Greys =
    | typeof grey50
    | typeof grey100
    | typeof grey200
    | typeof grey300
    | typeof grey400
    | typeof grey500
    | typeof grey600
    | typeof grey700;
type BlackWhiteCurrent = "black" | "white" | "currentColor";

export type Color =
    | BlackWhiteCurrent
    | Blues
    | Yellows
    | Purples
    | Greens
    | Oranges
    | Reds
    | Greys;
