import { observable } from "mobx";
import Model from "data/Model";
import { DataColumnCommon } from "./SheetTemplateWidgetInterface";
import OrganisationModel from "./Organisation";
import User from "./User";
import UploadModel from "./Upload";
interface SharedTableAttributes {
    code: string | null;
    version: string | null;
    rows: any | null;
    columns: DataColumnCommon[] | null;
    createdAt: string | null;
    updatedAt: undefined;
}

interface SharedTableRelationships {
    creator: User;
    upload: UploadModel;
    builderOrganisation: OrganisationModel;
}

export default class SharedTable extends Model {
    type = "sharedTables";
    attributes = observable.object({
        code: undefined,
        version: undefined,
        rows: undefined,
        columns: undefined,
        createdAt: undefined,
        updatedAt: undefined,
    } as any as SharedTableAttributes);

    relationships = observable.object(
        {
            upload: undefined,
            builderOrganisation: undefined,
            creator: undefined,
        } as any as SharedTableRelationships,
        {},
        { deep: false },
    );
}
