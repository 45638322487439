import { observable } from "mobx";
import Model from "data/Model";

import Organisation from "./Organisation";
import OneTimeState from "./OneTimeState";

interface UserAttributes {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    isActivated: string | null;
    globalRoles: string | null;
    helpScoutSignature: string | null;
    analyticsSuperProperties: any | null;
    hasApiToken: boolean | null;
    isPurged: boolean | null;
    experimentGroupNumber: number | null;

    referralCode: string | null;
    referralUrl: string | null;

    occupation: string | null;

    signupProvider: string | null;
    signupMethod: string | null;
}

interface UserRelationships {
    primaryOrganisation: Organisation | null;
    oneTimeState: OneTimeState | null;
}

class UserModel extends Model {
    type = "users";
    attributes = observable.object({
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        isActivated: undefined,
        globalRoles: undefined,
        helpScoutSignature: undefined,
        analyticsSuperProperties: undefined,
        referralCode: undefined,
        referralUrl: undefined,
        occupation: undefined,
        signupProvider: undefined,
        signupMethod: undefined,
        hasApiToken: undefined,
        isPurged: undefined,
        experimentGroupNumber: undefined,
    } as any as UserAttributes);

    relationships = observable.object(
        {
            primaryOrganisation: undefined,
            oneTimeState: undefined,
        } as any as UserRelationships,
        {},
        { deep: false },
    );

    get fullName() {
        if (this.attributes.firstName && this.attributes.lastName) {
            return `${this.attributes.firstName} ${this.attributes.lastName}`;
        } else if (this.attributes.firstName) {
            return `${this.attributes.firstName}`;
        }

        return `${this.attributes.email}`;
    }

    get userIcon() {
        if (this.attributes.firstName && this.attributes.lastName) {
            return `${this.attributes.firstName[0]}${this.attributes.lastName[0]}`;
        } else if (this.attributes.firstName) {
            return `${this.attributes.firstName[0]}`;
        } else {
            return null;
        }
    }
}
export default UserModel;
