import { ClientConfig } from "clientConfig";

export default function rollbarConfig(clientConfig: ClientConfig) {
    return {
        enabled: clientConfig.enableRollbar,
        accessToken: "f346a2a8e6494982ba405b532eeb1781",
        verbose: true,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            environment: clientConfig.environment,
            client: {
                javascript: {
                    source_map_enabled: true, // eslint-disable-line camelcase
                    code_version: process.env.NEXT_PUBLIC_ROLLBAR_CODE_VERSION, // eslint-disable-line
                },
            },
        },
        checkIgnore: function (isUncaught, args, payload) {
            const error = args[0];
            if (
                error instanceof Error &&
                SUPPRESSED_ERROR_MESSAGES.includes(error.message)
            ) {
                return true;
            }

            return false;
        },
    };
}

// List of Error messages that we want to suppress and not send to Rollbar,
// as they are noise and either cause no issues to users, or caused by things
// outside of our control.
//
// NOTE - If you need to add to this list, please make sure to add a comment
// on what an why you are suppressing it.
const SUPPRESSED_ERROR_MESSAGES = [
    // As discussed in the following links, we can safely ignore this error.
    //
    // https://stackoverflow.com/a/50387233/495876
    // https://github.com/WICG/resize-observer/issues/38
    // https://github.com/WICG/resize-observer/issues/38#issuecomment-334816361
    // https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
    //
    // > "This error means that ResizeObserver was not able to deliver all observations
    // within a single animation frame. It is benign and can be ignored."
    //
    // Some other discussions, suggest external plug-ins, like LastPass, might exaserbate
    // this error, when trying to control input fields.
    //
    // https://stackoverflow.com/q/78835510/495876
    // https://stackoverflow.com/a/78127203/495876
    // https://github.com/Scirra/Construct-bugs/issues/6500
    //
    // NOTE - This error comes in 2 flavours, one is primary triggered by Chrome-based
    // browsers, and the other by Firefox. They are equivalent in nature.
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",

    // This is a very nondescript error that appears in Rollbar from time to time. I have
    // sampled 20 occurrences, and they only happen when trying to load an external source,
    // like a script from a third party.
    //
    // There is a good write up about this error here https://raygun.com/blog/script-error/
    //
    // This is unlikely to cause any issue to the platform, and most loaders have retry
    // mechanisms in place, so we can safely ignore this error.
    "Script error",
];
