import getConfig from "next/config";

export interface ClientConfig extends Features {
    clientUrl?: string; // For print reference links, set by backend
    baseUrl?: string;
    solverBaseUrl?: string;
    enableAnalytics: boolean;
    enableRollbar: boolean;
    environment: string;
    stripePublishableKey: string;
    enableTemplateEditing: boolean;
    testingBanner: boolean;
    enableFramingToolImport?: boolean;
    sampleOrganisationId?: string;
    freeToolsEmbeddedOrganisationId?: string;
    segmentToken?: string;
    churnkeyAppId?: string;
    launchDarklyClientID?: string;
    googleMapsApiKey?: string;
    googleMapsMapId?: string;
    CLIENT_CMS_API_READ_KEY?: string;
    customerioSiteId?: string;
}
export interface Features {}

// TODO: Refactor this whole file using next's get and set config functions
// rather than rebinding it through this file.
let clientConfig: ClientConfig = {} as ClientConfig;
if (process.env.NODE_ENV === "test") {
    clientConfig = {
        environment: "test",
        enableAnalytics: false,
        enableRollbar: false,
        stripePublishableKey: "test",
        enableTemplateEditing: false,
        testingBanner: false,
    };
} else {
    try {
        // publicRuntimeConfig is not set when the build step happens,
        // default to empty opject for that step.
        const { publicRuntimeConfig } = getConfig();
        if (publicRuntimeConfig) {
            clientConfig = publicRuntimeConfig;
        }
    } catch (e) {
        // Do nothing.
    }
}

export function getClientConfig(): ClientConfig {
    if (clientConfig) {
        return clientConfig;
    } else {
        throw new Error("clientConfig hasn't been set");
    }
}
export function setClientConfig(newClientConfig: ClientConfig) {
    clientConfig = newClientConfig;
}
