import { action, observable } from "mobx";
import Model from "../Model";
import Universe from "../Universe";

interface EditableInterface<T> {
    constructor: {
        isEditableProxy: true;
    };
    model: T;
    setAttributes(newAttributes: any, options?: { optimistic: boolean }): void;
    setRelationships(
        newRelationships: any,
        options?: { optimistic: boolean },
    ): void;
    hasChanges(): boolean;
}

export type EditableModel<T extends Model> = T & EditableInterface<T>;

function editableModelProxy<T extends Model>(
    model: T,
    opts: {
        restrictAttributes?: string[];
        restrictRelationships?: string[];
    } = {},
): EditableModel<T> {
    const modelInstance = model as any;
    if (modelInstance.constructor.isEditableProxy) {
        throw new TypeError(`modelInstance is already an EditableModel`);
    }

    class EditableModel extends modelInstance.constructor {
        static isEditableProxy = true;

        model: Model;
        universe: Universe;

        constructor(model) {
            super(model.id, { iAmEditableModelProxy: true });
            this.model = model;
            this.universe = model.universe;

            if (opts.restrictAttributes) {
                const attributes = {};
                opts.restrictAttributes.forEach((a) => {
                    attributes[a] = model.attributes[a];
                });

                (this as any).attributes = observable(attributes);
            } else {
                this.setAttributes(model.attributes);
            }

            if (opts.restrictRelationships) {
                const relationships = {};
                opts.restrictRelationships.forEach((a) => {
                    relationships[a] = model.relationships[a];
                });

                (this as any).relationships = observable(relationships);
            } else {
                this.setRelationships(model.relationships);
            }
        }

        hasChanges = () => {
            for (const attributeName in this.attributes) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.attributes,
                        attributeName,
                    ) &&
                    Object.prototype.hasOwnProperty.call(
                        this.model.attributes,
                        attributeName,
                    ) &&
                    this.attributes[attributeName] !==
                        this.model.attributes[attributeName]
                ) {
                    return true;
                }
            }

            for (const relationshipName in this.relationships) {
                if (
                    Object.prototype.hasOwnProperty.call(
                        this.relationships,
                        relationshipName,
                    ) &&
                    Object.prototype.hasOwnProperty.call(
                        this.model.relationships,
                        relationshipName,
                    ) &&
                    this.relationships[relationshipName] !==
                        this.model.relationships[relationshipName]
                ) {
                    return true;
                }
            }

            return false;
        };

        setAttributes = action(
            (newAttributes, { optimistic } = { optimistic: false }) => {
                this._setAttributes(newAttributes);

                // Optimistically update the underlying model.
                // If any API requests return different values this
                // new value will be stomped. Use sparingly.
                if (optimistic) {
                    this.model._setAttributes(newAttributes as any);
                }
            },
        );

        setRelationships = action(
            (newRelationships, { optimistic } = { optimistic: false }) => {
                this._setRelationships(newRelationships);

                // Optimistically update the underlying model.
                // If any API requests return different values this
                // new value will be stomped. Use sparingly.
                if (optimistic) {
                    this.model._setRelationships(newRelationships as any);
                }
            },
        );
    }
    return new EditableModel(modelInstance) as any;
}
export default editableModelProxy;
