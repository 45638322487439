import Model from "./Model";
import * as defaultModelsByType from "data/models";
import * as defaultCollectionsByType from "data/collections";
import Collection from "./Collection";

export default class Universe {
    map = new Map();

    constructor({ modelsByType, collectionsByType } = {}) {
        this.modelsByType = modelsByType || defaultModelsByType;
        this.collectionsByType = collectionsByType || defaultCollectionsByType;
    }

    // Create new unsaved model for submission to the API
    createModel(type, resource) {
        const ModelClass = this.modelsByType[type];
        let model;
        if (ModelClass.prototype instanceof Model) {
            model = new ModelClass(null, { iAmTheUniverse: true });
        } else {
            // Factory function
            model = ModelClass(resource, { iAmTheUniverse: true });
        }

        model.universe = this;
        return model;
    }

    // Add a newly created model to the universe
    addModel(model) {
        const key = modelKey(model.type, model.id);
        if (this.map.has(key)) {
            throw new Error(
                `Can't add model to universe. Instance with key "${key}" already exists.`,
            );
        } else {
            if (model.universe && model.universe !== this) {
                throw new Error(`Model already belongs to another Universe.`);
            }
            if (model.constructor.isEditableProxy) {
                throw new TypeError(
                    `Can't add editable proxy to Universe. Add the inner model instead.`,
                );
            }
            model.universe = this;
            this.map.set(key, model);
        }
    }

    // Get model with known ID from the universe, or instantiate it if it doesn't exist
    getModel(type, id, resource = null) {
        const key = modelKey(type, id);

        if (!this.map.has(key)) {
            const ModelClass = this.modelsByType[type];
            if (typeof ModelClass !== "function") {
                throw new Error(`Could not find model class for type: ${type}`);
            } else {
                let model;
                if (ModelClass.prototype instanceof Model) {
                    model = new ModelClass(id, { iAmTheUniverse: true });
                } else {
                    // Factory function
                    model = ModelClass(resource, { iAmTheUniverse: true });
                }
                this.addModel(model);
            }
        }

        return this.map.get(key);
    }

    // Create a collection for a particular type for storing/fetching models belong to this Universe.
    // The Collection itself is not tracked by the Universe.
    createCollection(type, modelsArray) {
        const CollectionClass = this.collectionsByType[type] || Collection;
        const collection = new CollectionClass(modelsArray);
        collection.universe = this;
        return collection;
    }

    get isEmpty() {
        return this.map.size === 0;
    }
}

function modelKey(type, id) {
    if (id == null) {
        throw new Error(`Can't generate key for model without id`);
    }
    return `${type}/${id}`;
}
