import { observable } from "mobx";
import Model from "data/Model";

import Sheet from "./Sheet";
import Project from "./Project";
import Session from "./Session";
import SheetTemplate from "./SheetTemplate";

interface EmbeddedBootstrapRelationships {
    sheet: Sheet | null;
    project: Project | null;
    session: Session | null;
    sheetTemplate: SheetTemplate | null;
    unitSystem: string | null;
}

interface EmbeddedBootstrapAttributes {
    templateCode: string | null;
    organisationId: string | null;
    referrer: string | null;
}

export default class EmbeddedBootstrap extends Model {
    type = "embeddedBootstraps";
    attributes = observable.object({
        templateCode: undefined,
        organisationId: undefined,
        unitSystem: undefined,
        printMode: undefined,
        referrer: undefined,
    } as any as EmbeddedBootstrapAttributes);

    relationships = observable.object(
        {
            sheet: undefined,
            project: undefined,
            sheetTemplate: undefined,
            session: undefined,
        } as any as EmbeddedBootstrapRelationships,
        {},
        { deep: false },
    );
}
