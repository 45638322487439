import { computed, makeObservable, reaction } from "mobx";
import Collection from "../Collection";
import Sheet from "../models/Sheet";

class SheetsCollection extends Collection<Sheet> {
    type = "sheets";

    // We need to have a consistent reference to something
    // that contains all the ungrouped sheets, so that we'll
    // get the correct updated where ever this is referenced.
    //
    // The computed function will make sure it's up to date.
    _ungroupedSheets;

    constructor(models, ungroupedSheets = false) {
        super(models);
        makeObservable(this, {
            ungroupedSheets: computed,
        });
        // Prevent recursive creation of the ungrouped
        // collection.
        if (!ungroupedSheets) {
            this._ungroupedSheets = new SheetsCollection(null, true);

            reaction(
                () => this.models.filter((s) => !s.relationships.sheetGroup),
                (data) => {
                    this._ungroupedSheets._setModels(data);
                },
                {
                    fireImmediately: true,
                },
            );
        }
    }

    get ungroupedSheets() {
        return this._ungroupedSheets;
    }
}
export default SheetsCollection;
