import { observable, computed, makeObservable, runInAction } from "mobx";
import Model from "data/Model";
import omit from "lodash.omit";
import SheetTemplateWidgetsCollection from "data/collections/SheetTemplateWidgets";
import LookupSheetTemplateWidgetModel from "data/models/LookupSheetTemplateWidget";
import Collection from "data/Collection";
import Category from "./Category";
import Preset from "./Preset";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import SheetTemplateUpgradeModel from "./SheetTemplateUpgrade";
import SheetTemplateCodeModel from "data/models/SheetTemplateCode";
import OrganisationModel from "./Organisation";
import User from "./User";
import equationToTexFactory from "math/equationToTexFactory";
import makeReferenceTransformer from "math/makeReferenceTransformer";
import parseWithNumberSupport from "math/parseWithNumberSupport";
import CustomDiagramSheetTemplateWidgetModel from "./CustomDiagramSheetTemplateWidget";

import SheetTest, { SheetTestResults } from "data/models/SheetTest";

export type UpdateSeverity =
    | "feature"
    | "class0"
    | "class1"
    | "class2a"
    | "class2b"
    | "class3"
    | "obsolete";

export const UPDATE_SERVERITIES = [
    "feature",
    "class0",
    "class1",
    "class2a",
    "class2b",
    "class3",
    "obsolete",
];

interface SheetTemplateAttributes {
    name: string | null;
    icon: string | null;
    description: string | null;
    templateShortName: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    order: number | null;
    version: number | null;
    published: boolean | null;
    lastPublishedAt: string | null;
    isDraft: boolean | null;
    code: string | null;
    builderCode: string | null;
    conversionClass: string | null;
    projectDefaults: boolean | null;
    buildingStandards: string[] | null;
    changelog: string | null;
    updateSeverity: UpdateSeverity | null;
    forceUpdateReason: string | null;
    references: string | null;
    instructions: string | null;
    assumptions: string[] | null;
    helpScoutArticleIds: string[] | null;
    detailedDescription: string | null;
    strictnessLevel: number | null;
    enableTypedLinking: boolean | null;
    commitHash: string | null;
    schemaVersion: number | null;
    showPassFail: boolean | null;
    available: boolean | null;
    capabilities: string[] | null;
    importReferenceIds: string[] | null;
    assumptionsText: string | null;
    assumptionsHtml: string | null;
    hideAllSymbols: boolean | null;
    epoch: number | null;
}

interface SheetTemplateRelationships {
    sheetTemplateWidgets: SheetTemplateWidgetsCollection;
    categories: Collection<Category>;
    presets: Collection<Preset>;
    sheetTemplateUpgrade: SheetTemplateUpgradeModel;
    latestVersion: SheetTemplateModel | null;
    latestVersionAnyStatus: SheetTemplateModel | null;
    sheetTemplateCode: SheetTemplateCodeModel;
    builderOrganisation: OrganisationModel;
    creator: User;
    sheetTests: Collection<SheetTest>;
}

class SheetTemplateModel extends Model {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            _sheetTestResults: observable,
            sheetTestResults: computed,
            previewErrors: observable,
            sheetTemplateWidgetsByReferenceId: computed,
            readOnly: computed,
        });
    }
    type = "sheetTemplates";
    attributes = observable.object({
        name: undefined,
        icon: undefined,
        description: undefined,
        templateShortName: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        order: undefined,
        version: undefined,
        published: undefined,
        lastPublishedAt: undefined,
        isDraft: undefined,
        code: undefined,
        builderCode: undefined,
        conversionClass: undefined,
        projectDefaults: undefined,
        buildingStandards: undefined,
        changelog: undefined,
        updateSeverity: undefined,
        forceUpdateReason: undefined,
        references: undefined,
        instructions: undefined,
        assumptions: undefined,
        helpScoutArticleIds: undefined,
        detailedDescription: undefined,
        strictnessLevel: undefined,
        enableTypedLinking: undefined,
        commitHash: undefined,
        schemaVersion: undefined,
        showPassFail: undefined,
        available: undefined,
        capabilities: undefined,
        importReferenceIds: undefined,
        assumptionsText: undefined,
        assumptionsHtml: undefined,
        hideAllSymbols: undefined,
        epoch: undefined,
    } as any as SheetTemplateAttributes);
    relationships = observable.object(
        {
            sheetTemplateWidgets: undefined,
            categories: undefined,
            presets: undefined,
            sheetTemplateUpgrade: undefined,
            latestVersion: undefined,
            latestVersionAnyStatus: undefined,
            sheetTemplateCode: undefined,
            builderOrganisation: undefined,
            creator: undefined,
            sheetTests: undefined,
        } as any as SheetTemplateRelationships,
        {},
        { deep: false },
    );

    previewErrors: Array<{
        referenceId: string;
        path: string;
        message: string;
    }> = [];

    _sheetTestResults: SheetTestResults | null = null;
    _executedSheetTests: string[] = [];

    get sheetTemplateWidgetsByReferenceId(): Map<
        string,
        SheetTemplateWidgetModel
    > {
        return new Map(
            this.relationships.sheetTemplateWidgets.models.map(
                (sheetTemplateWidget) => [
                    sheetTemplateWidget.attributes.referenceId,
                    sheetTemplateWidget,
                ],
            ),
        );
    }

    get hasSheetTests() {
        return (
            this.relationships.sheetTests &&
            this.relationships.sheetTests.models.length > 0
        );
    }

    get readOnly(): boolean {
        if (!this.relationships.latestVersionAnyStatus) {
            throw new Error(
                "Sideload latestVersionAnyStatus relationship before checking read only",
            );
        }
        return (
            this.attributes.version !==
            this.relationships.latestVersionAnyStatus.attributes.version
        );
    }

    equationAsTex(
        equation: string,
        renderRemote: boolean = true,
        tableSheetTemplateWidget?,
    ) {
        const equationToTexHandler = equationToTexFactory(
            this,
            renderRemote,
            tableSheetTemplateWidget,
        );

        try {
            return parseWithNumberSupport(equation)
                .transform(makeReferenceTransformer(this))
                .toTex({ handler: equationToTexHandler });
        } catch (e) {
            return "";
        }
    }

    get presetsWithoutTestingOnly(): Preset[] {
        return this.relationships.presets.models.filter(
            (p) => !p.attributes.testingOnly,
        );
    }

    // If translateBuilderCode is true, remove builderCode value, and assign builderCode value
    // to code. We don't want to have the builderCode key in the exported string because
    // we don't want our engineers uploading templates to github with this key. It's only
    // a temporary value being used for saving codes in public builder templates
    toExportString(translateBuilderCode?: boolean) {
        const templateJSON = this.toResource();
        const widgetJSON = this.relationships.sheetTemplateWidgets.toResource();
        const presetsJSON = this.relationships.presets.toResource();

        let upgradesJSON;
        if (this.relationships.sheetTemplateUpgrade) {
            upgradesJSON = this.relationships.sheetTemplateUpgrade.toResource();
            delete upgradesJSON.id;
            delete upgradesJSON.relationships;
        }

        const strippedWidgetJSON = widgetJSON.map((widget) => {
            const fullWidget = this.sheetTemplateWidgetsByReferenceId.get(
                widget.attributes.referenceId,
            );

            if (fullWidget instanceof LookupSheetTemplateWidgetModel) {
                // The shared table relationship rather than sharedTableId attribute,
                // is the source of truth. In JSON, however, the sharedTableId needs to be added,
                // for the widget to be valid.
                if (widget.relationships?.sharedTable?.data !== null) {
                    widget.attributes.sharedTableId =
                        fullWidget.relationships.sharedTable?.attributes.code;
                } else {
                    delete widget.attributes.sharedTableId;
                }
            } else if (
                fullWidget instanceof CustomDiagramSheetTemplateWidgetModel
            ) {
                const uploadModel = fullWidget.relationships.upload;
                const uploadInteractiveModel =
                    fullWidget.relationships.uploadInteractive;

                if (!uploadModel && !uploadInteractiveModel) {
                    throw new TypeError(
                        `Custom diagram must have at least one upload (static or interactive).`,
                    );
                }

                if (uploadModel) {
                    widget.attributes.uploadUrl = uploadModel.attributes.url;
                }
                if (uploadInteractiveModel) {
                    widget.attributes.uploadInteractiveUrl =
                        uploadInteractiveModel.attributes.url;
                }
            }

            if (widget.attributes.hasOwnProperty("equation")) {
                if (Array.isArray(widget.attributes.equation)) {
                    widget.attributes.equation =
                        widget.attributes.equation.filter(
                            (eq) => !!eq.condition || !!eq.result,
                        );
                } else {
                    widget.attributes.equation = [];
                }
            }

            if (widget.attributes.hasOwnProperty("checks")) {
                if (Array.isArray(widget.attributes.checks)) {
                    // TODO: Confirm whether the filter should change for passfail & utilisation checks
                    widget.attributes.checks = widget.attributes.checks.filter(
                        (eq) => !!eq.condition || !!eq.result,
                    );
                } else {
                    widget.attributes.checks = [];
                }
            }

            return {
                type: widget.type,
                attributes: omit(
                    widget.attributes,
                    [
                        "createdAt",
                        "updatedAt",
                        "order",
                        "descriptionHtml",
                        "referencesHtml",
                    ].concat(
                        widget.attributes.sharedTableId
                            ? ["dataTable", "dataColumns"]
                            : [],
                    ),
                ),
            };
        });

        const strippedPresetsJSON = presetsJSON.map((preset) => {
            return {
                type: preset.type,
                attributes: preset.attributes,
            };
        });

        const included = [...strippedPresetsJSON, ...strippedWidgetJSON];
        if (upgradesJSON) {
            included.push(upgradesJSON);
        }

        const outputJSON = {
            data: {
                type: templateJSON.type,
                attributes: omit(
                    {
                        ...templateJSON.attributes,
                        code: translateBuilderCode
                            ? templateJSON.attributes.builderCode
                            : templateJSON.attributes.code,
                    },
                    [
                        "changelog",
                        "updateSeverity",
                        "forceUpdateReason",
                        "commitHash",
                        "createdAt",
                        "updatedAt",
                        "order",
                        "version",
                        "published",
                        "isDraft",
                        "available",
                        "builderCode",
                        "assumptionsHtml",
                        "epoch",
                    ],
                ),
                relationships: {
                    categories: templateJSON.relationships.categories,
                },
            },
            included,
        };

        return JSON.stringify(outputJSON, null, 4);
    }

    get sheetTestResults() {
        return this._sheetTestResults || {};
    }

    get executedSheetTests() {
        return this._executedSheetTests || [];
    }

    getSheetTestResultsByPreset(
        presetCode: String,
    ): SheetTestResults | undefined {
        if (this._sheetTestResults == null) return;
        if (Object.keys(this._sheetTestResults).length === 0) return;

        var sheetTestResults: SheetTestResults = {};

        for (const [referenceId, sheetWidgetResults] of Object.entries(
            this._sheetTestResults,
        )) {
            var relevantResults = sheetWidgetResults.filter(
                (srw) => srw.preset === presetCode,
            );
            if (relevantResults.length > 0) {
                sheetTestResults[referenceId] = relevantResults;
            }
        }

        return sheetTestResults;
    }

    addSheetTestResults(sheetTestName: string, results: SheetTestResults) {
        runInAction(() => {
            if (!this._sheetTestResults) {
                this._sheetTestResults = {};
            }

            this._executedSheetTests.push(sheetTestName);

            for (const [referenceId, widgetResult] of Object.entries(results)) {
                if (!this._sheetTestResults[referenceId]) {
                    this._sheetTestResults[referenceId] = [];
                }

                this._sheetTestResults[referenceId].push(...widgetResult);
            }
        });
    }

    set sheetTestResults(results: SheetTestResults) {
        runInAction(() => {
            this._sheetTestResults = results;
        });
    }

    clearTestResults() {
        runInAction(() => {
            this._sheetTestResults = null;
            this._executedSheetTests = [];
        });
    }
}

export default SheetTemplateModel;

export function updateSeverityDescription(updateSeverity: UpdateSeverity) {
    switch (updateSeverity) {
        case "feature":
            return "Feature";
        case "obsolete":
            return "Obsolete";
        case "class0":
            return "Minor Bug Fix";
        case "class1":
        case "class2a":
            return "Bug Fix";
        case "class2b":
        case "class3":
            return "Critical Bug Fix";
        default:
            return "Unknown";
    }
}
