import { EffectCallback, useEffect } from "react";

// Hook for only running an effect once. We currently use this to act like on
// componentDidMount, but that's not the correct way to think about it, but this
// does surpress the warning about missing dependencies.
export function useEffectOnce(effect: EffectCallback) {
    if (process.env.NODE_ENV !== "production") {
        console.warn(
            "useEffectOnce shouldn't need to exist, you might not understand how hooks work.",
            "Please make sure any usage is commented, and consider refactoring to use a normal `useEffect`.",
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
}
