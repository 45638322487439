import Model from "data/Model";
import { makeObservable, observable, computed, action } from "mobx";

export interface ExpectedResults {
    actual: number;
    expected: number;
}
export interface SheetWidgetResult {
    preset: string | null;
    expected: ExpectedResults;
}
export interface SheetTestResults {
    [sheetWidgetReferenceId: string]: SheetWidgetResult[];
}

export interface SheetTestAttributes {
    name: string | null;
    type: string | null;
    values: CommonValues | TestValues;
}

export interface CommonValues {}
export interface TestValues extends CommonValues {
    preset: string | null;
    buildingStandard: string | null;
    expected: Expected | null;
}
export interface Expected {
    checks: Object;
    values: Object;
    selectors: Object;
}

class SheetTestModel extends Model {
    type = "sheetTests";
    attributes = observable.object({
        name: undefined,
        type: undefined,
        values: undefined,
    } as any as SheetTestAttributes);

    relationships = observable.object({
        sheetTemplate: undefined,
        preset: undefined,
    });
}
export default SheetTestModel;
