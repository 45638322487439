import { ProviderConfig as LDProviderConfig } from "launchdarkly-react-client-sdk";
import * as LDClient from "launchdarkly-js-client-sdk";

import { getClientConfig } from "clientConfig";

let LAUNCH_DARKLY_CLIENT;
export const LAUNCH_DARKLY_CONFIG: LDProviderConfig = {
    clientSideID: getClientConfig().launchDarklyClientID!,
    options: {
        allAttributesPrivate: true,
        bootstrap: "localStorage",
        diagnosticOptOut: true,
    },
};

if (typeof window !== "undefined" && !!LAUNCH_DARKLY_CONFIG.clientSideID) {
    LAUNCH_DARKLY_CLIENT = LDClient.initialize(
        LAUNCH_DARKLY_CONFIG.clientSideID,
        // We give the user a marketing side id; we later add the
        // correct userId in the sessionStore context when the user
        // has logged in and we know who they are. This marketing side
        // id is given back if the user logs out.
        { kind: "user", anonymous: true },
        {
            allAttributesPrivate: true,
            bootstrap: "localStorage",
            diagnosticOptOut: true,
        },
    );
}

const launchdarkly = {
    instance: LAUNCH_DARKLY_CLIENT,
    config: LAUNCH_DARKLY_CONFIG,
};
export default launchdarkly;
