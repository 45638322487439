import { observable } from "mobx";
import Model from "data/Model";

import Session from "./Session";

interface OnboardingAttributes {
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    password: string | null;
    phone: string | null;
    orgName: string | null;
    estimatedTeamSize: "solo" | "smallTeam" | "largeOrg" | "student";
    redirectTo: string | null;
    signupReason: string | null;
    currentSoftware: string | null;
    templateCode: string | null;
    occupation: string | null;
    country: string | null;
    discovery: string | null;
    discoveryReferrer: string | null;
    timezoneOffset: number | null;
    externalCustomer: "esics" | "itidesignspec" | null;
}

interface OnboardingRelationships {
    session: Session | null;
}

class OnboardingModel extends Model {
    type = "onboardings";
    attributes = observable.object({
        email: undefined,
        firstName: undefined,
        lastName: undefined,
        password: undefined,
        phone: undefined,
        orgName: undefined,
        estimatedTeamSize: "solo",
        redirectTo: undefined,
        signupReason: undefined,
        currentSoftware: undefined,
        templateCode: undefined,
        occupation: undefined,
        country: undefined,
        discovery: undefined,
        discoveryReferrer: undefined,
        timezoneOffset: undefined,
        externalCustomer: undefined,
    } as any as OnboardingAttributes);

    relationships = observable.object(
        {
            session: undefined,
        } as any as OnboardingRelationships,
        {},
        { deep: false },
    );
}
export default OnboardingModel;
