import Collection from "../Collection";
import ProjectPreferredSection from "../models/ProjectPreferredSection";

class ProjectPreferredSections extends Collection<ProjectPreferredSection> {
    type = "projectPreferredSections";

    getAllBySharedTableCode(code: string) {
        return this.models.filter((model) => {
            return model.attributes.sharedTableCode === code;
        });
    }

    getBySharedTableCodeAndLabel(
        code: string,
        label: string,
    ): ProjectPreferredSection | undefined {
        return this.models.find((model) => {
            return (
                model.attributes.sharedTableCode === code &&
                model.attributes.label === label
            );
        });
    }
}
export default ProjectPreferredSections;
