let initialized = false;

const googleTagManager = {
    init() {
        if (!initialized) {
            (function (window: any, layer: "dataLayer", id: string) {
                window.layer = window.layer || [];
                window.layer.push({
                    "gtm.start": new Date().getTime(),
                    event: "gtm.js",
                });

                var first = document.getElementsByTagName("script")[0],
                    scriptEl = document.createElement("script"),
                    dataLayerQueryString =
                        layer != "dataLayer" ? "&l=" + layer : "";
                scriptEl.async = true;
                scriptEl.src =
                    "https://load.tr.clearcalcs.com/auxizcrm.js?st=" +
                    id +
                    dataLayerQueryString +
                    "";
                first.parentNode!.insertBefore(scriptEl, first);
            })(window, "dataLayer", "P7ZF7J7");
            initialized = true;
        }
    },

    get instance() {
        if (typeof window === "undefined") {
            // window doesn't exist - maybe we're running in a worker or nodejs. We don't support
            // using Google Tag Manager from anywhere but the main browser thread right now.
            return null;
        }

        return (window as any).dataLayer;
    },
};
export default googleTagManager;
