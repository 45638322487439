import math from "math";
import { MathNode } from "mathjs";
import parseWithNumberSupport from "math/parseWithNumberSupport";
import { Equation } from "data/models/SheetTemplateWidgetInterface";
import SheetShadowModel from "data/models/SheetShadow";
import makeReferenceTransformer from "./makeReferenceTransformer";

export default function conditionalEquationsToNode(
    equationAttribute: Equation,
    sheet: SheetShadowModel,
    ignoreParseErrors: boolean = false,
) {
    const equations = equationAttribute.slice().reverse();

    let fallbackCase;
    if (equations[0] && equations[0].condition === "@default") {
        const result = equations.shift()!.result;
        try {
            fallbackCase = parseWithNumberSupport(result);
        } catch (e) {
            if (ignoreParseErrors) {
                console.error(e);
                fallbackCase = new math.FunctionNode("throw", [
                    new math.ConstantNode(`Parse error: ${e}`),
                ]);
            } else {
                throw e;
            }
        }
    } else {
        fallbackCase = new math.FunctionNode("throw", [
            new math.ConstantNode("No matching equation condition"),
        ]);
    }

    const strictnessLevel =
        sheet.relationships.sheetTemplate!.attributes.strictnessLevel || 0;

    return equations
        .reduce((memo, equation) => {
            let conditionNode: MathNode;
            try {
                conditionNode = parseWithNumberSupport(equation.condition);
            } catch (e) {
                if (ignoreParseErrors) {
                    console.error(e);
                    conditionNode = new math.FunctionNode("throw", [
                        new math.ConstantNode(`Parse error: ${e}`),
                    ]);
                } else {
                    throw e;
                }
            }

            let resultNode: MathNode;
            try {
                resultNode = parseWithNumberSupport(equation.result);
            } catch (e) {
                if (ignoreParseErrors) {
                    console.error(e);
                    resultNode = new math.FunctionNode("throw", [
                        new math.ConstantNode(`Parse error: ${e}`),
                    ]);
                } else {
                    throw e;
                }
            }

            return new math.ConditionalNode(
                new math.FunctionNode("equal", [
                    strictnessLevel > 0
                        ? conditionNode
                        : new math.FunctionNode("try", [conditionNode]),
                    new math.ConstantNode(true),
                ]),
                resultNode,
                memo,
            );
        }, fallbackCase)
        .transform(
            makeReferenceTransformer(sheet.relationships.sheetTemplate!),
        );
}
