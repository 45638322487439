// Based on http://www.cespedes.org/blog/85/how-to-escape-latex-special-characters

const REPLACEMENTS = {
    "#": "\\#",
    $: "\\$",
    "%": "\\%",
    "&": "\\&",
    "\\": "\\textbackslash{}",
    "^": "\\textasciicircum{}",
    _: "\\_",
    "{": "\\{",
    "}": "\\}",
    "~": "\\textasciitilde{}",
};

export default function latexEscape(input: string) {
    return input.replace(/./g, (char) => REPLACEMENTS[char] || char);
}
