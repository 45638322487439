import { create, all, MathJsStatic, ObjectNode } from "mathjs";
import staticFunctions from "./staticFunctions";

const config = {
    // Predictable output type of functions. When true, output type depends
    // only on the input types, so sqrt(-4) is NaN instead of complex(2i).
    // We don't expect any complex results in our sheets, so this makes
    // everything much less surprising.
    //
    // You can still explicitly use complex numbers like this: sqrt(complex(-4))
    predictable: true,
};

const mathjs = create(
    {
        all,
    },
    config,
);

// Add our extensions to the default namespace. Use override, as there's now a definition of isNumber is mathjs that we need to override.
mathjs.import(staticFunctions(mathjs), { override: true });

const math = mathjs as ReturnType<typeof staticFunctions> & MathJsStatic;

// mathjs 7.0.0 includes a breaking changes that fixes multiplication of complex vectors
// See: https://github.com/josdejong/mathjs/issues/1761
// This broke multiplication of vectors of Units, as the conj function doesn't have an Unit implementation.
//
// Let's add one:
const conj = math.typed("conj", {
    Unit: function (x) {
        return x;
    },
});
math.import({ conj }); // This merges typed function definitions by default

// MathJS v11 modified the behaviour of sqrt, square etc.
// to not function on matrices or arrays.
// Since we have historical templates that rely on this, we want to
// maintain the old behaviour until we are confident older template versions are not in circulation.
// - steelBolt (v17 to v49) using square
// - steelBoltAnalysisASD (v1) using square
// - steelBoltAnalysisFree (v1 - v2) using square
// - steelBoltAS4100-2020 (v1 - v3) using square
// - timberBolt v1 to v35 using square
// - timberScrew versions 1 to 63 - using square & sqrt
// - timberNail v1 to v65 - using square & sqrt

// Copied from mathjs src/utils/collection.js
function deepMap(array, callback, skipZeros) {
    if (array && typeof array.map === "function") {
        // TODO: replace array.map with a for loop to improve performance
        return array.map(function (x) {
            return deepMap(x, callback, skipZeros);
        });
    } else {
        return callback(array);
    }
}

// MathJS v10 typed-functions for sqrt and square
// https://github.com/josdejong/mathjs/pull/2465
const sqrt = math.typed("sqrt", {
    "Array | Matrix": function (x) {
        console.warn(
            `Passing a matrix or an array to function sqrt is deprecated. Use map({matrix}, sqrt) instead.`,
        );
        // deep map collection, skip zeros since sqrt(0) = 0
        return deepMap(x, math.sqrt, true);
    },
});

const square = math.typed("square", {
    "Array | Matrix": function (x) {
        console.warn(
            `Passing a matrix or an array to function square is deprecated. Use map({matrix}, square) instead.`,
        );
        // deep map collection, skip zeros since square(0) = 0
        return deepMap(x, math.square, true);
    },
});

math.import({ sqrt, square }); // This merges typed function definitions by default

// Custom unit definitions
math.createUnit("plf", { definition: "1 lbf/ft" });
math.createUnit("klf", { definition: "1000 lbf/ft" });
math.createUnit("psf", { definition: "1 lbf/ft^2" });
math.createUnit("ksf", { definition: "1000 lbf/ft^2" });
math.createUnit("pcf", { definition: "1 lbf/ft^3" });
math.createUnit("kcf", { definition: "1000 lbf/ft^3" });
math.createUnit("pci", { definition: "1 lbf/in^3" });
math.createUnit("ksi", { definition: "1000 psi" });
math.createUnit("lb", { definition: "1 lbf" }, { override: true });
math.createUnit("lbs", { definition: "1 lbf" }, { override: true });
math.createUnit("pa", { definition: "1 Pa", prefixes: "short" });

// mathjs 4.0.0 changed the string comparison behaviour to compare strings by the numeric value
// they contain. See: https://github.com/josdejong/mathjs/issues/680. There were people fighting
// the good fight for the old behaviour, but there were defeated.
//
// There are new compareText and equalText functions for comparing strings instead, but we have
// templates that rely on the old behaviour.
//
// Extend the existing functions to support strings again. This isn't a deep fix
// (eg. identical Matrices of strings won't be equal), but it covers our use cases.
const equal = math.typed("equal", {
    "string, string": function (x, y) {
        return x === y;
    },
});
const unequal = math.typed("unequal", {
    "string, string": function (x, y) {
        return x !== y;
    },
});
math.import({ equal, unequal }); // This merges typed function definitions by default

// Custom Tex output for Unit values, as mathjs doesn't provide one.
function multiplyUnits(units) {
    return units
        .map(
            (u) =>
                `${u.prefix.name}${u.unit.name}${
                    Math.abs(u.power) > 1 ? `^${Math.abs(u.power)}` : ""
                }`,
        )
        .join(" \\cdot ");
}

// Temporarily cast math to any to avoid TypeScript errors
// math.Unit was only typed in v13.0.3
// see https://github.com/josdejong/mathjs/pull/3230
(math as any).Unit.prototype.toTex = function () {
    const fraction: string[] = [];
    const numerator = this.units.filter((u) => u.power > 0);
    if (numerator.length > 0) {
        fraction.push(multiplyUnits(numerator));
    } else {
        fraction.push("1");
    }
    const denominator = this.units.filter((u) => u.power < 0);
    if (denominator.length > 0) {
        fraction.push(multiplyUnits(denominator));
    }

    return `\\mathrm{${fraction.join(" / ")}}`;
};

// Override mathjs ObjectNode toTex method, which by default renders keys as maths (incl subscripts)
// Typically ObjectNodes are used for exporting to other sheets or to the solver
// and the keys should be displayed as text.
math.ObjectNode.prototype.toTex = function (options) {
    const entries: string[] = [];
    for (const [key, value] of Object.entries(
        // Typescript cannot derive which this is being referred to.
        (this as ObjectNode).properties,
    )) {
        entries.push(
            "\\mathbf{" +
                new math.ConstantNode(key).toTex() +
                ":} & " +
                value.toTex(options) +
                "\\\\",
        );
    }
    const tex =
        "\\left\\{\\begin{array}{ll}" +
        entries.join("\n") +
        "\\end{array}\\right\\}";
    return tex;
};

export default math;
