import { observable, computed, makeObservable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import {
    TableAttributes,
    TableDataColumn,
} from "./SheetTemplateWidgetInterface";

import latexEscape from "math/latexEscape";
import TableUtil from "sheet/components/TableUtil";

class TableSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    constructor(id, options) {
        super(id, options);
        makeObservable<TableSheetTemplateWidgetModel, "tableUtil">(this, {
            allColumnsComputed: computed,
            tableUtil: computed,
        });
    }
    attributes = observable.object({
        type: "table",
        label: undefined,
        symbol: undefined,
        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        referenceImage: undefined,
        visibleIf: undefined,
        showInSummary: undefined,
        showInSuperSummary: undefined,
        apiOutput: undefined,

        dataColumns: [],
        defaultValue: [],
        defaultValueMks: undefined,
        defaultValueFps: undefined,
        expand: undefined,
        export: undefined,
        tableLength: undefined,

        checks: [],
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,

        order: undefined,
    } as any as TableAttributes);

    isUserInput = true;

    get allColumnsComputed() {
        return new TableUtil(this.attributes).allColumnsComputed;
    }

    symbolForColumn(columnIndex: number) {
        const column = this.attributes.dataColumns[columnIndex];
        if (column.type === "linkRow") {
            return "";
        } else if (column.symbol) {
            return column.symbol;
        } else {
            return `\\text{${latexEscape(
                column.shortLabel || column.label || "",
            )}}`;
        }
    }

    private get tableUtil() {
        return new TableUtil(this.attributes);
    }

    cleanValue(
        value: (number | string | null)[][],
    ): (number | string | null)[][] {
        return this.tableUtil.cleanValue(value);
    }

    static hasValueColumnIndex(valueColumnIndex) {
        // Rails serializer only return valueColumnIndex === null if it has been explicitly unset (e.g. in builder)
        // This is in contrast to a sheetTemplateWidget where the key is always returned explicitly.
        // Check both for null & undefined (no key returned)
        return !!(valueColumnIndex !== null && valueColumnIndex !== undefined);
    }

    validateBuilder() {
        super.validateBuilder();

        if (this.attributes.expand && this.attributes.tableLength != null) {
            throw new Error(
                "expand cannot be true when tableLength is not null",
            );
        }
    }
}

export function unitOverrideForColumn(
    dataColumn: TableDataColumn,
    unitSystem: string | null,
) {
    if (dataColumn.type === "computed" || dataColumn.type === "input") {
        if (unitSystem === "MKS" && dataColumn.unitsMks) {
            return dataColumn.unitsMks;
        } else if (unitSystem === "FPS" && dataColumn.unitsFps) {
            return dataColumn.unitsFps;
        }
    }
    return null;
}

export default TableSheetTemplateWidgetModel;
