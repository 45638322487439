import abortableRequest from "./abortableRequest";
import { getClientConfig } from "clientConfig";

let currentSession;
let onLogout;
const apiRequest = function (
    method,
    url,
    JSONData,
    {
        filter = [],
        include = [],
        timeout,
        jsonReviver = undefined,
        responseType = "json",
    } = {},
) {
    let body;
    let queryData;
    if (method === "GET") {
        queryData = JSONData;
    } else {
        body = JSON.stringify({
            data: JSONData,
        });
    }

    const requestUrl = `${
        getClientConfig().baseUrl || ""
    }/api${url}${generateQueryString({
        filter,
        include,
        queryData,
    })}`;
    const headers = {
        "Content-Type":
            responseType === "document"
                ? "application/xml; charset=utf=8"
                : "application/vnd.api+json; charset=utf-8",
        "X-ClearCalcs-Version":
            process.env.NEXT_PUBLIC_ROLLBAR_CODE_VERSION || "dev",
    };

    if (currentSession && currentSession.id) {
        headers["X-CLEARCALCS-SESSION"] = currentSession.id;
    }
    const requestOptions = Object.assign(
        {},
        {
            method,
            body,
            headers,
            responseType,
            jsonReviver,
        },
    );

    if (timeout) {
        requestOptions.timeout = timeout;
    }

    const request = abortableRequest(requestUrl, requestOptions);
    const requestWith401Check = request.catch((response) => {
        if (response.meta._request.status === 401) {
            if (onLogout) {
                onLogout();

                // Return never-resolving promise - the logout handler is going to
                // reload the page.
                return new Promise((resolve, reject) => {});
            }
        } else {
            throw response;
        }
    });
    requestWith401Check.abort = request.abort;

    return requestWith401Check;
};

export const getRequest = apiRequest.bind(null, "GET");
export const postRequest = apiRequest.bind(null, "POST");
export const putRequest = apiRequest.bind(null, "PUT");
export const deleteRequest = apiRequest.bind(null, "DELETE");

export function generateQueryString({
    filter = [],
    include = [],
    queryData,
} = {}) {
    let queryString = "";

    if (filter.length > 0) {
        const filterTypeCount = {};
        filter.forEach(({ type }) => {
            if (filterTypeCount[type]) {
                filterTypeCount[type]++;
            } else {
                filterTypeCount[type] = 1;
            }
        });

        queryString += filter
            .map(({ type, value }, idx) => {
                return `filter${
                    filterTypeCount[type] > 1 ? "[]" : ""
                }[${encodeURIComponent(type)}]=${encodeURIComponent(value)}`;
            })
            .join("&");
    }

    if (include.length > 0) {
        if (queryString) {
            queryString += "&";
        }
        queryString += `include=${encodeURIComponent(include.join(","))}`;
    }

    if (queryData) {
        for (var key of Object.keys(queryData)) {
            if (queryString) {
                queryString += "&";
            }
            queryString += `${encodeURIComponent(key)}=${encodeURIComponent(
                queryData[key],
            )}`;
        }
    }

    if (!!queryString) {
        queryString = `?${queryString}`;
    }

    return queryString;
}

export function registerCurrentSession(sessionModel) {
    currentSession = sessionModel;
}

export function getCurrentSession() {
    return currentSession;
}

export function registerLogoutHandler(handler) {
    onLogout = handler;
}
