import { computed, makeObservable, observable, toJS } from "mobx";
import Model from "data/Model";

import math from "math";
import { createAtom } from "mobx";

import Sheet from "./Sheet";
import Sheets from "../collections/Sheets";
import SheetTemplateWidget from "./SheetTemplateWidget";
import { PendingDataError, SolveError } from "errors";
import { WorkerInterface } from "math/WorkerInterface";
import LookupSheetTemplateWidgetModel, {
    formatLabel,
} from "data/models/LookupSheetTemplateWidget";
import TableSheetTemplateWidgetModel from "./TableSheetTemplateWidget";
import { RenderResponse } from "data/models/CustomDiagramSheetTemplateWidget";
import { LookupDataTable } from "./SheetTemplateWidgetInterface";
let nextClientId = 0;

interface SheetWidgetAttributes {
    value: any;
    referenceId: string | null;
    clientState: any;
}

interface SheetWidgetRelationships {
    sheet: Sheet | null;
    linkedSheets: Sheets | null;
}

class SheetWidgetModel extends Model {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            _visible: observable,
            _currentValue: observable,
            _currentValueTyped: observable,
            _currentValueIgnoreErrors: observable,
            _currentCheckValue: observable,
            _currentCheckFailReasons: observable,
            _currentEquationIndex: observable,
            currentError: observable,
            _checkDebugResults: observable,
            _currentCustomDiagramRenderParams: observable,
            _currentCustomDiagramSource: observable,
            _currentDataTable: observable,
            sheetTemplateWidget: computed,
            visible: computed,
            currentValue: computed,
            currentValueTyped: computed,
            currentValueIgnoreErrors: computed,
            currentCheckValue: computed,
            currentCheckFailReasons: computed,
            checkDebugResults: computed,
            currentCustomDiagramRenderParams: computed,
            currentCustomDiagramSource: computed,
            currentEquation: computed,
            currentDataTable: computed,
            dataMap: computed,
            uiOptions: computed,
        });
    }
    type = "sheetWidgets";
    attributes = observable.object({
        value: undefined,
        referenceId: undefined,
        clientState: undefined,
    } as any) as SheetWidgetAttributes;

    relationships = observable.object(
        {
            sheet: undefined,
            linkedSheets: undefined,
        } as any as SheetWidgetRelationships,
        {},
        { deep: false },
    );

    worker: WorkerInterface | null = null;

    clientId = `sheetWidget-${nextClientId++}`;

    // Request worker to start sending "sheetWidgetResult" messages
    // Use: Call reportObserved() on this atom for any computed properties that
    // depend on the "sheetWidgetResult" message.
    // An atom is used instead of a reaction because we only want to
    // react to observable being observed by others, and not trigger them to be observed.
    sheetWidgetResultAtom = createAtom(
        "sheetWidgetResult",
        () => {
            if (this.relationships.sheet!.worker) {
                this.relationships.sheet!.worker!.reportSheetWidgetObserved(
                    this.sheetTemplateWidget.attributes.referenceId,
                );
            } else {
                // The SheetController is unmounting and has already disposed the worker.
            }
        },
        () => {
            // Not needed. We just terminate the entire worker when the sheet is closed.
        },
    );

    // Request worker to start sending "debug" messages
    debugAtom = createAtom(
        "debug",
        () => {
            if (this.relationships.sheet!.worker) {
                this.relationships.sheet!.worker!.reportDebugObserved(
                    this.sheetTemplateWidget.attributes.referenceId,
                );
            } else {
                // The SheetController is unmounting and has already disposed the worker.
            }
        },
        () => {
            // Not needed. We just terminate the entire worker when the sheet is closed.
        },
    );

    _visible: boolean | null = null;
    _currentValue: any = null;
    _currentValueTyped: any = null;
    _currentValueIgnoreErrors: any = null;
    _currentCheckValue: boolean | number | null = null;
    _currentCheckFailReasons: string[] = [];
    _currentEquationIndex: number | null = null;
    currentError: PendingDataError | SolveError | null = null;
    _checkDebugResults = [];
    // Evaluated mathjs object should return an object of diagram parameter values, however this cannot be guaranteed, so use any
    _currentCustomDiagramRenderParams: any = null;
    _currentCustomDiagramSource: RenderResponse | null = null;
    _currentDataTable: LookupDataTable | null = null;

    clearCurrentState() {
        this._visible = null;
        this._currentValue = null;
        this._currentValueTyped = null;
        this._currentValueIgnoreErrors = null;
        this._currentCheckValue = null;
        this._currentCheckFailReasons = [];
        this._currentEquationIndex = null;
        this.currentError = null;
        this._checkDebugResults = [];
        this._currentCustomDiagramRenderParams = null;
        this._currentCustomDiagramSource = null;
        this._currentDataTable = null;
    }

    get sheetTemplateWidget(): SheetTemplateWidget {
        return this.relationships.sheet!.sheetTemplateWidgetsByReferenceId.get(
            this.attributes.referenceId!,
        ) as SheetTemplateWidget;
    }
    get visible() {
        this.sheetWidgetResultAtom.reportObserved();
        if (this._visible === null) {
            // The worker hasn't told us if this widget is visible yet.
            // If the widget has a visibleIf assume that it will be hidden, otherwise make it visible.
            return !this.sheetTemplateWidget.attributes.visibleIf;
        } else {
            return this._visible;
        }
    }
    get currentValue() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentValue;
    }
    get currentValueTyped() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentValueTyped;
    }
    get currentValueIgnoreErrors() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentValueIgnoreErrors;
    }
    get currentCheckValue() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentCheckValue;
    }
    get currentCheckFailReasons() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentCheckFailReasons;
    }
    get checkDebugResults() {
        this.debugAtom.reportObserved();
        return this._checkDebugResults;
    }

    // computed/diagram
    get currentEquation() {
        this.sheetWidgetResultAtom.reportObserved();
        return this.sheetTemplateWidget.attributes.equation[
            this._currentEquationIndex as number
        ];
    }

    get currentCustomDiagramRenderParams() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentCustomDiagramRenderParams;
    }

    get currentCustomDiagramSource() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentCustomDiagramSource;
    }

    get currentDataTable() {
        this.sheetWidgetResultAtom.reportObserved();
        return this._currentDataTable;
    }

    get dataMap() {
        if (
            this.sheetTemplateWidget instanceof LookupSheetTemplateWidgetModel
        ) {
            // Dynamic lookup case with valid data table.
            // A special case is where both currentError and currentDataTable are defined.
            // This may occur if the error is with currentValue, where a user has a stale uiOption
            // and needs to be able to recover by selecting a new value from the dataMap
            if (this.currentDataTable) {
                return new Map(
                    this.currentDataTable.map((row) => [
                        (
                            row[
                                // value in column valueColumnIndex in the dataTable are always defined
                                // since the worker has already validated the dataMap shape.
                                this.sheetTemplateWidget.attributes
                                    .valueColumnIndex!
                            ] as string | number
                        ).toString(),
                        row,
                    ]),
                );
            } else if (
                // Static Lookup case
                // Verify we don't have an equation, as we may still be waiting for the solver to return
                // or the data table is blank because the worker errored.
                !this.sheetTemplateWidget.hasEquation
            ) {
                return this.sheetTemplateWidget.dataMap;
            }
        }
    }

    get uiOptions(): { label: string; value: string }[] {
        const stw = this.sheetTemplateWidget;

        if (!(stw instanceof LookupSheetTemplateWidgetModel)) {
            throw new Error("Cannot get uiOptions for non-lookup widget");
        }

        if (stw.attributes.equation?.length > 0) {
            // Dynamic Lookup
            if (this.dataMap) {
                return [...this.dataMap.keys()].map((idx) => {
                    return {
                        label: formatLabel(
                            stw.lookupValueInColumn(idx, 0, this.dataMap),
                        ),
                        value: idx.toString(), // TODO: Confirm why we need to convert to string here, but not originally
                    };
                });
            } else {
                // This will only get hit on the first time the worker reports currentDataTable
                // Render an empty list in the meantime
                return [];
            }
        } else {
            // Static Lookup
            return stw.uiOptions;
        }
    }

    get apiValue() {
        const type = this.sheetTemplateWidget.attributes.type;

        if (type === "table") {
            const tableValues: any[] = [];
            const dataColumns = this.sheetTemplateWidget.attributes.dataColumns;

            this.attributes.value.forEach((row) => {
                const rowValues = {};
                row.forEach((value, columnIndex) => {
                    if (!dataColumns[columnIndex].referenceId) {
                        return;
                    }

                    if (
                        dataColumns[columnIndex].type === "lookup" &&
                        !TableSheetTemplateWidgetModel.hasValueColumnIndex(
                            dataColumns[columnIndex].valueColumnIndex,
                        )
                    ) {
                        rowValues[dataColumns[columnIndex].referenceId] = toJS(
                            dataColumns[columnIndex].dataTable[value][0],
                        );
                    } else if (dataColumns[columnIndex].type === "linkRow") {
                        // TO DO: this requires more work since we need to generate the associated sheet
                        // and get the sheetId. Just going to leave this blank for now
                    } else if (dataColumns[columnIndex].type === "object") {
                        const object = {};

                        Object.keys(value).forEach((key) => {
                            const rowObject = {};
                            dataColumns[columnIndex].dataColumns.forEach(
                                (dataColumn, index) => {
                                    if (index === 0) {
                                        return;
                                    }

                                    if (
                                        dataColumn.type === "lookup" &&
                                        !TableSheetTemplateWidgetModel.hasValueColumnIndex(
                                            dataColumns.valueColumnIndex,
                                        )
                                    ) {
                                        rowObject[dataColumn.referenceId] =
                                            toJS(
                                                dataColumn.dataTable[
                                                    value[key][index - 1]
                                                ][0],
                                            );
                                    } else if (dataColumn.type === "lookup") {
                                        rowObject[dataColumn.referenceId] =
                                            toJS(
                                                dataColumn.dataTable[
                                                    value[key][index - 1]
                                                ][dataColumn.valueColumnIndex],
                                            );
                                    } else {
                                        rowObject[dataColumn.referenceId] =
                                            toJS(value[key][index - 1]);
                                    }
                                },
                            );
                            object[key] = rowObject;
                        });

                        rowValues[dataColumns[columnIndex].referenceId] =
                            object;
                    } else {
                        rowValues[dataColumns[columnIndex].referenceId] =
                            toJS(value);
                    }
                });
                tableValues.push(rowValues);
            });

            return tableValues;
        } else if (
            type === "lookup" &&
            this.sheetTemplateWidget instanceof LookupSheetTemplateWidgetModel
        ) {
            const columnIndex = this.sheetTemplateWidget.attributes
                .valueColumnIndex
                ? this.sheetTemplateWidget.attributes.valueColumnIndex
                : 0;

            const value = this.sheetTemplateWidget.lookupValueInColumn(
                this.attributes.value,
                columnIndex,
            );
            return value;
        } else {
            return toJS(this.attributes.value);
        }
    }
}

export default SheetWidgetModel;
