import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { ImageAttributes } from "./SheetTemplateWidgetInterface";

class ImageSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "image",
        label: undefined,
        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        equation: [],
        image: {},
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        visibleIf: undefined,
        showInSuperSummary: undefined,
        fullWidth: undefined,

        order: undefined,
    } as any as ImageAttributes);
}

export default ImageSheetTemplateWidgetModel;
