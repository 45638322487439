import { observable } from "mobx";
import Model from "data/Model";

interface CategoryAttributes {
    name: string | null;
}

class Category extends Model {
    type = "categories";
    attributes = observable.object({
        name: undefined,
    } as any as CategoryAttributes);

    relationships = null;
}
export default Category;
