import { action } from "mobx";
import Collection from "../Collection";
import Model from "../Model";

export default function responseMapper(dataObjectToMapTo) {
    const universe = dataObjectToMapTo.universe;

    if (!universe) {
        throw new Error("dataObjectToMapTo does not belong to a universe");
    }

    function mapResourceToModel(resource) {
        const model = universe.getModel(resource.type, resource.id, resource);
        activeModelSerializerWorkaround(resource.attributes);
        model._setAttributes(resource.attributes);
        model._setRelationships(mapRelationships(resource.relationships || {}));
        return model;
    }

    function mapRelationships(relationships) {
        const parsedRelationships = {};

        Object.keys(relationships).forEach((relName) => {
            if (relationships[relName].data === null) {
                parsedRelationships[relName] = null;
            } else if (!relationships[relName].data) {
                return;
            } else if (Array.isArray(relationships[relName].data)) {
                const collectionModels = relationships[relName].data.map(
                    (modelData) => mapResourceToModel(modelData),
                );
                const type =
                    (collectionModels[0] && collectionModels[0].type) ||
                    relName;
                const collection = universe.createCollection(type);
                collection._setModels(collectionModels);

                parsedRelationships[relName] = collection;
            } else {
                parsedRelationships[relName] = mapResourceToModel(
                    relationships[relName].data,
                );
            }
        });

        return parsedRelationships;
    }

    return action(function mapResponse(response, options = {}) {
        if (!response.data) {
            throw new Error("response didn't contain data element");
        }

        if (!Array.isArray(response.data) && !dataObjectToMapTo.id) {
            // This is a newly created model that was just returned from the API - add it to the universe.
            // Do this before processing the includes, as the includes may include a reference to this model.
            dataObjectToMapTo.id = response.data.id;
            universe.addModel(dataObjectToMapTo);
        }

        if (response.included) {
            // Process any included sheetTemplateWidgets first, as we can't instantiate them without their attribute data.
            response.included
                .filter((resource) => resource.type === "sheetTemplateWidgets")
                .forEach((resource) => mapResourceToModel(resource));
            response.included
                .filter((resource) => resource.type !== "sheetTemplateWidgets")
                .forEach((resource) => mapResourceToModel(resource));
        }

        if (Array.isArray(response.data)) {
            if (!(dataObjectToMapTo instanceof Collection)) {
                throw new Error(
                    "response expecting top level to be a Collection",
                );
            }

            const collectionModels = response.data.map((modelData) =>
                mapResourceToModel(modelData),
            );
            dataObjectToMapTo._setModels(collectionModels, {
                append: options.append,
            });
        } else {
            if (!(dataObjectToMapTo instanceof Model)) {
                throw new Error("response expecting top level to be a Model");
            }

            mapResourceToModel(response.data);
        }

        if (response.links) {
            dataObjectToMapTo.links = response.links;
        }

        return dataObjectToMapTo;
    });
}

const RAW_KEY_MATCHER = /^(.*)RawJson$/;
function activeModelSerializerWorkaround(attributes) {
    if (!attributes) return;
    Object.entries(attributes).forEach(([key, value]) => {
        const match = key.match(RAW_KEY_MATCHER);
        if (match) {
            // The server sent us a double JSON encoded value - decode it now and store it without the "RawJson" suffix.
            attributes[match[1]] = JSON.parse(value);
            // Delete the raw value
            delete attributes[key];
        }
    });
}
