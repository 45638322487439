import { observable, computed, reaction, makeObservable } from "mobx";
import Model from "../Model";
import Sheet from "./Sheet";
import simplifyMathjsObjects from "math/simplifyMathjsObjects";
import math from "math";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import track from "track";

interface ResultSetAttributes {
    inputHash2: string | null;
    /**
     * @deprecated Use the summaryCheckValue property on the model instead.
     */
    summaryCheckValue: number | boolean | null;
    summaryCheckError: boolean | null;
    summaryCheckFailReasons: string[] | null;
    summaryCheckReferenceId: string | null;
    exports: ResultSetExport[] | null;
    apiOutput: any | null;
    hasPassed: boolean | null;
    createdAt: string | null;
    updatedAt: string | null;
}

export interface ResultSetExport {
    referenceId: string;
    value: any | null;
    error: boolean | null;
    visible: boolean | null;
    sharedTableId?: string;
}

interface ResultSetRelationships {
    sheet: Sheet;
    summaryCheckSheetTemplateWidget: SheetTemplateWidgetModel | null;
}

export default class ResultSet extends Model {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            summaryCheckValue: computed,
            summaryCheckPassed: computed,
            exportsUntyped: computed,
            exportsTyped: computed,
        });
    }
    type = "resultSets";
    attributes = observable.object({
        inputHash2: undefined,
        summaryCheckValue: undefined,
        summaryCheckError: undefined,
        summaryCheckFailReasons: undefined,
        summaryCheckReferenceId: undefined,
        exports: undefined,
        apiOutput: undefined,
        hasPassed: undefined,
        createdAt: undefined,
        updatedAt: undefined,
    } as any as ResultSetAttributes);

    relationships = observable.object(
        {
            sheet: undefined,
            summaryCheckSheetTemplateWidget: undefined,
        } as any as ResultSetRelationships,
        {},
        { deep: false },
    );

    get summaryCheckValue() {
        // Run the value through the MathJS reviver to deserialize Infinity and NaN values
        if (this.attributes.summaryCheckValue === undefined) {
            return this.attributes.summaryCheckValue;
        }
        return JSON.parse(
            JSON.stringify(this.attributes.summaryCheckValue),
            math.reviver,
        );
    }

    get summaryCheckPassed() {
        return (
            !this.attributes.summaryCheckError &&
            (this.summaryCheckValue === true ||
                (typeof this.summaryCheckValue === "number" &&
                    this.summaryCheckValue <= 1 &&
                    this.summaryCheckValue >= 0))
        );
    }

    enablePassFailTracking = () =>
        reaction(
            () => this.summaryCheckPassed,
            () =>
                track("Utilisation Pass/Fail", {
                    "Template code":
                        this.relationships.sheet.relationships.sheetTemplate
                            ?.attributes.code,
                    "Utilisation PassFail": this.summaryCheckPassed
                        ? "pass"
                        : "fail",
                }),
        );

    get exportsUntyped() {
        return JSON.parse(
            JSON.stringify(this.attributes.exports),
            simplifyMathjsObjects,
        ) as ResultSetExport[];
    }

    get exportsTyped() {
        return JSON.parse(
            JSON.stringify(this.attributes.exports),
            math.reviver,
        ) as ResultSetExport[];
    }
}
