export default function simplifyMathjsObjects(key, value) {
    if (
        value &&
        value.mathjs === "number" &&
        (value.value === "Infinity" ||
            value.value === "NaN" ||
            value.value === "-Infinity")
    ) {
        return null;
    }
    if (value && value.mathjs === "DenseMatrix") {
        return value.data;
    } else if (value && value.mathjs === "Unit") {
        return value.value;
    } else {
        return value;
    }
}
