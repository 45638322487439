import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { MapAttributes } from "./SheetTemplateWidgetInterface";

class MapSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "map",
        label: undefined,
        symbol: undefined,
        equation: [],
        referenceId: undefined,
        authorNotes: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        visibleIf: undefined,

        order: undefined,
    } as any as MapAttributes);
}

export default MapSheetTemplateWidgetModel;
