import Collection from "../Collection";
import Invoice from "../models/Invoice";

class Invoices extends Collection<Invoice> {
    type = "invoices";
    organisationId = null;

    get url() {
        return `/organisations/${this.organisationId}/invoices`;
    }
}
export default Invoices;
