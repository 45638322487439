import { observable, when, computed, makeObservable } from "mobx";
import { toStream, fromStream } from "mobx-utils";
import { from, interval, of } from "rxjs";
import { debounce } from "rxjs/operators";
import track from "track";

import Model from "data/Model";
import parseWithNumberSupport from "math/parseWithNumberSupport";
import equationToTexFactory from "math/equationToTexFactory";

import deepClone from "framework/utils/deepClone";

import SheetWidgetModel from "./SheetWidget";

import Project from "./Project";
import SheetTemplate from "./SheetTemplate";
import SheetWidget from "./SheetWidget";
import SheetWidgets from "../collections/SheetWidgets";
import User from "./User";
import Members from "../collections/Members";
import Sheets from "../collections/Sheets";
import Preset from "./Preset";
import Import from "./Import";
import ResultSet from "./ResultSet";
import SheetGroup from "./SheetGroup";
import SheetTemplateWidget from "./SheetTemplateWidget";
import { WorkerInterface } from "math/WorkerInterface";
import makeReferenceTransformer from "math/makeReferenceTransformer";
import editableModelProxy, {
    EditableModel,
} from "data/utils/editableModelProxy";
import { getClientConfig } from "clientConfig";
import Upload from "./Upload";

interface SheetAttributes {
    name: string | null;
    shortName: string | null;
    inputHash2: string | null;
    hasDependants: boolean | null;
    readOnly: boolean | null;
    projectDefaults: boolean | null;
    presetCode: string | null;
    dxfId: string | null;
    includeInPrint: boolean | null;
    unitSystem: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    subsheetReferenceId: string | null;
    subsheetId: string | null;
}

interface SheetRelationships {
    project: Project | null;
    sheetTemplate: SheetTemplate | null;
    resultSet: ResultSet | null;
    sheetWidgets: SheetWidgets | null;
    creator: User | null;
    members: Members | null;
    downstreamSheets: Sheets | null;
    import: Import | null;
    upgradeFrom: SheetModel | null;
    sheetGroup: SheetGroup | null;
    subsheets: Sheets | null;
    upload: Upload | null;
}

class SheetModel extends Model {
    type = "sheets";
    attributes = observable.object({
        name: undefined,
        shortName: undefined,
        inputHash2: undefined,
        hasDependants: undefined,
        readOnly: undefined,
        projectDefaults: undefined,
        presetCode: undefined,
        dxfId: undefined,
        includeInPrint: undefined,
        unitSystem: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        subsheetReferenceId: undefined,
        subsheetId: undefined,
    } as any) as SheetAttributes;

    relationships = observable.object(
        {
            project: undefined,
            sheetTemplate: undefined,
            resultSet: undefined,
            sheetWidgets: undefined,
            creator: undefined,
            members: undefined,
            downstreamSheets: undefined,
            import: undefined,
            upgradeFrom: undefined,
            sheetGroup: undefined,
            subsheets: undefined,
            upload: undefined,
        } as any as SheetRelationships,
        {},
        { deep: false },
    );

    worker: WorkerInterface | null = null;
    _sheetWidgets: SheetWidgetModel[] | null = null;

    computing: boolean = true;
    warnings: string[] = [];

    // Any errors, even if scoped to a particular widget
    get currentError() {
        return this.sheetWidgets.find((sw) => sw.currentError)?.currentError;
    }

    // Top-level sheet error
    get failedSheetWidget() {
        // this.computing is referenced, as we have to attach to some observable that will trigger when results change on the sheet
        // Although sw.currentError is an observable, it's value will not change if it is already set to an error
        // and you navigate away from the sheet and back again.
        // This may cause some unnecessary runs of this function, but it's better than not notifying the customer why their calculator is failing
        // in the form of solver error messages etc.
        this.computing;
        return this.sheetWidgets.find(
            // Ignore errors that are scoped to a particular widget - they will be displayed on that widget
            // instead.
            (sw) =>
                sw.currentError &&
                (!sw.currentError.data.referenceId ||
                    sw.currentError.data.showInHeader),
        );
    }

    get failedError() {
        if (this.failedSheetWidget) {
            return this.failedSheetWidget.currentError;
        } else {
            return null;
        }
    }

    get failedMessage() {
        return this.failedError && this.failedError.message;
    }

    get sheetTemplateWidgetsByReferenceId(): Map<string, SheetTemplateWidget> {
        return this.relationships.sheetTemplate!
            .sheetTemplateWidgetsByReferenceId;
    }

    get sheetWidgetsByReferenceId() {
        return new Map(
            this.sheetWidgets.map((sheetWidget) => [
                sheetWidget.attributes.referenceId,
                sheetWidget,
            ]),
        );
    }

    get computingDebounced() {
        return this._computingDebounced.current;
    }

    _computingDebounced;

    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            worker: observable.ref,
            computing: observable,
            warnings: observable,
            currentError: computed,
            failedSheetWidget: computed,
            failedError: computed,
            failedMessage: computed,
            sheetWidgetsByReferenceId: computed,
            computingDebounced: computed,
            preset: computed,
        });

        // Debounced computing flag for UI.

        // Delays "leading edge" of computing flag by 200ms, so the UI doesn't flash
        // for fast calculations. There's no delay on the "trailing edge".
        this._computingDebounced = fromStream(
            from(toStream(() => this.computing, true) as any).pipe(
                debounce((computing) => (computing ? interval(200) : of(null))),
            ) as any,
        );
    }

    // Beware - calling this generates state that persists in the instance of
    // the model.
    //
    // The unsaved SheetWidgets need to live somewhere, and the Store can't currently
    // handle models without ids.
    //
    // If you call this before the SheetTemplateWidgets have been fetched, it
    // will always be empty.
    get sheetWidgets(): SheetWidgetModel[] {
        if (!this._sheetWidgets) {
            const sheetTemplateWidgets =
                this.relationships.sheetTemplate!.relationships
                    .sheetTemplateWidgets;

            this._sheetWidgets = sheetTemplateWidgets.models.map(
                (widgetModel) => {
                    const referenceId = widgetModel.attributes.referenceId;
                    const sheetWidgetsCollection =
                        this.relationships.sheetWidgets;
                    let sheetWidget =
                        sheetWidgetsCollection &&
                        sheetWidgetsCollection.getByReferenceId &&
                        sheetWidgetsCollection.getByReferenceId(referenceId);

                    if (!sheetWidget) {
                        sheetWidget = this.universe.createModel("sheetWidgets");
                        sheetWidget!._setRelationships({
                            sheet: this,
                            linkedSheets:
                                this.universe.createCollection("linkedSheets"),
                        });

                        sheetWidget!._setAttributes({
                            referenceId,
                            value: widgetModel.defaultValue({
                                unitSystem: this.attributes.unitSystem,
                                preset: this.preset,
                            }),
                        });
                    }

                    return sheetWidget! as SheetWidget;
                },
            );
        }
        return this._sheetWidgets!;
    }

    // Call this after swapping templates, so that any untouched SheetWidgets
    // are re-initialized with the new template's defaultValues.
    resetSheetWidgetCache() {
        this._sheetWidgets = null;
    }

    // used to tell if at least one of the inputs (i.e sheetWidgets) have been changed
    // from the defaultValues
    isSheetEdited(): boolean {
        const sheetWidgets = this.sheetWidgets as SheetWidgetModel[];
        return sheetWidgets.some((sw) => sw.id);
    }
    get preset(): Preset | undefined {
        return this.relationships.sheetTemplate!.relationships.presets!.models.find(
            (p) => p.attributes.code === this.attributes.presetCode,
        );
    }

    get duplicateActionUrl() {
        return `${this.url}/actions/duplicate`;
    }

    equationAsTex(
        equation: string,
        renderRemote: boolean = true,
        tableSheetTemplateWidget?,
        LDFlagEnableDebugHyperlink?: boolean,
    ) {
        const equationToTexHandler = equationToTexFactory(
            this.relationships.sheetTemplate!,
            renderRemote,
            tableSheetTemplateWidget,
            LDFlagEnableDebugHyperlink,
        );

        try {
            return parseWithNumberSupport(equation)
                .transform(
                    makeReferenceTransformer(this.relationships.sheetTemplate!),
                )
                .toTex({ parenthesis: "auto", handler: equationToTexHandler });
        } catch (e) {
            return "";
        }
    }

    waitForCompute() {
        return new Promise<void>((resolve) => {
            when(() => !this.computing, resolve);
        });
    }

    nextName(sheetCollection) {
        const numericSuffix = /(.*?)([0-9]+)$/;
        const matches = this.attributes.name!.match(numericSuffix);
        if (matches) {
            const n = parseInt(matches[2], 10);
            const newName = `${matches[1]}${n + 1}`;

            const existingNames = sheetCollection.models.map(
                (m) => m.attributes.name,
            );
            if (!existingNames.includes(newName)) {
                return newName;
            }
        }

        return `${this.attributes.name} copy`;
    }

    getEditableSheetWidgets(): EditableSheetWidgets {
        const editableSheetWidgets = new Map<
            SheetWidgetModel,
            EditableModel<SheetWidgetModel>
        >();
        this.sheetWidgets.forEach((sheetWidget) => {
            editableSheetWidgets.set(
                sheetWidget,
                editableModelProxy(sheetWidget),
            );
        });
        return editableSheetWidgets;
    }

    async getApiJson() {
        const code = this.relationships.sheetTemplate?.attributes.code;

        if (!code) {
            return;
        }

        // Early exit here because if you call sheetWidgets this before the
        // SheetTemplateWidgets have been fetched, it will always be empty.
        if (
            !this.relationships.sheetTemplate?.relationships
                .sheetTemplateWidgets
        ) {
            return;
        }

        const schemaVersion =
            this.relationships.sheetTemplate?.attributes.schemaVersion;
        const buildingStandard =
            this.relationships.project?.attributes.buildingStandard;
        const apiInputKeys = new Set<string>();
        const apiInputsFilterSet = new Set(["$module", "$id", "$name"]);

        const requestSchema = async () => {
            const response = await fetch(
                `${
                    getClientConfig().baseUrl || ""
                }/api/schemas/modules/${buildingStandard}/${code}/v${schemaVersion}`,
            );

            if (!response.ok) {
                throw Error(response.statusText);
            }

            const responseJson = await response.json();

            if (responseJson.errorMessage) {
                throw responseJson.errorMessage;
            }
            Object.keys(responseJson.definitions.inputs.properties).forEach(
                (key) => {
                    if (!apiInputsFilterSet.has(key)) {
                        apiInputKeys.add(key);
                    }
                },
            );
        };

        await requestSchema();

        const apiInputs = {};

        this.sheetWidgets.forEach((sheetWidget) => {
            // if sheetWidget isn't visible, it's not part of the current sheet and thus not necessary
            // in the api call
            if (!sheetWidget.visible) {
                return;
            }

            if (
                !sheetWidget.attributes.referenceId ||
                !apiInputKeys.has(sheetWidget.attributes.referenceId)
            ) {
                return;
            }

            apiInputs[sheetWidget.attributes.referenceId] =
                sheetWidget.apiValue;
        });

        if (Object.keys(apiInputs).length > 0) {
            apiInputs["$module"] =
                `https://app.clearcalcs.com/api/schemas/modules/${buildingStandard}/${code}/v${schemaVersion}.json`;
            apiInputs["$id"] = this.attributes.name;
            apiInputs["$name"] = this.attributes.name;

            if (this.preset) {
                apiInputs["$preset"] = this.preset.attributes.code;
            }
        }
        return apiInputs;
    }
}
export default SheetModel;

export const SHEET_INCLUDES = [
    "sheetWidgets",
    "sheetWidgets.linkedSheets",
    "resultSet",
    "creator",
    "sheetGroup",
    "project",
    "project.projectDefaultsSheet",
    "project.projectDefaultsSheet.sheetTemplate",
    "project.projectDefaultsSheet.resultSet",
];

export const SHEET_TEMPLATE_INCLUDES = [
    "presets",
    "categories",
    "sheetTemplateWidgets",
    "sheetTemplateWidgets.sharedTable",
    "sheetTemplateWidgets.upload",
    "sheetTemplateWidgets.uploadInteractive",
];

export type EditableSheetWidgets = Map<
    SheetWidgetModel,
    EditableModel<SheetWidgetModel>
>;
