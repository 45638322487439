import { makeObservable, observable, computed } from "mobx";

import Model from "data/Model";
import Sheet from "./Sheet";
import User from "./User";
import Organisation from "./Organisation";
import ProjectPreferredSections from "../collections/ProjectPreferredSections";
import Sheets from "../collections/Sheets";
import Collection from "../Collection";
import ProjectArchiveExport from "./ProjectArchiveExport";
import SheetGroups from "../collections/SheetGroups";
import * as api from "data/utils/objectCrud";

interface ProjectAttributes {
    name: string | null;
    clientName: string | null;
    projectNumber: string | null;
    projectDate: string | null;
    address: string | null;
    longitude: number | null;
    latitude: number | null;
    country: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    epoch: number | null;
    buildingStandard: string | null;
    unitSystem: string | null;
    solveState: SolveState | null;
    solveProgressPercent: number | null;
    archiveState: ("available" | "processing" | "archived") | null;
    archivedAt: string | null;
    upgradedAt: string | null;
    duplicateState: "queued" | "running" | "finished" | "error" | null;
    duplicateProgressPercent: number | null;
    printMemberSchedule: boolean | null;
    printMode: string | null;
    paperSize: string | null;

    // Embedded Fields
    embeddedAuthorName: string | null;
    embeddedAuthorEmail: string | null;
    embeddedOrganisationName: string | null;

    mafiRolloutProject: string | null;
    mafiOperator: string | null;
}

export type SolveState = "queued" | "running" | "finished" | "error";

interface ProjectRelationships {
    organisation: Organisation | null;
    creator: User | null;
    sheets: Sheets | null;
    projectDefaultsSheet: Sheet | null;
    projectPreferredSections: ProjectPreferredSections | null;
    projectArchiveExports: Collection<ProjectArchiveExport> | null;
    upgradeFrom: ProjectModel | null;
    sheetGroups: SheetGroups | null;
}

const DUPLICATE_POLL_PERIOD = 2000; //ms

class ProjectModel extends Model {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            sheetIdsSet: computed({ equals: setComparer }),
        });
    }
    type = "projects";
    attributes = observable.object({
        name: undefined,
        clientName: undefined,
        projectNumber: undefined,
        projectDate: undefined,
        address: undefined,
        longitude: undefined,
        latitude: undefined,
        country: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        epoch: undefined,
        buildingStandard: undefined,
        unitSystem: undefined,
        solveState: undefined,
        solveProgressPercent: undefined,
        archiveState: undefined,
        duplicateState: undefined,
        duplicateProgressPercent: undefined,
        archivedAt: undefined,
        upgradedAt: undefined,
        printMemberSchedule: undefined,
        printMode: undefined,
        paperSize: undefined,

        embeddedAuthorName: undefined,
        embeddedAuthorEmail: undefined,
        embeddedOrganisationName: undefined,

        mafiRolloutProject: undefined,
        mafiOperator: undefined,
    } as any as ProjectAttributes);

    relationships = observable.object(
        {
            organisation: undefined,
            creator: undefined,
            sheets: undefined,
            projectDefaultsSheet: undefined,
            projectPreferredSections: undefined,
            projectArchiveExports: undefined,
            upgradeFrom: undefined,
            sheetGroups: undefined,
        } as any as ProjectRelationships,
        {},
        { deep: false },
    );

    get createdByFullName() {
        if (this.relationships.creator) {
            return this.relationships.creator.fullName;
        }

        return "Unknown";
    }

    get duplicateActionUrl() {
        return `${this.url}/actions/duplicate`;
    }

    get duplicateSampleActionUrl() {
        return `${this.url}/actions/duplicateSample`;
    }

    get upgradeActionUrl() {
        return `${this.url}/actions/upgrade`;
    }

    get serverSideUpgradeActionUrl() {
        return `${this.url}/actions/upgradeProject`;
    }

    get revertUpgradeActionUrl() {
        return `${this.url}/actions/revertUpgrade`;
    }

    get archiveActionUrl() {
        return `${this.url}/actions/archive`;
    }

    get formattedLatLong() {
        if (this.attributes.latitude && this.attributes.longitude) {
            return `${this.attributes.latitude}, ${this.attributes.longitude}`;
        } else {
            return null;
        }
    }

    get sheetsAndDefaults(): Sheet[] | null {
        if (!this.relationships.sheets) return null;

        const models = [...this.relationships.sheets.models];
        if (this.relationships.projectDefaultsSheet) {
            models.unshift(this.relationships.projectDefaultsSheet);
        }
        return models;
    }

    get sheetIdsSet(): Set<string> {
        const idSet = new Set(this.relationships.sheets!._models.keys());

        if (this.relationships.projectDefaultsSheet) {
            idSet.add(this.relationships.projectDefaultsSheet.id);
        }
        return idSet;
    }

    async waitForDuplicate(progressCallback?: (percent: number) => void) {
        while (this.attributes.duplicateState !== "finished") {
            await new Promise((resolve) =>
                setTimeout(resolve, DUPLICATE_POLL_PERIOD),
            );
            await api.read(this);
            if (progressCallback) {
                progressCallback(this.attributes.duplicateProgressPercent || 0);
            }
        }
        return this;
    }
}
export default ProjectModel;

export const PROJECT_INCLUDES = [
    "sheets",
    "sheets.sheetTemplate",
    "sheets.sheetTemplate.presets",
    "sheets.members",
    "sheets.resultSet",
    "sheets.resultSet.summaryCheckSheetTemplateWidget",
    "sheetGroups",
    "sheetGroups.sheets",
    "organisation",
    "organisation.logo",
    "organisation.subscription",
    "creator",
    "projectDefaultsSheet",
    "projectDefaultsSheet.resultSet",
    "projectPreferredSections",
    "projectPreferredSections.sharedTableVersion",
    "projectArchiveExports",
    "projectArchiveExports.export",
];

function setComparer(a: any, b: any): boolean {
    if (!(a instanceof Set) || !(b instanceof Set)) {
        return false;
    } else if (a.size !== b.size) {
        return false;
    } else {
        for (let elem of a) {
            if (!b.has(elem)) {
                return false;
            }
        }
        return true;
    }
}
