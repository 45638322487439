import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { ComputedAttributes } from "./SheetTemplateWidgetInterface";

class ComputedSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "computed",
        label: undefined,
        symbol: undefined,
        units: undefined,
        units2: undefined,
        unitsMks: undefined,
        unitsFps: undefined,
        export: undefined,
        showInSummary: undefined,
        showInSuperSummary: undefined,
        visibleIf: undefined,
        hidden: undefined,
        apiOutput: undefined,

        equation: [],

        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        referenceImage: undefined,
        checks: [],
        checkInHeader: undefined,
        checkShortName: undefined,
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        ignoreUnitAssertion: undefined,
        fullWidth: undefined,

        order: undefined,
    } as any as ComputedAttributes);

    unitOverride(unitSystem: string | null | undefined): string | null {
        if (unitSystem === "MKS" && this.attributes.unitsMks) {
            return this.attributes.unitsMks;
        } else if (unitSystem === "FPS" && this.attributes.unitsFps) {
            return this.attributes.unitsFps;
        } else {
            return null;
        }
    }

    get isMembersWidget() {
        return this.attributes.referenceId === "members";
    }
}

export default ComputedSheetTemplateWidgetModel;
