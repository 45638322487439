import { computed, makeObservable } from "mobx";
import Collection from "../Collection";
import SheetTemplateWidget from "../models/SheetTemplateWidget";

class SheetTemplateWidgetsCollection extends Collection<SheetTemplateWidget> {
    constructor(models) {
        super(models);
        makeObservable(this, {
            sectionGroups: computed,
        });
    }
    type = "sheetTemplateWidgets";

    getByReferenceId(referenceId: string) {
        return this.models.find(
            (model) => model.attributes.referenceId === referenceId,
        );
    }

    get sectionGroups(): SheetTemplateWidget[][] {
        let sectionGroups: SheetTemplateWidget[][] = [];

        let currentGroup;
        this.models.forEach((sheetTemplateWidget, idx) => {
            const type = sheetTemplateWidget.attributes.type;
            if (!currentGroup || type === "section") {
                currentGroup = [sheetTemplateWidget];
                sectionGroups.push(currentGroup);
            } else {
                currentGroup.push(sheetTemplateWidget);
            }
        });
        return sectionGroups;
    }
}
export default SheetTemplateWidgetsCollection;
