import Rollbar from "rollbar";

const PERIOD = 60000;

function getMessages() {
    return JSON.parse(localStorage.getItem("rollbarOffline") || "[]");
}

function setMessages(messages) {
    localStorage.setItem("rollbarOffline", JSON.stringify(messages));
}

function process() {
    const messages = getMessages();

    // Send a single message
    if (messages.length > 0) {
        // @ts-ignore TODO Rollbar bindings don't acknowledge any
        // of the functions:
        //     Property 'info' does not exist on type 'typeof Rollbar'
        Rollbar.info(`Offline: ${messages.shift()}`, function (err) {
            if (!err) {
                // Only store the updated messages list if we succeeded
                setMessages(messages);
            }
            setTimeout(process, PERIOD);
        });
    }
}

export function start() {
    setTimeout(process, PERIOD);
}

// Queue message for reliable reporting to Rollbar. Duplicate messages are ignored so were don't
// queue up megabytes of logs to send.
export function info(message: string) {
    const messages = getMessages();
    if (messages.indexOf(message) === -1) {
        messages.push(message);
        setMessages(messages);
    }
}
