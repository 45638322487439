import {
    getRequest,
    postRequest,
    putRequest,
    deleteRequest,
} from "./apiRequest";
import responseMapper from "./responseMapper";
import errorMapper from "./errorMapper";

export function read(dataObject, options = {}) {
    return getRequest(dataObject.url, null, options).then(
        responseMapper(dataObject, {
            modelsByType: options.modelsByType,
            collectionsByType: options.collectionsByType,
        }),
        errorMapper,
    );
}

// Follow next link on a Collection to load in the next page of data.
export function readNext(dataObject) {
    return getRequest(dataObject.links.next, null, {}).then(
        (response) => responseMapper(dataObject)(response, { append: true }),
        errorMapper,
    );
}

export function create(dataObject, options = {}) {
    if (!dataObject.constructor.isEditableProxy) {
        throw new TypeError(`dataObject is not an editable object`);
    }

    return postRequest(dataObject.url, dataObject.toResource(), options).then(
        (response) => {
            if (!options.expectingEmptyResponse) {
                responseMapper(dataObject.model)(response);
                dataObject.id = dataObject.model.id;
            }

            return dataObject;
        },
        errorMapper,
    );
}

export function update(dataObject, options = {}) {
    if (!dataObject.constructor.isEditableProxy) {
        throw new TypeError(`dataObject is not an editable object`);
    }

    return putRequest(dataObject.url, dataObject.toResource(), options).then(
        (response) => {
            responseMapper(dataObject.model)(response);
            return dataObject;
        },
        errorMapper,
    );
}

export function destroy(dataObject, options = {}) {
    return deleteRequest(dataObject.url, null, options).then((response) => {
        if (response) {
            // We respond with 202 Accepted and data for subscription deletes
            responseMapper(dataObject)(response);
        }
        return dataObject;
    }, errorMapper);
}

export function createOrUpdate(dataObject, options) {
    if (dataObject.id) {
        return update(dataObject, options);
    } else {
        return create(dataObject, options);
    }
}
