import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { CommentAttributes } from "./SheetTemplateWidgetInterface";

class CommentSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "comment",
        label: undefined,
        visibleIf: undefined,
        showInSuperSummary: undefined,

        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        referenceImage: undefined,

        symbol: undefined,
        export: undefined,

        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,

        order: undefined,
    } as any as CommentAttributes);

    isUserInput = true;
}

export default CommentSheetTemplateWidgetModel;
