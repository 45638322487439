import { observable } from "mobx";
import Model from "data/Model";

interface SessionAttributes {
    userId: string | null;
    email: string | null;
    password: string | null;
    userActionId: string | null;
    solverToken: string | null;
}

class SessionModel extends Model {
    type = "sessions";
    attributes = observable.object({
        userId: undefined,
        email: undefined,
        password: undefined,
        userActionId: undefined,
        solverToken: undefined,
    } as any as SessionAttributes);

    relationships = null;

    get url(): string {
        if (this.id) {
            // Use the /whoami endpoint rather than exposing the
            // id in the URL.
            //
            // This was a recommendation from ZX Security from our
            // pen test on July 2023.
            return `/whoami`;
        } else {
            return `/sessions`;
        }
    }
}
export default SessionModel;
