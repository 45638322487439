import { observable, computed, makeObservable } from "mobx";
import Model from "data/Model";

import Upload from "./Upload";
import OrganisationUser from "./OrganisationUser";
import Subscription from "./Subscription";
import LicenseType from "./LicenseType";
import SheetTemplatesCollection from "data/collections/SheetTemplates";

interface OrganisationAttributes {
    name: string | null;
    phone: string | null;
    isPaid: boolean | null;
    isTrial: boolean | null;
    trialEnd: string | null;
    enablePaidFeatures: boolean | null;
    abn: string | null;
    billingEmail: string | null;
    country: string | null;
    region: string | null;
    city: string | null;
    stripeBalance: number | null;
    stripeCurrency: string | null;
    createdAt: string | null;
    preferredBuildingStandard: string | null;
    preferredUnitSystem: "MKS" | "FPS" | null;
    estimatedTeamSize: string | null;
    externalCustomer: null;
    timezoneOffset: number | null;
    signupReason: string | null;
    currentSoftware: string | null;
    // isAcademic defines when an academic license is active.
    // (i.e. isAcademicOrg is true and the is_trial is false) whereas
    // isAcademicOrg defines whether or not this is an academic org.
    isAcademic: boolean | null;
    isAcademicOrg: boolean | null;
    academicCourseName: string | null;
    academicCourseEnd: string | null;
    featureBuilder: boolean | null;
    featureStandards: boolean | null;
    calculationFrequency: string | null;
    stripeCustomerId: string | null;
    experimentGroupNumber: number | null;
    preferredPrintMode: string | null;
    preferredPrintProjectDefaults: boolean | null;
    preferredPrintMemberSchedule: boolean | null;
    preferredPaperSize: string | null;
}

interface OrganisationRelationships {
    logo: Upload | null;
    myOrganisationUser: OrganisationUser | null;
    subscription: Subscription | null;
    licenseType: LicenseType | null;
    sheetTemplates: SheetTemplatesCollection;
}

class OrganisationModel extends Model {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            logoUrl: computed,
        });
    }
    type = "organisations";
    attributes = observable.object({
        name: undefined,
        phone: undefined,
        isPaid: undefined,
        isTrial: undefined,
        trialEnd: undefined,
        enablePaidFeatures: undefined,
        abn: undefined,
        billingEmail: undefined,
        country: undefined,
        region: undefined,
        city: undefined,
        stripeBalance: undefined,
        stripeCurrency: undefined,
        createdAt: undefined,
        preferredBuildingStandard: undefined,
        preferredUnitSystem: undefined,
        estimatedTeamSize: undefined,
        externalCustomer: undefined,
        timezoneOffset: undefined,
        signupReason: undefined,
        currentSoftware: undefined,
        isAcademic: false,
        isAcademicOrg: false,
        academicCourseName: undefined,
        academicCourseEnd: undefined,
        featureBuilder: undefined,
        featureStandards: undefined,
        calculationFrequency: undefined,
        stripeCustomerId: undefined,
        experimentGroupNumber: undefined,
        preferredPrintMode: undefined,
        preferredPrintProjectDefaults: undefined,
        preferredPrintMemberSchedule: undefined,
        preferredPaperSize: undefined,
    } as any as OrganisationAttributes);
    relationships = observable.object(
        {
            logo: undefined,
            myOrganisationUser: undefined,
            subscription: undefined,
            licenseType: undefined,
            sheetTemplates: undefined,
        } as any as OrganisationRelationships,
        {},
        { deep: false },
    );

    get logoUrl(): string | null {
        if (this.attributes.enablePaidFeatures && this.relationships.logo) {
            return this.relationships.logo.attributes.url;
        } else {
            if (this.attributes.externalCustomer === "esics") {
                return "https://clearcalcs-public.s3-ap-southeast-2.amazonaws.com/mafiorglogo.jpg";
            } else {
                return null;
            }
        }
    }

    get trialDaysRemaining(): number {
        const milliseconds =
            Date.parse(this.attributes.trialEnd!).valueOf() - Date.now();
        return Math.max(Math.round(milliseconds / 1000 / 60 / 60 / 24), 0);
    }

    get expiredTrial(): boolean {
        return (
            !this.attributes.isPaid &&
            !this.relationships.subscription &&
            !this.attributes.isTrial
        );
    }

    getRole(): string | undefined {
        if (this.relationships.myOrganisationUser) {
            return this.relationships.myOrganisationUser.attributes.role!;
        }
    }
}
export default OrganisationModel;

// TODO: There's probably a better place to store these constants.
export const CURRENT_ORGANISATION_ID = "currentOrganisationId";
