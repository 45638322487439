import React, { useEffect, useState } from "react";

interface IContext {
    collapsed: boolean;
    collapsible: boolean;
    setCollapsed?: (collapsed: boolean) => void;
    extendedHover: boolean;
    setExtendedHover?: (collapsed: boolean) => void;
    selectedSheetGroupId: string | null;
    setSelectedSheetGroupId?: (sheeGroupId: string | null) => void;
}
export const SidebarContext = React.createContext<IContext>({
    collapsed: false,
    collapsible: false,
    selectedSheetGroupId: null,
    extendedHover: false,
});

const SIDEBAR_COLLAPSE_WIDTH = 1025;

export function SidebarContextProvider(props) {
    const [collapsible, setCollapsible] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [selectedSheetGroupId, setSelectedSheetGroupId] = useState<
        string | null
    >(null);
    const [extendedHover, setExtendedHover] = useState(false);

    useEffect(() => {
        function _updateCollapsible() {
            setCollapsible(window.innerWidth < SIDEBAR_COLLAPSE_WIDTH);
            setCollapsed(window.innerWidth < SIDEBAR_COLLAPSE_WIDTH);
        }
        window.addEventListener("resize", _updateCollapsible);
        _updateCollapsible();

        return () => {
            window.removeEventListener("resize", _updateCollapsible);
        };
    }, []);

    return (
        <SidebarContext.Provider
            value={{
                collapsed,
                collapsible,
                setCollapsed,
                extendedHover,
                setExtendedHover,
                selectedSheetGroupId,
                setSelectedSheetGroupId,
            }}
        >
            {props.children}
        </SidebarContext.Provider>
    );
}
