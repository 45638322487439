import TableSheetTemplateWidgetModel from "data/models/TableSheetTemplateWidget";
import SheetTemplate from "data/models/SheetTemplate";
import LookupSheetTemplateWidgetModel from "data/models/LookupSheetTemplateWidget";
import {
    isAccessorNode,
    isArrayNode,
    isConstantNode,
    isFunctionNode,
    isOperatorNode,
} from "mathjs";
import parseWithNumberSupport from "math/parseWithNumberSupport";

function equationToTexFactory(
    sheetTemplate: SheetTemplate,
    renderRemote: boolean,
    tableSheetTemplateWidget?: TableSheetTemplateWidgetModel,
    LDFlagEnableDebugHyperlink?: boolean,
) {
    const sheetTemplateWidgets =
        sheetTemplate.relationships.sheetTemplateWidgets;

    const scopeFunctions = {
        x(node) {
            const model = sheetTemplateWidgets.getByReferenceId(
                node.args[0].value.toString(),
            );
            if (model) {
                if (model.attributes.type === "remote" && !renderRemote) {
                    throw new Error(
                        "Aborting render of remote widget equation",
                    );
                }
                if (LDFlagEnableDebugHyperlink) {
                    return `\\color{#0076D6}\\href{#${model.attributes.referenceId}}{${model.attributes.symbol}}`;
                } else {
                    return `{${model.attributes.symbol}}`;
                }
            } else {
                return `\\red{\\operatorname{x}(${node.args[0].toTex()})}`;
            }
        },

        L(node) {
            const model = sheetTemplateWidgets.getByReferenceId(
                node.args[0].value.toString(),
            );
            if (model && model instanceof LookupSheetTemplateWidgetModel) {
                return `{${model.symbolForColumn(node.args[1].value)}}`;
            } else {
                return `\\red{\\operatorname{L}(${node.args[0].toTex()}}`;
            }
        },

        I(node) {
            // eslint-disable-next-line no-use-before-define
            return `[${node.args[0].toTex({ handler: equationToTexHandler })}]`;
        },

        setSum(node) {
            const from = node.args[0].toTex({ handler: equationToTexHandler });
            const to = node.args[1].toTex({ handler: equationToTexHandler });
            let equationNode = node.args[2];
            if (
                isConstantNode(equationNode) &&
                typeof equationNode.value === "string"
            ) {
                equationNode = parseWithNumberSupport(equationNode.value);
            }
            const equation = equationNode.toTex({
                handler: equationToTexHandler,
            });
            const equationIndex = node.args[3].toTex({
                handler: equationToTexHandler,
            });
            return `{\\displaystyle\\sum_{${equationIndex}=${from}}^{${to}} (${equation}})`;
        },

        solveSecant(node) {
            const x0 = node.args[0].toTex({ handler: equationToTexHandler });
            const x1 = node.args[1].toTex({ handler: equationToTexHandler });
            const criteria = node.args[2].toTex({
                handler: equationToTexHandler,
            });
            const equation = node.args[4].toTex({
                handler: equationToTexHandler,
            });
            const variable = node.args[4].params[0];

            return `{\\text{solve}(${equation}=0 ~ \\vert ~ ${variable}_0=${x0}, ${variable}_1=${x1}, \\text{max}(\\Delta ${variable})=${criteria})}`;
        },

        interpolate(node) {
            const mat = node.args[0].toTex({ handler: equationToTexHandler });
            const xVal = node.args[1].toTex({ handler: equationToTexHandler });
            const xArray = node.args[2].toTex({
                handler: equationToTexHandler,
            });
            let interpMode = "linear";
            if (node.args.length === 4 || node.args.length === 6) {
                interpMode = node.args[node.args.length - 1].value;
            }
            if (node.args.length <= 4) {
                if (interpMode === "log" || interpMode === "logarithmic") {
                    return `{\\text{interpolate}(${xVal} \\in ${xArray} \\underset{log}{\\mapsto} ${mat})}`;
                }
                return `{\\text{interpolate}(${xVal} \\in ${xArray} \\underset{linear}{\\mapsto} ${mat})}`;
            } else if (node.args.length <= 6) {
                const yVal = node.args[3].toTex({
                    handler: equationToTexHandler,
                });
                const yArray = node.args[4].toTex({
                    handler: equationToTexHandler,
                });
                if (interpMode === "log" || interpMode === "logarithmic") {
                    return `{\\text{interpolate}(${xVal} \\in ${xArray} \\land ${yVal} \\in ${yArray} \\underset{log}{\\mapsto} ${mat})}`;
                }
                return `{\\text{interpolate}(${xVal} \\in ${xArray} \\land ${yVal} \\in ${yArray} \\underset{linear}{\\mapsto} ${mat})}`;
            }
        },

        matrixSubset(node) {
            if (
                isFunctionNode(node.args[0]) &&
                node.args[0].fn.name === "x" &&
                isConstantNode(node.args[2])
            ) {
                const model = sheetTemplateWidgets.getByReferenceId(
                    node.args[0].args[0].evaluate(),
                );
                if (model && model instanceof TableSheetTemplateWidgetModel) {
                    const columnSymbol = model.symbolForColumn(
                        node.args[2].evaluate() - 1,
                    );

                    if (
                        tableSheetTemplateWidget &&
                        isRowIndexPlusOne(node.args[1])
                    ) {
                        return `{${model.attributes.symbol}}[{${columnSymbol}}]`;
                    } else {
                        return `{{${
                            model.attributes.symbol
                        }}[{${columnSymbol}}]_{${node.args[1].toTex({
                            handler: equationToTexHandler,
                        })}}}`;
                    }
                }
            }

            const matrix = node.args[0].toTex({
                handler: equationToTexHandler,
            });
            const rowIndex = node.args[1].toTex({
                handler: equationToTexHandler,
            });
            const columnIndex = node.args[2].toTex({
                handler: equationToTexHandler,
            });
            return `{{${matrix}}_{${rowIndex},${columnIndex}}}`;
        },

        vectorSubset(node) {
            const vector = node.args[0].toTex({
                handler: equationToTexHandler,
            });
            const index = node.args[1].toTex({ handler: equationToTexHandler });

            if (tableSheetTemplateWidget && isRowIndexPlusOne(node.args[1])) {
                // Table equation display - convert any references that use the current
                // rowIndex() to vector equations.
                return vector;
            } else {
                return `{{${vector}}_{${index}}}`;
            }
        },

        isNumber(node) {
            return `{\\operatorname{isNumber}(${node.args[0].toTex({
                handler: equationToTexHandler,
            })})}`;
        },

        svg(node) {
            return "";
        },

        T(node) {
            if (
                tableSheetTemplateWidget &&
                isRowIndex(node.args[0]) &&
                isConstantNode(node.args[1])
            ) {
                const columnSymbol = tableSheetTemplateWidget.symbolForColumn(
                    node.args[1].evaluate(),
                );
                return `{${tableSheetTemplateWidget.attributes.symbol}}[{${columnSymbol}}]`;
            }
        },

        col(node) {
            if (
                isFunctionNode(node.args[0]) &&
                node.args[0].fn.name === "x" &&
                isConstantNode(node.args[1])
            ) {
                // Treat references to table columns as a vector
                const model = sheetTemplateWidgets.getByReferenceId(
                    node.args[0].args[0].evaluate(),
                );
                if (model && model instanceof TableSheetTemplateWidgetModel) {
                    const columnSymbol = model.symbolForColumn(
                        node.args[1].evaluate() - 1,
                    );
                    return `{${model.attributes.symbol}}[{${columnSymbol}}]`;
                }
            }
        },

        isIncluded(node) {
            const subset = node.args[0].toTex({
                handler: equationToTexHandler,
            });
            const set = node.args[1].toTex({ handler: equationToTexHandler });
            return `{${subset} \\underset{\\text{within}}{\\in} ${set}}`;
        },

        setIsSubset(node) {
            const subset = node.args[0].toTex({
                handler: equationToTexHandler,
            });
            const set = node.args[1].toTex({ handler: equationToTexHandler });
            return `{${subset} \\underset{\\text{within}}{\\subset} ${set}}`;
        },
    };

    const operatorFunctions = {
        and(node) {
            const left = node.args[0].toTex({ handler: equationToTexHandler });
            const right = node.args[1].toTex({ handler: equationToTexHandler });

            return `${left}\\text{ and }${right}`;
        },
        or(node) {
            const left = node.args[0].toTex({ handler: equationToTexHandler });
            const right = node.args[1].toTex({ handler: equationToTexHandler });

            return `${left}\\text{ or }${right}`;
        },
        xor(node) {
            const left = node.args[0].toTex({ handler: equationToTexHandler });
            const right = node.args[1].toTex({ handler: equationToTexHandler });

            return `${left}\\text{ xor }${right}`;
        },
    };

    const equationToTexHandler = function (node, options) {
        if (isOperatorNode(node) && operatorFunctions[node.fn]) {
            return operatorFunctions[node.fn](node);
        }

        if (isFunctionNode(node) && scopeFunctions[node.fn.name]) {
            return scopeFunctions[node.fn.name](node, options);
        }

        // Vectorise array access by rowIndex() in table equations
        if (
            tableSheetTemplateWidget &&
            isAccessorNode(node) &&
            node.index.dimensions.length === 1 &&
            isRowIndex(node.index.dimensions[0])
        ) {
            return node.object.toTex(options);
        }

        if (isArrayNode(node)) {
            // We need to handle ArrayNodes ourselves because
            // MathJS is currently adding an extra new line at
            // the end of the Matrix that KaTeX is then outputting
            //
            // This is basically the same as the internal ArrayNode
            // toTeX handler, except for the last node check.
            let output = "\\begin{bmatrix}";

            // Traverse the children fo the array
            node.items.forEach(function (childNode) {
                // If childNode is another array, traverse
                // the children of that.
                if (isArrayNode(childNode) && childNode.items) {
                    output += childNode.items
                        .map(function (childInnerNode) {
                            return childInnerNode.toTex(options);
                        })
                        .join("&");
                } else {
                    output += childNode.toTex(options);
                }

                // If we're not the last node in the array add a
                // new line afterwards
                if (node.items.indexOf(childNode) !== node.items.length - 1) {
                    output += "\\\\";
                }
            });
            output += "\\end{bmatrix}";
            return output;
        }
    };
    return equationToTexHandler;
}
export default equationToTexFactory;

function isRowIndex(node) {
    return isFunctionNode(node) && node.fn.name === "rowIndex";
}

function isRowIndexPlusOne(node) {
    return (
        isOperatorNode(node) &&
        node.op === "+" &&
        isFunctionNode(node.args[0]) &&
        node.args[0].fn.name === "rowIndex" &&
        isConstantNode(node.args[1]) &&
        node.args[1].evaluate() === 1
    );
}
