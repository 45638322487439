import { toJS, observable, computed, makeObservable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { TextAttributes } from "./SheetTemplateWidgetInterface";

class TextSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            richText: computed,
        });
    }
    attributes = observable.object({
        type: "text",
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,

        order: undefined,

        richText: undefined,
    } as any as TextAttributes);

    get richText() {
        return toJS(this.attributes.richText);
    }
}

export default TextSheetTemplateWidgetModel;
