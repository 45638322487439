import SheetsCollection from "data/collections/Sheets";
import { computed, observable } from "mobx";
import Model from "../Model";
import Sheet from "./Sheet";
import math from "math";

interface SubsheetDefinitionAttributes {
    referenceId: string | null;
    value: SubsheetDefinitionValue[] | null;
    createdAt: string | null;
    updatedAt: string | null;
}

export interface SubsheetDefinitionValue {
    id: string;
    commonInputs: any;
}

interface SubsheetDefinitionRelationships {
    sheet: Sheet;
    subsheets: SheetsCollection;
}

export default class SubsheetDefinition extends Model {
    type = "subsheetDefinitions";
    attributes = observable.object({
        referenceId: undefined,
        value: undefined,
        createdAt: undefined,
        updatedAt: undefined,
    } as any as SubsheetDefinitionAttributes);

    relationships = observable.object(
        {
            sheet: undefined,
            subsheets: undefined,
        } as any as SubsheetDefinitionRelationships,
        {},
        { deep: false },
    );
}
