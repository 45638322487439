import { observable, computed, makeObservable } from "mobx";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";
import { postRequest } from "data/utils/apiRequest";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import {
    CustomDiagramAttributes,
    CustomDiagramRelationships,
} from "./SheetTemplateWidgetInterface";
export interface ResultObject {
    [key: string]: string;
}

// Expected return types from lambda or iframe.
export type Parameter = {
    key: string;
    type: string;
};

export type RenderResponse =
    | {
          success: true;
          result?: string; // If user code has no return, result will not come back
      }
    | {
          success: false;
          error: {
              message: string;
              stack: string[];
          };
      };
type ParamsResponse =
    | {
          success: true;
          result: Array<Parameter>;
      }
    | {
          success: false;
          error: {
              message: string;
              stack: string[];
          };
      };

class CustomDiagramSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            equation: computed,
            customdiagramParams: computed,
            customdiagramRenderEmptyParams: computed,
        });
    }
    attributes = observable.object({
        type: "customdiagram",
        symbol: undefined,
        equation: [],
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        showInSummary: undefined,
        showInSuperSummary: undefined,
        order: undefined,
    } as any as CustomDiagramAttributes);

    relationships = observable.object(
        {
            sheetTemplate: undefined,
            upload: undefined,
            uploadInteractive: undefined,
        } as any as CustomDiagramRelationships,
        {},
        { deep: false },
    );
    customDiagramImgSrc;
    // Custom diagrams store the equation in a JSON compatible format
    // Below equation can be used when editing each key of the mathjs object individually.
    get equation(): Array<{
        condition: string;
        result: ResultObject;
    }> {
        return this.attributes.equation.map((equation) => {
            try {
                return {
                    condition: equation.condition,
                    result: JSON.parse(equation.result),
                };
            } catch (e) {
                console.error("Equation result could not be JSON parsed.", e);
                return {
                    condition: equation.condition,
                    result: {}, // Custom Diagram will only function with JSON parseable objects..
                };
            }
        });
    }

    get customdiagramParams(): IPromiseBasedObservable<ParamsResponse> {
        if (!this.relationships.upload) {
            return fromPromise(Promise.resolve({ success: true, result: [] }));
        } else if (
            this.relationships.upload.attributes.status !== "completed"
        ) {
            return fromPromise(new Promise(() => {}));
        } else {
            // TODO: Error if params does not match shape of ParamsResponse
            return fromPromise(postRequest(this.paramsUrl));
        }
    }

    get customdiagramRenderEmptyParams(): IPromiseBasedObservable<RenderResponse | null> {
        if (!this.relationships.upload) {
            return fromPromise(Promise.resolve(null));
        } else if (
            this.relationships.upload.attributes.status !== "completed"
        ) {
            return fromPromise(new Promise(() => {}));
        } else {
            return fromPromise(postRequest(this.renderUrl));
        }
    }

    get paramsUrl() {
        return `${this.url}/actions/customdiagramParams`;
    }

    get renderUrl() {
        return `${this.url}/actions/customdiagramRender`;
    }
}

export default CustomDiagramSheetTemplateWidgetModel;
