import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { DiagramAttributes } from "./SheetTemplateWidgetInterface";

class DiagramSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "diagram",
        diagram: [],
        equation: [],
        referenceId: undefined,
        visibleIf: undefined,
        showInSuperSummary: undefined,

        createdAt: undefined,
        updatedAt: undefined,
        order: undefined,
    } as any as DiagramAttributes);
}

export default DiagramSheetTemplateWidgetModel;
