import { observable, values, ObservableMap } from "mobx";
import Model from "./Model";
import Universe from "./Universe";

abstract class Collection<T extends Model> {
    abstract type: string;
    universe?: Universe;

    get url() {
        return `/${this.type}`;
    }

    constructor(modelsArray?: Array<T>) {
        if (modelsArray) {
            this._setModels(modelsArray);
        }
    }

    _models: ObservableMap<string, T> = observable.map(new Map(), {
        deep: false,
    });
    get models(): Array<T> {
        return Array.from(values(this._models));
    }

    get(modelId): T | undefined {
        return this._models.get(modelId);
    }

    _setModels(modelsArray: Array<T>, options = { append: false }) {
        modelsArray.forEach(assertRealModel);

        const newModels = modelsArray.map((model) => {
            const modelEntry: [key: string, value: T] = [model.id, model];
            return modelEntry;
        });
        // Replace has been broken in the version upgrade per
        // https://github.com/mobxjs/mobx/issues/1842. Implementing
        // previous behaviour
        //
        //
        // this._models.replace(newModels);
        if (!options.append) {
            this._models.clear();
        }
        this._models.merge(newModels);
    }

    _setModel(model: T) {
        assertRealModel(model);
        this._models.set(model.id, model);
    }
    _removeModel(model: T) {
        this._models.delete(model.id);
    }

    toResource(): any[] {
        return this.models.map((model) => model.toResource());
    }

    toRelationship(): Array<any | null> {
        return this.models.map((model) => model.toRelationship());
    }
}
export default Collection;

function assertRealModel(model) {
    if (model.constructor.isEditableProxy) {
        throw new TypeError(
            `Can't add editable proxies to collections. Add the inner model instead.`,
        );
    }
}
