import { observable } from "mobx";
import Model from "data/Model";

interface InvoiceAttributes {
    date: string | null;
    total: string | null;
    sourceDescription: string | null;
    amountDue: number | null;
    paid: string | null;
    lines: string | null;
    hostedInvoiceUrl: string | null;
}

class Invoice extends Model {
    type = "invoices";

    attributes = observable.object({
        date: undefined,
        total: undefined,
        sourceDescription: undefined,
        amountDue: undefined,
        paid: undefined,
        lines: undefined,
        hostedInvoiceUrl: undefined,
    } as any as InvoiceAttributes);

    relationships = null;
}
export default Invoice;
