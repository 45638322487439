let initialized = false;
const customerio = {
    init(siteId: string) {
        if (!initialized) {
            (function (window: any) {
                window._cio = window._cio || [];
                var factory = function (method) {
                    return function () {
                        window._cio.push(
                            [method].concat(
                                Array.prototype.slice.call(arguments, 0),
                            ),
                        );
                    };
                };
                var methods = [
                    "load",
                    "identify",
                    "sidentify",
                    "track",
                    "page",
                    "on",
                    "off",
                ];
                for (var i = 0; i < methods.length; i++) {
                    window._cio[methods[i]] = factory(methods[i]);
                }
                var script = document.createElement("script");
                var first = document.getElementsByTagName("script")[0];
                script.async = true;
                script.id = "cio-tracker";
                script.setAttribute("data-site-id", siteId);
                script.setAttribute("data-use-array-params", "true");

                //Enables in-app messaging
                script.setAttribute("data-use-in-app", "true");

                script.src = "https://assets.customer.io/assets/track.js";
                //If your account is in the EU, use:
                //t.src = 'https://assets.customer.io/assets/track-eu.js'
                first.parentNode!.insertBefore(script, first);
            })(window);
            initialized = true;
        }
    },

    get instance() {
        if (typeof window === "undefined") {
            // window doesn't exist - maybe we're running in a worker or nodejs. We don't support
            // using Customer.io from anywhere but the main browser thread right now.
            return null;
        }

        return (window as any)._cio;
    },
};
export default customerio;
