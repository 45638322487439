import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { SubsheetAttributes } from "./SheetTemplateWidgetInterface";

class SubsheetSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "subsheet",
        label: undefined,
        symbol: undefined,
        equation: [],
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        visibleIf: undefined,

        order: undefined,
    } as any as SubsheetAttributes);
}

export default SubsheetSheetTemplateWidgetModel;
