import { observable } from "mobx";
import Model from "data/Model";

import Project from "./Project";

interface ProjectPreferredSectionAttributes {
    label: string | undefined;
    sharedTableCode: string | undefined;
}

interface ProjectPreferredSectionRelationships {
    project: Project | null;
}

class ProjectPreferredSectionModel extends Model {
    type = "projectPreferredSections";
    attributes = observable.object({
        label: undefined,
        sharedTableCode: undefined,
    } as any as ProjectPreferredSectionAttributes);

    relationships = observable.object(
        {
            project: undefined,
        } as any as ProjectPreferredSectionRelationships,
        {},
        { deep: false },
    );
}
export default ProjectPreferredSectionModel;
