import { observable } from "mobx";
import Model from "data/Model";
import SheetTemplate from "./SheetTemplate";

export interface PresetAttributes {
    code: string | null;
    name: string | null;
    icon: string | null;
    values: any | null;
    testingOnly: boolean | null;
}

interface PresetRelationships {
    sheetTemplate: SheetTemplate | null;
}

export default class Preset extends Model {
    type = "presets";
    attributes = observable.object({
        code: undefined,
        name: undefined,
        icon: undefined,
        values: undefined,
        testingOnly: undefined,
    } as any as PresetAttributes);

    relationships = observable.object(
        {
            sheetTemplate: undefined,
        } as any as PresetRelationships,
        {},
        { deep: false },
    );
}
