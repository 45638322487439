import { observable } from "mobx";
import Model from "data/Model";

import Organisation from "./Organisation";

interface SubscriptionAttributes {
    plan: string | null;
    quantity: number | null;
    stripeToken: string | null;
    pastDue: boolean | null;
    cancelAtPeriodEnd: boolean | null;
    nextPaymentAttempt: string | null;
    upcomingAmountDue: number | null;
    currency: string | null;
    coupon: string | null;
    createdAt: string | null;
    updatedAt: string | null;
    deletedAt: string | null;
    cancelReason: string | null;
    cancelNotes: string | null;
    billingInterval: string | null;
}

interface SubscriptionRelationships {
    organisation: Organisation | null;
}

class SubscriptionModel extends Model {
    type = "subscriptions";
    attributes = observable.object({
        plan: undefined,
        quantity: undefined,
        stripeToken: undefined,
        pastDue: undefined,
        cancelAtPeriodEnd: undefined,
        nextPaymentAttempt: undefined,
        upcomingAmountDue: undefined,
        currency: undefined,
        coupon: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        deletedAt: undefined,
        cancelReason: undefined,
        cancelNotes: undefined,
        billingInterval: undefined,
    } as any as SubscriptionAttributes);
    relationships = observable.object(
        {
            organisation: undefined,
        } as any as SubscriptionRelationships,
        {},
        { deep: false },
    );
}
export default SubscriptionModel;
