import math from "math";
import {
    MathNode,
    EvalFunction,
    isConstantNode,
    isFunctionNode,
    isSymbolNode,
    isConditionalNode,
    isFunctionAssignmentNode,
    isBlockNode,
    isIndexNode,
} from "mathjs";
import parseWithNumberSupport from "math/parseWithNumberSupport";
import { when, computed } from "mobx";
import { fromPromise } from "mobx-utils";

import conditionalEquationsToNode from "./conditionalEquationsToNode";
import remoteLambda from "../data/utils/remoteLambda";
import { PendingDataError } from "errors";
import SheetModel from "data/models/SheetShadow";
import SheetTemplateWidgetModel from "data/models/SheetTemplateWidget";
import LookupSheetTemplateWidgetModel from "data/models/LookupSheetTemplateWidget";

// Counter for creating unique names
let n = 0;

// MathJs introduces optional Map for scope in v9.4.1.
// staticFunctions.js injects functions that receive the internal scope (which is a Map) when using rawArgs
// However, objects are still supported when calling math.evaluate(scope).
// TODO: Use of objects might be completely deprecated in future, which will require re-writing all custom scope
// see https://github.com/josdejong/mathjs/issues/2165
function ensureObject(objOrMap: OuterScopeObjOrMap): OuterScope {
    if (!(objOrMap instanceof Map)) return objOrMap;

    const obj = {};
    [...objOrMap.entries()].forEach(([k, v]) => {
        obj[k] = v;
    });
    return obj;
}

// Typically sheet: SheetModel also a key
type OuterScope = {
    [any: string]: any;
};

type OuterScopeObjOrMap = OuterScope | Map<string, any>;

export default function partialEval(
    input: MathNode,
    outerScopeObjOrMap: OuterScopeObjOrMap,
    unboundTransformer: (node: MathNode) => MathNode | null,
    allowAsync = true,
    globallyUnbound = true,
): PartialEvalResult {
    const outerScope = ensureObject(outerScopeObjOrMap);

    // Unit tests use partialEvalRaw to get the node result and compare with asserted value.
    const { node, definitions, remotes, fnNames } = partialEvalRaw(
        input, // full equation that needs to be evaluated.
        outerScope,
        unboundTransformer,
        allowAsync,
        globallyUnbound,
    );
    const compiledNode = node.compile();
    remotes.forEach((value) => {
        value.equation = value.equation.compile();
    });

    return {
        isAsync: remotes.size > 0,
        // Closure that contains all (cached) definitions, and the JS func with the simplified root node.
        evaluate(scope) {
            const defCache = new Map();

            const localScope = {
                ...outerScope,
                ...scope,
                [fnNames.def](key) {
                    if (!defCache.has(key)) {
                        if (!definitions.has(key)) {
                            throw new Error("Unknown def: " + key);
                        }
                        let def = definitions.get(key);
                        if (def.evaluate) {
                            if (def.compile) {
                                def = def.compile();
                                definitions.set(key, def);
                            }
                            defCache.set(key, def.evaluate({ ...localScope }));
                        } else {
                            // Direct reference to Matrix instance
                            defCache.set(key, def);
                        }
                    }
                    return defCache.get(key);
                },
                [fnNames.remote](key) {
                    const {
                        lambdaName,
                        solver,
                        equation,
                        enableTypes,
                        unboundIndex,
                    } = remotes.get(key);

                    if (unboundIndex === -1) {
                        // This remote is fully bound. Use the normal x() function that will cache the single
                        // result that we need across all evaluations.
                        return outerScope.x(key);
                    }

                    if (!defCache.has(key)) {
                        // The remote result has been evaluated for the first time - request it from the server.
                        let remoteValuePromise;
                        if (lambdaName == "Solver") {
                            remoteValuePromise = fromPromise(
                                remoteLambda(
                                    lambdaName,
                                    solver,
                                    equation.evaluate({ ...localScope }),
                                    enableTypes,
                                ),
                            );
                        } else if (lambdaName == "GIS") {
                            remoteValuePromise = fromPromise(
                                remoteLambda(
                                    lambdaName,
                                    solver.evaluate({ ...localScope })["type"],
                                    equation.evaluate({ ...localScope }),
                                    enableTypes,
                                ),
                            );
                        } else {
                            throw new Error(
                                "Unknown lambdaName: " + lambdaName,
                            );
                        }
                        defCache.set(
                            key,
                            computed(() => {
                                if (remoteValuePromise.state === "fulfilled") {
                                    return remoteValuePromise.value;
                                } else if (
                                    remoteValuePromise.state === "rejected"
                                ) {
                                    throw remoteValuePromise.value;
                                } else {
                                    throw new PendingDataError(
                                        "Waiting for remote solver",
                                    );
                                }
                            }),
                        );
                    }

                    // Get the current result from the computed. This will raise PendingDataError if we're still
                    // waiting for the server.
                    return defCache.get(key).get();
                },
            };

            if (remotes.size > 0) {
                const evalResult = computed(() =>
                    compiledNode.evaluate(localScope),
                );

                // Wait until evalResult evaluates without throwing PendingDataError
                return when(() => {
                    try {
                        evalResult.get();
                        return true;
                    } catch (e) {
                        if (e instanceof PendingDataError) {
                            return false;
                        } else {
                            throw e;
                        }
                    }
                }).then(() => evalResult.get());
            } else {
                return compiledNode.evaluate(localScope);
            }
        },
    };
}

// Useful for introspecting the uncompiled expression in tests.
export function partialEvalRaw(
    input: MathNode,
    outerScope: OuterScope,
    unboundTransformer: (node: MathNode) => MathNode | null,
    allowAsync: boolean,
    globallyUnbound: boolean,
) {
    const sheet: SheetModel = outerScope.sheet;
    const definitions = new Map<string, any>();
    const remotes = new Map<string, any>();
    const fnNames = {
        def: `def_${n++}`,
        remote: `remote_${n++}`,
    };

    // Track references so we can detected cycles (instead of blowing the call stack), and give a helpful error message.
    const referenceStack: string[] = [];

    // Pre-processing.
    function transformer(node) {
        if (unboundTransformer(node)) {
            return node;
        } else if (isFunctionNode(node) && node.fn.name === "x") {
            // Any function call x() which is a reference to another widget
            // has a special handling to add it to the definition cache.
            //
            const referenceId = node.args[0].evaluate().toString();
            const inputsOnly =
                node.args[1] &&
                isConstantNode(node.args[1]) &&
                // Typescript definition only allows string | number, however we use booleans in templates
                node.args[1].value.toString() === "true";

            if (referenceStack.includes(referenceId)) {
                referenceStack.push(referenceId);
                throw new Error(
                    `Reference cycle detected: ${referenceStack.join(" -> ")}`,
                );
            }
            referenceStack.push(referenceId);
            try {
                const defKey = `${referenceId}|${inputsOnly}`;

                // If we're not matching anything globally unbound, don't waste any time evaluating and optimizing
                // references - they're always going to bound and we can use them as-is.
                if (!globallyUnbound) {
                    if (!definitions.has(defKey)) {
                        definitions.set(defKey, node);
                    }
                    return defNode(fnNames, defKey);
                }

                const sheetWidget =
                    sheet.sheetWidgetsByReferenceId.get(referenceId);

                if (!sheetWidget) {
                    return new math.FunctionNode("throw", [
                        new math.ConstantNode(
                            "Could not find widget with referenceId: " +
                                referenceId,
                        ),
                    ]);
                }
                const type = sheetWidget.sheetTemplateWidget.attributes.type;

                if (type === "remote") {
                    if (allowAsync) {
                        if (!remotes.has(referenceId)) {
                            remotes.set(referenceId, {
                                lambdaName: "Solver",
                                solver: sheetWidget.sheetTemplateWidget
                                    .attributes.solver,
                                equation: conditionalEquationsToNode(
                                    sheetWidget.sheetTemplateWidget.attributes
                                        .equation,
                                    sheet,
                                ).transform(transformer),
                                enableTypes:
                                    sheetWidget.sheetTemplateWidget.attributes
                                        .enableTypes,
                            });
                        }

                        return remoteNode(fnNames, referenceId);
                    } else {
                        // The remote value must be available, or eval will fail with PendingDataError.
                        return node;
                    }
                } else if (type === "gis") {
                    if (allowAsync) {
                        if (!remotes.has(referenceId)) {
                            remotes.set(referenceId, {
                                lambdaName: "GIS",
                                solver: conditionalEquationsToNode(
                                    sheetWidget.sheetTemplateWidget.attributes
                                        .equation,
                                    sheet,
                                ).transform(transformer),
                                equation: conditionalEquationsToNode(
                                    sheetWidget.sheetTemplateWidget.attributes
                                        .equation,
                                    sheet,
                                ).transform(transformer),
                                enableTypes: true,
                            });
                        }

                        return remoteNode(fnNames, referenceId);
                    } else {
                        // The remote value must be available, or eval will fail with PendingDataError.
                        return node;
                    }
                } else if (
                    // When encountering a custom diagram widget, the partial eval expression
                    // can just store the plain javascript object returned by the widget.
                    // Unlike a MathJS expression tree generated by other widgets e.g. input,
                    // there is no benefit to cache a pure javascript object in the "definitions" map.
                    type === "customdiagram"
                ) {
                    return node;
                }

                if (definitions.has(defKey)) {
                    return defNode(fnNames, defKey);
                }

                let newNode;
                if (type === "input") {
                    newNode = sheetWidget.inputNode;
                } else if (type === "lookup") {
                    newNode = sheetWidget.lookupNode;
                } else if (type === "computed") {
                    newNode = sheetWidget.equationNode;
                } else if (type === "table") {
                    newNode = sheetWidget.getTableExpression(inputsOnly);
                } else {
                    throw new Error(
                        `Unhandled widget type ${type} for reference ${node.toString()}`,
                    );
                }

                newNode = assertVisible(
                    sheetWidget.sheetTemplateWidget,
                    newNode,
                );

                if (isConstantNode(newNode)) {
                    return newNode;
                } else {
                    definitions.set(defKey, newNode.transform(transformer));
                    return defNode(fnNames, defKey);
                }
            } finally {
                referenceStack.pop();
            }
        } else if (isFunctionNode(node) && node.fn.name === "L") {
            const referenceIdNode = node.args[0];
            const columnIndexNode = node.args[1];
            const sheetWidget = sheet.sheetWidgetsByReferenceId.get(
                referenceIdNode.evaluate().toString(),
            );

            if (
                !sheetWidget ||
                !(
                    sheetWidget.sheetTemplateWidget instanceof
                    LookupSheetTemplateWidgetModel
                )
            ) {
                return new math.FunctionNode("throw", [
                    new math.ConstantNode(
                        "Could not find lookup widget with referenceId: " +
                            referenceIdNode.evaluate().toString(),
                    ),
                ]);
            }

            if (sheetWidget.sheetTemplateWidget.hasEquation) {
                // Dynamic Lookup
                return new math.FunctionNode("lookup", [
                    referenceIdNode,
                    new math.FunctionNode("x", [referenceIdNode]),
                    columnIndexNode,
                    new math.FunctionNode("simplifyDynamicLookupEquation", [
                        sheetWidget.equationNode,
                    ]),
                ]).transform(transformer);
            } else {
                // Static Lookup
                // For a lookup (which typically use the same syntax as a shared table member selector)
                // this converts it to a similar shape as x('').
                return new math.FunctionNode("lookup", [
                    referenceIdNode,
                    new math.FunctionNode("x", [referenceIdNode]),
                    columnIndexNode,
                ]).transform(transformer);
            }
        } else if (isFunctionNode(node) && node.fn.name === "setSum") {
            if (
                isConstantNode(node.args[2]) &&
                typeof node.args[2].value === "string"
            ) {
                // Legacy usage where equation is a string. Parse it, so the transformer can dive into it.
                node.args[2] = parseWithNumberSupport(node.args[2].value);
            }

            const [from, to, expr, variable] = node.args;
            let variableName;
            if (
                isConstantNode(variable) &&
                typeof variable.value === "string"
            ) {
                variableName = variable.value;
            } else if (isSymbolNode(variable)) {
                variableName = variable.name;
            } else {
                throw new Error(
                    `partialEval: setSum: Unexpected variable type`,
                );
            }

            // Replace with setSum2 function that doesn't require special case partialEval behaviour.
            return new math.FunctionNode("setSum2", [
                from,
                to,
                new math.FunctionAssignmentNode("f", [variableName], expr),
            ]).transform(transformer);
        } else if (isFunctionNode(node) && node.fn.name === "iterate") {
            // Replace with iterate2 function that doesn't require special case partialEval behaviour.
            const [from, to, step, expr, variable] = node.args;

            return new math.FunctionNode("iterate2", [
                from,
                to,
                step,
                // FunctionAssignmentNode types suggests a string array as the second argument
                // However, the implementation of FunctionAssignmentNode falls back to pulling
                // the param.name if the param is not a string.
                // This works correctly for SymbolNodes which are always strings,
                // Since math.iterate() staticFunction always returns SymbolNode, this will work correctly.
                // @ts-ignore
                new math.FunctionAssignmentNode("f", [variable], expr),
            ]).transform(transformer);
        } else {
            return node;
        }
    }

    function unboundReplacer(node) {
        // Replaces unbound nodes, depending implementation of unboundTransformer.
        const replacement = unboundTransformer(node);
        return replacement || node;
    }

    const node = optimize({
        node: input.transform(transformer),
        definitions,
        remotes,
        localScopeMatchers: globallyUnbound ? [] : [unboundTransformer], // iterate: we use the local scope iterator. member selector: we have no local scope
        globalScopeMatchers: globallyUnbound ? [unboundTransformer] : [],
        outerScope,
        fnNames,
    }).transform(unboundReplacer);

    definitions.forEach((value, key) => {
        if (value.transform) {
            definitions.set(key, value.transform(unboundReplacer));
        }
    });

    remotes.forEach((value, key) => {
        value.equation = value.equation.transform(unboundReplacer);
    });

    return {
        node,
        definitions,
        remotes,
        fnNames,
    };
}

function defNode(fnNames, defKey) {
    return new math.FunctionNode(fnNames.def, [new math.ConstantNode(defKey)]);
}

function remoteNode(fnNames, referenceId) {
    return new math.FunctionNode(fnNames.remote, [
        new math.ConstantNode(referenceId),
    ]);
}

// Pre-evaluate as much of the expression as possible.
function optimize({
    node,
    definitions,
    remotes,
    localScopeMatchers,
    globalScopeMatchers,
    outerScope,
    fnNames,
    defCache = new Map<string, any>(),
    evalBlocked,
    insideTry,
}: {
    node: MathNode;
    definitions: Map<string, any>;
    remotes: Map<string, any>;
    localScopeMatchers: ((node: MathNode) => boolean | MathNode | null)[];
    globalScopeMatchers: ((node: MathNode) => boolean | MathNode | null)[];
    outerScope: any;
    fnNames: { def: string; remote: string };
    defCache?: Map<string, any>;
    evalBlocked?: boolean;
    insideTry?: boolean;
}): OptimizedMathNode {
    const unboundMatchers = [...localScopeMatchers, ...globalScopeMatchers];
    const topLevelIndex = unboundMatchers.length - 1;

    // The highest scope that this node (and its descendents) are unbound in.
    // < 0 means fully bound.
    let maxUnboundIndex = -1;

    if (isConditionalNode(node)) {
        const condition = optimize({
            node: (node as any).condition,
            definitions,
            remotes,
            localScopeMatchers,
            globalScopeMatchers,
            outerScope,
            fnNames,
            defCache,
            evalBlocked,
            insideTry,
        });

        const conditionUnbound = condition.unboundIndex > -1;

        if (!conditionUnbound && !evalBlocked) {
            // The condition is fully bound - we can evaluate it and subtitute the whole
            // ConditionalNode for either trueExpr or falseExpr.
            const scope = {
                ...outerScope,
                [fnNames.def](key) {
                    if (!defCache.has(key)) {
                        const def = definitions.get(key);
                        if (def.evaluate) {
                            defCache.set(key, def.evaluate(scope));
                        } else {
                            defCache.set(key, def);
                        }
                    }

                    return defCache.get(key);
                },
            };
            if (testCondition(condition.evaluate(scope))) {
                node = (node as any).trueExpr;
            } else {
                node = (node as any).falseExpr;
            }

            return optimize({
                node: node,
                definitions,
                remotes,
                localScopeMatchers,
                globalScopeMatchers,
                outerScope,
                fnNames,
                defCache,
                evalBlocked,
            });
        }

        // If the ConditionalNode's condition is unbound, avoid evaluating the true/false
        // expressions until we know what the result will be.
        evalBlocked = evalBlocked || conditionUnbound;
    }

    if (isFunctionNode(node) && node.fn.name === "try") {
        insideTry = true;
    }

    // Do a depth-first search to check whether each node in the tree is unbound or not.
    node = node.map((child, path) => {
        let optimizedChild: OptimizedMathNode;
        if (isFunctionAssignmentNode(node) && path === "expr") {
            const params: string[] = (node as any).params;

            const fnVariableMatcher = (n) =>
                isSymbolNode(n) && params.includes(n.name);

            optimizedChild = optimize({
                node: child,
                definitions,
                remotes,
                localScopeMatchers: [fnVariableMatcher, ...localScopeMatchers],
                globalScopeMatchers,
                outerScope,
                fnNames,
                defCache,
                evalBlocked,
            });

            if (optimizedChild.unboundIndex === 0) {
                // If the highest unbound scope in FunctionAssignmentNode's expr was 0, then the
                // only unbound nodes were the function's parameters. That means the
                // FunctionAssignmentNode node as a whole is not unbound and can be evaluated.
                optimizedChild.unboundIndex = -1;
            } else if (optimizedChild.unboundIndex > 0) {
                // The FunctionAssignmentNode's expr referenced something from an outer scope. Adjust the index
                // to match the current scope's index, since fnVariableMatcher is no longer preset.
                optimizedChild.unboundIndex -= 1;
            }
        } else {
            optimizedChild = optimize({
                node: child,
                definitions,
                remotes,
                localScopeMatchers,
                globalScopeMatchers,
                outerScope,
                fnNames,
                defCache,
                // The BlockNode is stateful and needs to be evaluated as a whole.
                evalBlocked: evalBlocked || isBlockNode(child),
                insideTry,
            });
        }

        if (optimizedChild.unboundIndex > maxUnboundIndex) {
            maxUnboundIndex = optimizedChild.unboundIndex;
        }
        return optimizedChild;
    });

    // Dive into defs
    // The above search of unbound nodes doesn't look inside the cached definitions for all x() nodes
    // If the node we're looking at is a def, then we need to convert it back its original Node form
    // and determine its unbound status.
    if (
        isFunctionNode(node) &&
        node.fn.name === fnNames.def &&
        isConstantNode(node.args![0])
    ) {
        const key = node.args![0].value.toString();
        const def = definitions.get(key);

        // If the def node hasn't been optimized yet
        if (def.unboundIndex === undefined) {
            definitions.set(
                key,
                optimize({
                    node: def,
                    definitions,
                    remotes,
                    // If we're calling into a def, then any local variables (like the setSum loop variable) are no
                    // longer in scope and shouldn't be considered unbound.
                    localScopeMatchers: [],
                    // But any global matchers (such as L() for LookupChecker) are still unbound.
                    globalScopeMatchers,
                    outerScope,
                    fnNames,
                    defCache,
                    evalBlocked,
                    insideTry,
                }),
            );
        }

        if (definitions.get(key).unboundIndex > -1) {
            maxUnboundIndex = topLevelIndex;
        }
    }

    if (
        isFunctionNode(node) &&
        node.fn.name === fnNames.remote &&
        isConstantNode(node.args![0])
    ) {
        const key = node.args![0].value.toString();
        const remote = remotes.get(key);

        // If the remote node hasn't been optimized yet
        if (remote.unboundIndex === undefined) {
            const equation = optimize({
                node: remote.equation,
                definitions,
                remotes,
                // If we're calling into a remote, then any local variables (like the setSum loop variable) are no
                // longer in scope and shouldn't be considered unbound.
                localScopeMatchers: [],
                // But any global matchers (such as L() for LookupChecker) are still unbound.
                globalScopeMatchers,
                outerScope,
                fnNames,
                defCache,
                evalBlocked,
                insideTry,
            });

            remote.unboundIndex = equation.unboundIndex;
        }

        if (remote.unboundIndex > -1) {
            // Remote is unbound. It'll get called on every evaluation.
            maxUnboundIndex = topLevelIndex;
        } else {
            // Remote is fully bound.

            // ...but still treat it as unbound for the purpose of optimization,
            // as this code is synchronous, and we can't wait for any remote
            // results that don't happen to be cached already.
            maxUnboundIndex = topLevelIndex;
        }
    }
    // x("lookup widget") => 1
    // equation result => 1 + L(member,3) => 1
    // another child (1) => -1 => evaluate
    // L(member, 3+3+3) => 1 => don't evaluate
    const nodeUnboundIndex = unboundMatchers.findIndex((m) => m(node));
    if (nodeUnboundIndex > maxUnboundIndex) {
        maxUnboundIndex = nodeUnboundIndex;
    }

    if (!evalBlocked && maxUnboundIndex > -1) {
        // This node is unbound, so we won't be able to evaluate it.
        // Evaluate any children that aren't unbound.
        node = node.map((child) => {
            if (
                (child as OptimizedMathNode).unboundIndex > -1 ||
                isConstantNode(child) ||
                isSymbolNode(child) ||
                isIndexNode(child)
            ) {
                return child;
            }

            if (isFunctionAssignmentNode(child)) {
                // It's possible to evaluate fully bound FunctionAssignmentNodes, but the result
                // is a JS Function object, which we can't integrate back into the expression
                // tree.
                return child;
            }

            if (
                isFunctionNode(child) &&
                child.fn.name === fnNames.def &&
                isConstantNode(child.args![0])
            ) {
                if (
                    !definitions.get(child.args![0].value.toString()).evaluate
                ) {
                    // The definition already points to a concrete value. Don't
                    // go any further - the result will be a no-op.
                    return child;
                }
            }

            const scope = {
                ...outerScope,
                [fnNames.def](key) {
                    if (!defCache.has(key)) {
                        const def = definitions.get(key);
                        if (def.evaluate) {
                            defCache.set(key, def.evaluate(scope));
                        } else {
                            defCache.set(key, def);
                        }
                    }

                    return defCache.get(key);
                },
            };

            if (insideTry) {
                try {
                    // Convert the result back into a node and inline it
                    return new math.ConstantNode(child.evaluate(scope));
                } catch (e) {
                    return new math.ConstantNode(NaN);
                }
            } else {
                return new math.ConstantNode(child.evaluate(scope));
            }
        });
    }

    const optimizedNode: OptimizedMathNode = Object.assign(node, {
        unboundIndex: maxUnboundIndex,
    });

    return optimizedNode;
}

// Extracted from mathjs/src/expression/node/ConditionalNode.js
function testCondition(condition: any): boolean {
    if (
        typeof condition === "number" ||
        typeof condition === "boolean" ||
        typeof condition === "string"
    ) {
        return !!condition;
    }

    if (condition) {
        if (math.isBigNumber(condition)) {
            return !condition.isZero();
        }

        if (math.isComplex(condition)) {
            return !!(condition.re || condition.im);
        }

        if (math.isUnit(condition)) {
            return !!condition.value;
        }
    }

    if (condition === null || condition === undefined) {
        return false;
    }

    throw new TypeError(
        'Unsupported type of condition "' + math.typeOf(condition) + '"',
    );
}

function assertVisible(
    sheetTemplateWidget: SheetTemplateWidgetModel,
    node: MathNode,
) {
    if (sheetTemplateWidget.visibleEquation) {
        return new math.ConditionalNode(
            sheetTemplateWidget.visibleEquation,
            node,
            new math.FunctionNode("throw", [
                new math.ConstantNode(
                    `Can't reference invisible widget ${sheetTemplateWidget.attributes.referenceId}`,
                ),
            ]),
        );
    } else {
        return node;
    }
}

export type PartialEvalResult = EvalFunction & { isAsync: boolean };
type OptimizedMathNode = MathNode & { unboundIndex: number };
