import { observable, computed, makeObservable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import {
    LookupAttributes,
    LookupRelationships,
} from "./SheetTemplateWidgetInterface";
import math from "math";

import { SolveError } from "errors";
import SHARED_TABLES_DATA from "preferredSections/data/sharedTableData";

class LookupSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    constructor(id, options) {
        super(id, options);
        // By default TypeScript will not allow you to annotate private fields (e.g. dataFilter)
        // This can be overcome by explicitly passing the relevant private fields as generic argument
        // see https://mobx.js.org/observable-state.html#limitations
        makeObservable<LookupSheetTemplateWidgetModel, "dataFilter">(this, {
            dataMap: computed,
            columnMap: computed,
            uiOptions: computed,
            dataFilter: computed,
        });
    }
    attributes = observable.object({
        type: "lookup",
        label: undefined,
        symbol: undefined,
        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        referenceImage: undefined,
        defaultValue: undefined,
        defaultValueMks: undefined,
        defaultValueFps: undefined,
        export: undefined,
        visibleIf: undefined,
        showInSuperSummary: undefined,
        enableAutosize: undefined,
        radioListDisplay: undefined,
        dataFilter: undefined,
        valueColumnIndex: undefined,
        enableSelectorChecks: undefined,

        checks: [],
        dataColumns: [],
        dataTable: [],
        selector: undefined,
        sharedTableId: undefined,

        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,

        order: undefined,
        // Although the equation is optional, we initialize it is with an empty array to avoid
        // having to check for undefined. The DB treats empty arrays as undefined values.
        equation: [],
    } as any as LookupAttributes);

    relationships = observable.object(
        {
            sheetTemplate: undefined,
            sharedTable: undefined,
        } as any as LookupRelationships,
        {},
        { deep: false },
    );

    // The lookup is not a pure input when it has an equation field
    // however we cannot override the class property on the parent after instantiation
    // and equation as with other attributes starts off blank
    // so instead we should manually check computing state
    // when rendering the component.
    isUserInput = true;

    get dataColumns() {
        if (this.relationships.sharedTable) {
            return this.relationships.sharedTable.attributes.columns!;
        } else {
            return this.attributes.dataColumns;
        }
    }

    get hasEquation() {
        return !!(
            this.attributes.equation && this.attributes.equation.length > 0
        );
    }

    get dataMap(): Map<string, any[]> {
        const map = new Map<string, any[]>();
        const array = this.relationships.sharedTable
            ? this.relationships.sharedTable.attributes.rows
            : this.attributes.dataTable;
        array.forEach((row, index) => {
            if (this.dataFilter(row)) {
                let key;
                if (this.attributes.valueColumnIndex != null) {
                    key = row[this.attributes.valueColumnIndex].toString();
                } else {
                    key = index.toString();
                }
                if (map.has(key)) {
                    throw new Error(
                        `Lookup ${this.attributes.referenceId}: duplicate value in valueColumnIndex column: ${key}`,
                    );
                }
                map.set(key, row);
            }
        });
        return map;
    }

    get columnMap(): Map<string, string> {
        const map = new Map<string, string>();
        this.dataColumns.forEach((col, index) => {
            if (col.referenceId) {
                if (map.has(col.referenceId)) {
                    throw new Error(
                        `Lookup ${this.attributes.referenceId}: duplicate column referenceId: ${col.referenceId}`,
                    );
                }
                map.set(col.referenceId, index.toString());
            }
        });
        return map;
    }

    get uiOptions(): { label: string; value: string }[] {
        return [...this.dataMap.keys()].map((idx) => {
            return {
                label: formatLabel(this.lookupValueInColumn(idx, 0)),
                value: idx,
            };
        });
    }

    get canAutosize() {
        return (
            // Enable autosize by default, but allow it to be disabled on analysis-only templates
            this.attributes.enableAutosize !== false &&
            this.attributes.selector &&
            this.relationships.sharedTable &&
            this.relationships.sheetTemplate &&
            this.relationships.sheetTemplate.attributes.buildingStandards!.some(
                (s) =>
                    SHARED_TABLES_DATA[s] &&
                    SHARED_TABLES_DATA[s].find(
                        (d) =>
                            d.code ===
                            this.relationships.sharedTable!.attributes.code,
                    ),
            )
        );
    }

    findColumnIndex(columnIndex: number | string) {
        let indexNumber;
        if (typeof columnIndex === "number") {
            indexNumber = columnIndex;
        } else if (typeof columnIndex === "string") {
            indexNumber = this.columnMap.get(columnIndex);
            if (!indexNumber) {
                throw new RangeError(
                    `findColumnIndex: columnIndex "${columnIndex}" does not exist in column referenceIds`,
                );
            }
        } else {
            throw new RangeError(
                "findColumnIndex: columnIndex is not a number or string",
            );
        }
        return indexNumber;
    }

    lookupValueInColumn(
        rowKey: number | string | null | undefined,
        columnIndex: number | string,
        dataMap: Map<string, any[]> = this.dataMap,
    ) {
        if (rowKey === null || rowKey === undefined) {
            throw new SolveError(`${this.attributes.label} not selected`, {
                referenceId: this.attributes.referenceId,
                showInHeader: true,
            });
        }
        const row = dataMap.get(rowKey.toString());
        if (!row) {
            throw new SolveError(`${this.attributes.label} not selected`, {
                referenceId: this.attributes.referenceId,
                showInHeader: true,
            });
        }
        let indexNumber = this.findColumnIndex(columnIndex);

        if (indexNumber >= row.length) {
            throw new Error(
                `Not enough columns in lookup data table. referenceId=${this.attributes.referenceId} rowKey=${rowKey} requestedColumnIndex=${indexNumber} rowLength=${row.length}`,
            );
        }

        let value = row[indexNumber];

        // We have null values in our tables where the value is undefined or not applicable in some way.
        if (value === null) {
            value = NaN;
        }

        const dataColumn = this.dataColumns[indexNumber];
        if (!dataColumn) {
            throw new Error(
                `No dataColumn definition for referenceId "${this.attributes.referenceId}", columnIndex "${columnIndex}"`,
            );
        }
        if (dataColumn.units2) {
            return math.unit(value, dataColumn.units2);
        } else {
            return value;
        }
    }

    symbolForColumn(columnIndex: number | string) {
        return this.dataColumns[this.findColumnIndex(columnIndex)].symbol;
    }

    get isRadioList() {
        return this.attributes.radioListDisplay;
    }

    private get dataFilter(): (row: any[]) => boolean {
        try {
            if (this.attributes.dataFilter) {
                const fn = math.evaluate(this.attributes.dataFilter);
                return (row) => {
                    try {
                        // We need to remove sub-arrays from the row before passing it to the filter
                        // function, because mathjs will throw an error if it encounters a
                        // sub-array in the row. See https://app.clickup.com/t/3fdjv86 for
                        // background and discussion.
                        return fn(this.removeSubArray(row));
                    } catch (e) {
                        console.error(
                            `Error evaluating dataFilter for referenceId ${this.attributes.referenceId}. Row will be included:`,
                            row,
                            `Original error:`,
                            e,
                        );
                        return true;
                    }
                };
            } else {
                return trueFn;
            }
        } catch (e) {
            console.error(
                `Error parsing dataFilter for referenceId ${this.attributes.referenceId}. dataFilter will be ignored.`,
                e,
            );
            return trueFn;
        }
    }

    private removeSubArray(arr) {
        return arr.map((element) => (Array.isArray(element) ? null : element));
    }
}
export default LookupSheetTemplateWidgetModel;

function trueFn() {
    return true;
}

export function formatLabel(label): string {
    if (typeof label === "string") {
        return label;
    } else if (math.typeOf(label) === "Unit") {
        return `${math.defaultFormat(label, 8)} ${label.formatUnits()}`;
    } else {
        return label.toString();
    }
}
