import { observable } from "mobx";
import SheetTemplateWidgetModel from "./SheetTemplateWidget";
import { SectionAttributes } from "./SheetTemplateWidgetInterface";

class SectionSheetTemplateWidgetModel extends SheetTemplateWidgetModel {
    attributes = observable.object({
        type: "section",
        label: undefined,
        referenceId: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        collapsed: undefined,
        visibleIf: undefined,

        order: undefined,

        description: undefined,
        descriptionHtml: undefined,
        references: undefined,
        referencesJson: undefined,
        referencesHtml: undefined,
        authorNotes: undefined,
        referenceImage: undefined,

        platformLayout: undefined,
    } as any as SectionAttributes);
}

export default SectionSheetTemplateWidgetModel;
