import { autorun, action, observable, computed, makeObservable } from "mobx";
import { fromPromise } from "mobx-utils";
import Rollbar from "rollbar";
import segment from "segment";
import customerio from "customerio";
import launchDarkly from "launchDarkly";

import Storage from "framework/Storage";
import * as api from "data/utils/objectCrud";
import { getClientConfig, Features } from "clientConfig";
import { registerCurrentSession } from "data/utils/apiRequest";
import editableModelProxy from "data/utils/editableModelProxy";
import Universe from "data/Universe";
import Session from "data/models/Session";
import User from "data/models/User";

const sessionsStorage = new Storage("sessions");

export default class SessionStore {
    universe: Universe = new Universe();

    private _embeddedCalc: boolean = false;
    private _session: Session | null = null;
    initialized: boolean = false;

    get session(): Session | null {
        return this._session;
    }

    setSession = action((session: Session | null) => {
        // Let's just sidestep the potential for the iFrame to
        // write anything to localstorage, incase the browser
        // ever lets it write to the top level because they're
        // on the same domain
        //
        // We don't want the embedded calc to write the session
        // of the embedded user to localstorage so that clicking
        // Login, logs you in as the embed user.
        if (!this._embeddedCalc) {
            if (session) {
                sessionsStorage.setItem("currentSessionId", session.id);
            } else {
                sessionsStorage.removeItem("currentSessionId");
            }
        }

        // Make sure we register the new session with apiRequest *before* we notify our observers that we are
        // authenticated.
        registerCurrentSession(session);
        this._session = session;
    });

    get authenticated() {
        return !!this.session;
    }

    get userPromise(): Promise<User | null> {
        if (this.session) {
            const user = this.universe.getModel(
                "users",
                this.session.attributes.userId,
            );
            return api.read(user, {
                include: ["primaryOrganisation", "oneTimeState"],
            });
        } else {
            return Promise.resolve(null);
        }
    }

    get _userPromiseObservable() {
        return fromPromise(this.userPromise);
    }

    get user() {
        // TODO: Do we need to expose the error state?
        if (this._userPromiseObservable.state === "fulfilled") {
            return this._userPromiseObservable.value;
        } else {
            return null;
        }
    }

    get primaryOrganisation() {
        // TODO: Do we need to expose the error state?
        if (
            this._userPromiseObservable.state === "fulfilled" &&
            this._userPromiseObservable.value !== null
        ) {
            return this._userPromiseObservable.value.relationships
                .primaryOrganisation;
        } else {
            return null;
        }
    }

    constructor({ printSessionId, embeddedCalc }) {
        makeObservable<SessionStore, "_session">(this, {
            _session: observable,
            initialized: observable,
            session: computed,
            authenticated: computed,
            userPromise: computed,
            _userPromiseObservable: computed,
            user: computed,
            primaryOrganisation: computed,
        });

        new Promise((resolve, reject) => {
            this._embeddedCalc = !!embeddedCalc;

            // Ignore any sessions we may have in localStorage already if we're
            // loading an embedded calc, in practice this doesn't matter because
            // the embedded calcs API doesn't care about any session id and then
            // stomps the new session.
            //
            // But would potentally allow someone to re-source the iframe to an
            // app page for the logged in user.
            if (this._embeddedCalc) {
                resolve(undefined);
                return;
            }

            const sessionId =
                printSessionId || sessionsStorage.getItem("currentSessionId");

            if (sessionId) {
                const session = this.universe.getModel("sessions", sessionId);
                registerCurrentSession(session);
                resolve(
                    api.read(session).then(
                        (session) => this.setSession(session),
                        (error) => {
                            this.setSession(null);
                        },
                    ),
                );
            } else {
                resolve(undefined);
            }
        }).then(
            action(() => {
                autorun(this._handleSessionChange);
                this.initialized = true;
            }),
        );
    }

    logout = async ({
        preserveLocation,
        callback,
    }: {
        preserveLocation?: boolean;
        callback?: () => void;
    }) => {
        const session = this.session;
        this.setSession(null);
        if (session) {
            await api.destroy(session).catch(() => null);
        }
        if (segment.instance) {
            segment.instance.reset();
        }

        if (launchDarkly.instance) {
            launchDarkly.instance.identify({
                kind: "user",
                anonymous: true,
            });
        }

        // @ts-ignore TODO Rollbar bindings don't acknowledge any
        // of the functions:
        //      Property 'configure' does not exist on type 'typeof Rollbar'
        Rollbar.configure({
            payload: {
                person: undefined,
            },
        });

        if (callback) {
            callback();
        } else {
            if (preserveLocation) {
                window.location.assign(`/login${window.location.pathname}`);
            } else {
                window.location.assign("/login");
            }
        }
    };

    getOneTimeState(flag) {
        return (
            this.user &&
            this.user.relationships.oneTimeState &&
            this.user.relationships.oneTimeState.attributes[flag]
        );
    }

    setOneTimeState = action((flag, value) => {
        const oneTimeState = this.user && this.user.relationships.oneTimeState;
        if (oneTimeState) {
            const editable = editableModelProxy(oneTimeState, {
                restrictAttributes: [flag],
                restrictRelationships: [],
            });
            editable.setAttributes({ [flag]: value });
            api.update(editable);

            // Eagerly update the model so the UI changes immediately
            oneTimeState._setAttributes({ [flag]: value });
        }
    });

    isSupportOrganisation(organisationId?: string) {
        // If we don't know the primary organisation, assume that the
        // current org is the primary org. This will stop any flickering
        // of support admin UI if we're still waiting on the primaryOrg
        // to finish fetching.
        return (
            organisationId &&
            this.primaryOrganisation &&
            this.primaryOrganisation.id !== organisationId
        );
    }

    hasGlobalRole(role) {
        return (
            this.user &&
            this.user.attributes.globalRoles &&
            this.user.attributes.globalRoles.indexOf(role) > -1
        );
    }

    featureEnabled(feature: keyof Features): boolean {
        return !!(
            getClientConfig()[feature] || this.hasGlobalRole("engineering")
        );
    }

    _handleSessionChange = () => {
        if (this.session) {
            // @ts-ignore TODO Rollbar bindings don't acknowledge any
            // of the functions:
            //      Property 'configure' does not exist on type 'typeof Rollbar'
            Rollbar.configure({
                payload: {
                    person: {
                        id: this.session.attributes.userId,
                    },
                },
            });

            if (launchDarkly.instance) {
                if (this.primaryOrganisation?.id && this.user?.id) {
                    let context = {
                        kind: "multi",
                        user: {
                            key: this.session.attributes.userId,
                            experimentGroupNumber:
                                this.user.attributes.experimentGroupNumber,
                        },
                        organisation: {
                            key: this.primaryOrganisation?.id,
                            region: this.primaryOrganisation?.attributes
                                .preferredBuildingStandard,
                            paid: !!this.primaryOrganisation?.relationships
                                .subscription,
                            externalCustomer:
                                this.primaryOrganisation?.attributes
                                    .externalCustomer,
                            isExternalCustomer:
                                !!this.primaryOrganisation?.attributes
                                    .externalCustomer,
                            experimentGroupNumber:
                                this.primaryOrganisation?.attributes
                                    .experimentGroupNumber,
                        },
                    };

                    launchDarkly.instance.identify(context);
                }
            }

            const traits: any = {};

            if (this.user && !this._embeddedCalc) {
                traits.email = this.user.attributes.email;
            }

            if (segment.instance) {
                segment.instance.identify(
                    this.session.attributes.userId,
                    traits,
                );
            }

            if (customerio.instance) {
                customerio.instance.identify({
                    id: this.session.attributes.userId,
                    ...traits,
                });
            }
        }
    };
}
