const DATA = {
    AU: [
        {
            code: "timber_v2",
            name: "Timber",
            icon: "TemplateTimber",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 4,
                    },
                    {
                        type: "select",
                        columnIndex: 28,
                    },
                    {
                        type: "max",
                        columnIndex: 2,
                    },
                    {
                        type: "max",
                        columnIndex: 3,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 4,
                    },
                    {
                        type: "value",
                        columnIndex: 28,
                    },
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 6,
                    },
                    {
                        type: "value",
                        columnIndex: 23,
                    },
                ],
            },
        },
        {
            code: "steel_v2",
            name: "Steel",
            icon: "TemplateSteel",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 38,
                    },
                    {
                        type: "select",
                        columnIndex: 39,
                    },
                    {
                        type: "max",
                        columnIndex: 3,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 2,
                    },
                    {
                        type: "value",
                        columnIndex: 3,
                    },
                    {
                        type: "value",
                        columnIndex: 32,
                    },
                ],
            },
        },
        {
            code: "cfsBeams_au",
            name: "Cold Form Steel Beams",
            icon: "TemplateCfs",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 2,
                    },
                    {
                        type: "max",
                        columnIndex: 5,
                    },
                    {
                        type: "max",
                        columnIndex: 11,
                    },
                    {
                        type: "max",
                        columnIndex: 13,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 12,
                    },
                    {
                        type: "value",
                        columnIndex: 13,
                    },
                    {
                        type: "value",
                        columnIndex: 16,
                    },
                ],
            },
        },
    ],
    NZ: [
        {
            code: "timberNZ",
            name: "Timber",
            icon: "TemplateTimber",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 4,
                    },
                    {
                        type: "select",
                        columnIndex: 28,
                    },
                    {
                        type: "max",
                        columnIndex: 2,
                    },
                    {
                        type: "max",
                        columnIndex: 3,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 4,
                    },
                    {
                        type: "value",
                        columnIndex: 28,
                    },
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 6,
                    },
                    {
                        type: "value",
                        columnIndex: 23,
                    },
                ],
            },
        },
        {
            code: "steel_v2",
            name: "Steel",
            icon: "TemplateSteel",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 38,
                    },
                    {
                        type: "select",
                        columnIndex: 39,
                    },
                    {
                        type: "max",
                        columnIndex: 3,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 2,
                    },
                    {
                        type: "value",
                        columnIndex: 3,
                    },
                    {
                        type: "value",
                        columnIndex: 32,
                    },
                ],
            },
        },
        {
            code: "cfsBeams_au",
            name: "Cold Form Steel Beams",
            icon: "TemplateCfs",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 2,
                    },
                    {
                        type: "max",
                        columnIndex: 5,
                    },
                    {
                        type: "max",
                        columnIndex: 11,
                    },
                    {
                        type: "max",
                        columnIndex: 13,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 12,
                    },
                    {
                        type: "value",
                        columnIndex: 13,
                    },
                    {
                        type: "value",
                        columnIndex: 16,
                    },
                ],
            },
        },
    ],
    US: [
        {
            code: "timberUS",
            name: "Wood",
            icon: "TemplateTimber",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 2,
                        leftToRight: true,
                    },
                    {
                        type: "select",
                        columnIndex: 12,
                        leftToRight: true,
                    },
                    {
                        type: "select",
                        columnIndex: 1,
                        leftToRight: true,
                    },
                    {
                        type: "select",
                        columnIndex: 13,
                        leftToRight: true,
                    },
                    {
                        type: "max",
                        columnIndex: 3,
                    },
                    {
                        type: "max",
                        columnIndex: 4,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 3,
                    },
                    {
                        type: "value",
                        columnIndex: 4,
                    },
                    {
                        type: "value",
                        columnIndex: 12,
                    },
                    {
                        type: "value",
                        columnIndex: 13,
                    },
                ],
            },
        },
        {
            code: "steelUS",
            name: "Steel",
            icon: "TemplateSteel",
            selector: {
                filters: [
                    {
                        columnIndex: 2,
                        type: "select",
                    },
                    {
                        columnIndex: 83,
                        type: "max",
                    },
                    {
                        columnIndex: 84,
                        type: "max",
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 83,
                    },
                    {
                        type: "value",
                        columnIndex: 84,
                    },
                    {
                        type: "value",
                        columnIndex: 3,
                    },
                    {
                        type: "value",
                        columnIndex: 37,
                    },
                    {
                        type: "value",
                        columnIndex: 38,
                    },
                ],
            },
        },
        {
            code: "cfsBeams_us",
            name: "Cold Formed Steel",
            icon: "TemplateCfs",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 2,
                    },
                    {
                        type: "max",
                        columnIndex: 7,
                    },
                    {
                        type: "max",
                        columnIndex: 8,
                    },
                    {
                        type: "max",
                        columnIndex: 12,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 14,
                    },
                    {
                        type: "value",
                        columnIndex: 16,
                    },
                ],
            },
        },
    ],
    EU: [
        {
            code: "timberEU",
            name: "Timber",
            icon: "TemplateTimber",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 13,
                    },
                    {
                        type: "select",
                        columnIndex: 30,
                    },
                    {
                        type: "max",
                        columnIndex: 33,
                    },
                    {
                        type: "max",
                        columnIndex: 32,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 13,
                    },
                    {
                        type: "value",
                        columnIndex: 30,
                    },
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 11,
                    },
                ],
            },
        },
        {
            code: "steelEU",
            name: "Steel",
            icon: "TemplateSteel",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 29,
                    },
                    {
                        type: "select",
                        columnIndex: 30,
                    },
                    {
                        type: "max",
                        columnIndex: 2,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 1,
                    },
                    {
                        type: "value",
                        columnIndex: 15,
                    },
                    {
                        type: "value",
                        columnIndex: 19,
                    },
                ],
            },
        },
    ],
    CA: [
        {
            code: "steelCA",
            name: "Steel",
            icon: "TemplateSteel",
            selector: {
                filters: [
                    {
                        columnIndex: 2,
                        type: "select",
                    },
                    {
                        columnIndex: 83,
                        type: "max",
                    },
                    {
                        columnIndex: 84,
                        type: "max",
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 83,
                    },
                    {
                        type: "value",
                        columnIndex: 84,
                    },
                    {
                        type: "value",
                        columnIndex: 3,
                    },
                    {
                        type: "value",
                        columnIndex: 37,
                    },
                    {
                        type: "value",
                        columnIndex: 38,
                    },
                ],
            },
        },
        {
            code: "timberUS",
            name: "Wood",
            icon: "TemplateTimber",
            selector: {
                filters: [
                    {
                        type: "select",
                        columnIndex: 2,
                        leftToRight: true,
                    },
                    {
                        type: "select",
                        columnIndex: 34,
                        leftToRight: true,
                    },
                    {
                        type: "select",
                        columnIndex: 12,
                        leftToRight: true,
                    },
                    {
                        type: "select",
                        columnIndex: 1,
                        leftToRight: true,
                    },
                    {
                        type: "select",
                        columnIndex: 13,
                        leftToRight: true,
                    },
                    {
                        type: "max",
                        columnIndex: 3,
                    },
                    {
                        type: "max",
                        columnIndex: 4,
                    },
                ],
                tableColumns: [
                    {
                        type: "value",
                        columnIndex: 0,
                    },
                    {
                        type: "value",
                        columnIndex: 3,
                    },
                    {
                        type: "value",
                        columnIndex: 4,
                    },
                    {
                        type: "value",
                        columnIndex: 12,
                    },
                    {
                        type: "value",
                        columnIndex: 13,
                    },
                ],
            },
        },
    ],
};
export default DATA;
