function isPrimitive(value) {
    return value !== Object(value);
}

export default function DeepClone(input: any) {
    if (isPrimitive(input)) {
        return input;
    } else {
        return JSON.parse(JSON.stringify(input));
    }
}
