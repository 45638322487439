import { observable } from "mobx";
import Model from "data/Model";
import { Equation } from "./SheetTemplateWidgetInterface";
import SheetTemplateModel from "./SheetTemplate";

interface SheetTemplateUpgradeAttributes {
    mappings: { targetReferenceId: string; equation: Equation }[];
    ignore: string[];
    sharedTables?: SharedTableMapping[];
}

interface SharedTableMapping {
    sourceSharedTableId: string;
    targetSharedTableId: string;
    mappings: [string, string][];
}

interface SheetTemplateUpgradeRelationships {
    sheetTemplate: SheetTemplateModel;
}

class SheetTemplateUpgradeModel extends Model {
    type = "sheetTemplateUpgrades";
    attributes = observable.object({
        mappings: undefined,
        ignore: undefined,
        sharedTables: undefined,
    } as any as SheetTemplateUpgradeAttributes);
    relationships = observable.object(
        {
            sheetTemplate: undefined,
        } as any as SheetTemplateUpgradeRelationships,
        {},
        { deep: false },
    );
}
export default SheetTemplateUpgradeModel;
