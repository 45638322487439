import { observable } from "mobx";
import Model from "data/Model";

import User from "./User";
import Organisation from "./Organisation";

interface OrganisationUserAttributes {
    role: "user" | "admin" | null;

    // This attribute will only return true from the API
    // if the user is a support admin, otherwise it will
    // omitted from the response.
    isSupportAdmin: true;
    supportAdminExpiresAt: string | null;
}

interface OrganisationUserRelationships {
    organisation: Organisation | null;
    user: User | null;
}

class OrganisationUserModel extends Model {
    type = "organisationUsers";
    attributes = observable.object({
        role: undefined,
        isSupportAdmin: undefined,
        supportAdminExpiresAt: undefined,
    } as any as OrganisationUserAttributes);

    relationships = observable.object(
        {
            organisation: undefined,
            user: undefined,
        } as any as OrganisationUserRelationships,
        {},
        { deep: false },
    );

    toResource() {
        const toResource = super.toResource();

        if (!this.id) {
            (toResource as any).relationships.user = {
                data: this.relationships.user!.toResource(),
            };
        }

        return toResource;
    }

    get resendActionUrl() {
        return `${this.url}/actions/resend`;
    }
}
export default OrganisationUserModel;
