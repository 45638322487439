import segment from "segment";

export interface Properties {
    "Template code"?: string | null;
    template_code?: string | null;
    "Field Name"?: string | null;
    field_name?: string | null;
    model_type?: string;
    "Preset code"?: string | null;
    preset_code?: string | null;
    "Event type"?: string | null;
    "From template code"?: string | null;
    from_template_code?: string | null;
    "To template code"?: string | null;
    to_template_code?: string | null;
    "Reference ID"?: string | null;
    reference_id?: string | null;
    "Link description"?: string | null;
    link_description?: string | null;
    "Calculation count"?: number | "All";
    calculation_count?: number | "All";
    "Section name"?: string | null;
    section_name?: string | null;
    "Print mode"?: "supersummary" | "summary" | "details";
    print_mode?: "supersummary" | "summary" | "details";
    "Project attribute"?: string;
    project_attribute?: string;
    "Plan type"?: string;
    "Plan length"?: string;
    "Plan ID"?: string;
    plan_id?: string;
    "Annualised value"?: number;
    "Filter label"?: string;
    filter_label?: string;
    "HelpScout article ID"?: string;
    "Search Query"?: string;
    search_query?: string;
    "Result Slug"?: string;
    result_slug?: string;
    "Result Category"?: string;
    "Result Position"?: number;
    "Result Index"?: string;
    "With export"?: boolean;
    with_export?: boolean;
    "Unit system"?: string;
    "Sample project name"?: string;
    "Cancel Reason"?: string;
    "Cancel Notes"?: string | null;
    "Sign In Method"?: string | null;
    "Signup Provider"?: string | null;
    signup_provider?: string | null;
    "Signup Method"?: string | null;
    signup_method?: string | null;
    "Flag name"?: string | null;
    flag_name?: string | null;
    Value?: string | null;
    value?: string | boolean | null;
    Context?: string | null;
    context?: string | null;
    Location?: string | null;
    location?: string | null;
    Content?: string | string[] | null;
    content?: string | string[] | null;
    "Utilisation PassFail"?: string;
    Email?: string | null;
    Occupation?: string | null;
    "Calculation frequency"?: string | null;
    "Step name"?: string | null;
    step_name?: string | null;
    Available?: boolean;
    available?: boolean;

    pricing_tier?: string | null;

    // Builder Event Properties
    "Widget Type"?: string | null;
    widget_type?: string | null;

    // GA properties
    category?: string | null;
    label?: string | null;

    //Segment Reserved Properties
    revenue?: number;
    currency?: string;

    // Standards Integration
    Clause?: string | null;
    "Standard code"?: string | null;
}

interface SuperProperties {
    // To be in sync with user.rb `analytics_super_properties` method
    paid_status?: string;
}

// These properties are automatically included on all events
let superProperties: SuperProperties = {};

export default function track(eventName: string, properties?: Properties) {
    properties = {
        ...superProperties,
        ...(properties || {}),
    };
    if (segment.instance) {
        segment.instance.track(eventName, properties);
    } else {
        console.info("track:", eventName, properties);
    }
}

export function setSuperProperties(value: {
    [key: string]: string | number | null;
}) {
    superProperties = value;
}
