import { observable } from "mobx";
import Model from "data/Model";

import Project from "./Project";
import User from "./User";
import UploadModel from "./Upload";

export type UploadType = "ccframe" | "ccimport" | "archier_saf" | "sofistik";

interface ImportAttributes {
    id: string | null;
    ccframeData: any;
    state: string | null;
    progressPercent: number | null;
    durationSeconds: number | null;
    uploadType: UploadType | null;
    error: string | null;
}

interface ImportRelationships {
    project: Project | null;
    creator: User | null;
    upload: UploadModel | null;
}

export default class ImportModel extends Model {
    type = "imports";

    attributes = observable.object(
        {
            id: undefined,
            ccframeData: undefined,
            state: undefined,
            progressPercent: undefined,
            durationSeconds: undefined,
            uploadType: undefined,
            error: undefined,
        } as any as ImportAttributes,
        {},
        { deep: false },
    );
    relationships = observable.object(
        {
            project: undefined,
            creator: undefined,
            upload: undefined,
        } as any as ImportRelationships,
        {},
        { deep: false },
    );

    get generateSheetsUrl() {
        return `${this.url}/actions/generateSheets`;
    }
}
