import Collection from "../Collection";
import SheetWidget from "../models/SheetWidget";
import SheetWidgetShadowModel from "../models/SheetWidgetShadow";

class SheetWidgetsCollection extends Collection<
    SheetWidget | SheetWidgetShadowModel
> {
    type = "sheetWidgets";

    getByReferenceId(referenceId) {
        return this.models.find(
            (model) => model.attributes.referenceId === referenceId,
        );
    }
}
export default SheetWidgetsCollection;
