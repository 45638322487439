import { observable, computed, makeObservable, action } from "mobx";
import math from "math";
import parseWithNumberSupport from "math/parseWithNumberSupport";

import Model from "../Model";
import {
    ChecksItem,
    CommonRelationships,
    ChecksComparators,
} from "./SheetTemplateWidgetInterface";
import makeReferenceTransformer from "math/makeReferenceTransformer";
import deepClone from "framework/utils/deepClone";
import Preset from "./Preset";
import { ValidationError } from "errors";

abstract class SheetTemplateWidgetModel extends Model {
    constructor(id, options) {
        super(id, options);
        makeObservable(this, {
            visibleEquation: computed,
            checkEquations: computed,
            checks: computed,
            checkEquation: computed,
        });
    }
    type = "sheetTemplateWidgets";
    relationships = observable.object(
        {
            sheetTemplate: undefined,
        } as any as CommonRelationships,
        {},
        { deep: false },
    );
    isUserInput = false;

    get hasChecks() {
        return this.attributes.checks && this.attributes.checks.length > 0;
    }

    get visibleEquation() {
        if (this.attributes.visibleIf) {
            return new math.FunctionNode("equal", [
                // visibleIf is typically a string expression that evaluates to a boolean
                // however for consistency with other parsing we run it through the number parser
                parseWithNumberSupport(this.attributes.visibleIf),
                new math.ConstantNode(true),
            ]).transform(
                makeReferenceTransformer(this.relationships.sheetTemplate!),
            );
        } else {
            return null;
        }
    }

    get checkEquations() {
        const transformer = makeReferenceTransformer(
            this.relationships.sheetTemplate!,
            this.attributes.referenceId,
        );

        return this.checks!.map((c) =>
            parseWithNumberSupport(c.condition).transform(transformer),
        );
    }

    get checks(): Array<ChecksItem> | undefined {
        if (!this.attributes.checks) return undefined;

        return this.attributes.checks.map((check) => {
            switch (check.type) {
                // HEREBEDRAGONS
                //
                // Added backticks around referenceId so that any symbols containing maths syntax e.g. (-)
                // are only interpreted as variables
                // Currently new builder users never have symbols with backticks already in them, so this
                // will work. Revisit when we add the ability to use backticks in symbol names,
                // or extend utilisation and passfail checks to old builder
                case "utilisation":
                    return {
                        // Parentheses around condition are added to ensure mathematical order of operations
                        // e.g. (1+1)/2 is 1;
                        //      1+1/2 is 1.5
                        condition: `(${check.condition}) / \`${this.attributes.referenceId}\``,
                        result: check.result,
                    };
                case "passfail":
                    if (!ChecksComparators.hasOwnProperty(check.comparator)) {
                        throw new Error(
                            "Cannot calculate passfail equation with invalid or missing comparator",
                        );
                    }
                    return {
                        // Parentheses around condition are added to prevent some edge cases:
                        // e.g. 1 < 2 < 3 is true; // where the user input is `2 < 3`
                        //      1 < (2 < 3) is false; // true always evaluates to 1
                        condition: `\`${this.attributes.referenceId}\` ${
                            ChecksComparators[check.comparator]
                        } (${check.condition})`,
                        result: check.result,
                    };
                default:
                    return check;
            }
        });
    }

    get checkEquation() {
        let node;
        if (this.checkEquations.length === 1) {
            node = this.checkEquations[0];
        } else {
            // If there are multiple checks, we fail if any of them are false,
            // otherwise we take the maximum number.
            node = new math.FunctionNode("checkGov", this.checkEquations);
        }

        if (this.visibleEquation) {
            return new math.ConditionalNode(
                this.visibleEquation,
                node,
                new math.ConstantNode(true),
            );
        } else {
            return node;
        }
    }

    get hasUnits() {
        return !!this.attributes.units || !!this.attributes.units2;
    }
    get hasDebugInfo() {
        // Everything has debug info now that we're showing the referenceId.
        return true;
    }

    // Override to throw an error if widget is in an invalid state in the builder
    validateBuilder() {
        if (
            this.relationships.sheetTemplate?.relationships.sheetTemplateWidgets.models.some(
                (stw) => {
                    return (
                        stw.id !== this.id &&
                        stw.attributes.referenceId ===
                            this.attributes.referenceId
                    );
                },
            )
        ) {
            throw new ValidationError("referenceId already exists", [
                {
                    code: "validation_uniqueRefId",
                    source: {
                        pointer: "/data/attributes/referenceId",
                    },
                    title: "Validation Unique ReferenceId ",
                    detail: "referenceId already exists for this sheet",
                    key: "referenceId",
                    path: "referenceId",
                },
            ]);
        }
    }

    cleanValue(value) {
        return value;
    }

    defaultValue({
        unitSystem,
        preset,
    }: {
        unitSystem: string | null;
        preset: Preset | void;
    }) {
        let defaultValue;
        if (
            preset &&
            preset.attributes.values.some(
                (v) => v.referenceId === this.attributes.referenceId,
            )
        ) {
            const presetValue = preset.attributes.values.find(
                (v) => v.referenceId === this.attributes.referenceId,
            );
            if (unitSystem === "MKS" && presetValue.valueMks != null) {
                defaultValue = presetValue.valueMks;
            } else if (unitSystem === "FPS" && presetValue.valueFps != null) {
                defaultValue = presetValue.valueFps;
            } else {
                defaultValue = presetValue.value;
            }
        } else {
            if (
                unitSystem === "MKS" &&
                this.attributes.defaultValueMks !== null
            ) {
                defaultValue = this.attributes.defaultValueMks;
            } else if (
                unitSystem === "FPS" &&
                this.attributes.defaultValueFps !== null
            ) {
                defaultValue = this.attributes.defaultValueFps;
            } else {
                defaultValue = this.attributes.defaultValue;
            }
        }
        return deepClone(defaultValue);
    }
}

export default SheetTemplateWidgetModel;
