import { ValidationErrorData } from "./types";

// https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
class ClearCalcsError extends Error {
    data?: any;

    constructor(message: string, data?: Object) {
        super(message);
        this.data = data;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ClearCalcsError.prototype);
    }
}

class OAuthRequestError extends ClearCalcsError {
    constructor(message: string, data?: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, OAuthRequestError.prototype);
    }
}

class RequestTimeoutError extends ClearCalcsError {
    constructor(message: string, data?: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, RequestTimeoutError.prototype);
    }
}

class RequestAbortError extends ClearCalcsError {
    constructor(message: string, data?: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, RequestAbortError.prototype);
    }
}

class PermissionDeniedError extends ClearCalcsError {
    constructor(message: string, data: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, PermissionDeniedError.prototype);
    }
}

class ValidationError extends ClearCalcsError {
    constructor(message: string, data: Array<ValidationErrorData>) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ValidationError.prototype);
    }
}

class NotFoundError extends ClearCalcsError {
    constructor(message: string, data?: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NotFoundError.prototype);
    }
}

class UnexpectedError extends ClearCalcsError {
    constructor(message: string, data: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, UnexpectedError.prototype);
    }
}

class BadRequestError extends ClearCalcsError {
    constructor(message: string, data: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, BadRequestError.prototype);
    }
}

// This error is thrown to halt computation when we're still waiting for data (eg. remote solver, linked ResultSet).
class PendingDataError extends ClearCalcsError {
    constructor(message: string) {
        super(message);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, PendingDataError.prototype);
    }
}

//Error that occurred during computation. Suitable for presentation to users.
class SolveError extends ClearCalcsError {
    constructor(message: string, data?: Object) {
        super(message, data);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, SolveError.prototype);
    }
}

export {
    ClearCalcsError,
    NotFoundError,
    OAuthRequestError,
    PermissionDeniedError,
    RequestTimeoutError,
    RequestAbortError,
    ValidationError,
    UnexpectedError,
    BadRequestError,
    PendingDataError,
    SolveError,
};
