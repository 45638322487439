import {
    PermissionDeniedError, // 403
    NotFoundError, // 404
    UnexpectedError, //0
    ValidationError, // 422
    BadRequestError, // 400
} from "errors";

const statusToErrorClass = {
    403: PermissionDeniedError,
    404: NotFoundError,
    400: BadRequestError,
    422: ValidationError,
};

function errorMapper(response) {
    function generateErrorMessage(error) {
        return `(${response.meta._request.status}) ${response.meta._request.method} ${response.meta._request.url} :: ${error.detail}`;
    }

    const ErrorClass =
        statusToErrorClass[response.meta._request.status.toString()];
    const errors = (response && response.errors) || [];

    let errorToThrow;
    if (!ErrorClass) {
        errorToThrow = new UnexpectedError(
            generateErrorMessage({
                details: `Unexpected API error (status ${response.meta._request.status}) - ${errors[0]}`,
            }),
            errors[0],
        );
    } else if (ErrorClass === ValidationError) {
        errorToThrow = new ErrorClass(generateErrorMessage(errors[0]), errors);
    } else {
        errorToThrow = new ErrorClass(
            generateErrorMessage(errors[0]),
            errors[0],
        );
    }

    // We only support a single error return at the moment, but let's return an array of
    // errors for furtue proofing.
    //
    // eslint-disable-next-line no-throw-literal
    throw [errorToThrow];
}

export default errorMapper;
